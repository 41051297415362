import dashboardAction from './actions';
const initState = {
dashboard_data:{},
user_plan:[],
check_aval_module:[],
get_dashboard:[],
new_dashboard_chart: [],
common_data:[],
pusher_data: {},
mark_visited:[],
userData:{},
userCredits:{},
chart_data:{},
update_setup_guide:{},
setup_guide_make_call:{},
}
export default function dashboardReducer(state = initState, action){
    switch(action.type){
        case dashboardAction.DASHBOARD_DATA:
      return {
        ...state,
        dashboard_data: action.postdata,
      };
      case dashboardAction.USER_PLAN:
      return {
        ...state,
      user_plan: action.postdata,
      };
      case dashboardAction.CHECK_AVL_MODULE:
      return {
        ...state,
      check_aval_module: action.postdata,
      };

      case dashboardAction.DASHBOARD_CHART_DATA:
        return {
          ...state,
          new_dashboard_chart: action.postdata
        }

      case dashboardAction.GET_DASHBOARD:
        return {
          ...state,
        get_dashboard: action.postdata,
        };
      case dashboardAction.UPDATE_SETUP_GUIDE:
        return {
          ...state,
          update_setup_guide: action.payload,
        };
      case dashboardAction.SETUP_GUIDE_MAKE_CALL:
        return {
          ...state,
          setup_guide_make_call: action.payload,
        };
      case dashboardAction.PUSHER_DATA:
          let userData = JSON.parse(localStorage.getItem("userData"));
          let userCredits = state.userCredits;

          if(action.payload.type == "credit"){
              userData.totalCredits = Number(action.payload.data.totalCredits);
              userCredits.credits = action.payload.data.totalCredits;
              return {
                  ...state,
                  userData: userData,
                  pusher_data: action.payload,
                  userCredits:userCredits
              };
          }if(action.payload.type == "amountDue"){
              userData.amountDue = action.payload.data.amountDue;
              userCredits.amountDue = action.payload.data.amountDue;
              userData.blockReason = action.payload.data.blockReason ? action.payload.data.blockReason : userData.blockReason;
              localStorage.setItem("userData", JSON.stringify(userData));
              return {
                  ...state,
                  userData: userData,
                  pusher_data: action.payload,
                  userCredits:userCredits
              };
          }else if(action.payload.type == "login"){
              userData.totalCredits = Number(action.payload.data.data.totalCredits);
              userCredits.credits = action.payload.data.data.totalCredits;
              userCredits.amountDue = action.payload.data.data.amountDue;
              return {
                  ...state,
                  pusher_data: action.payload,
                  userCredits:userCredits
              };
          }else if(action.payload.type == "plan"){
              userCredits.amountDue = action.payload.data.amountDue;
              userCredits.credits = action.payload.data.totalCredits;
              return {
                  ...state,
                  userData: userData,
                  pusher_data: action.payload,
                  userCredits:userCredits
              };
          }else{
              return {
                  ...state,
                  pusher_data: action.payload,
              };
          }

      case "CLEAR_DASHBOARD":
       return {
        ...state,
        dashboard_data:{},
        user_plan:[],
        check_aval_module:[],
        get_dashboard:[],
        chart_data:[],
       }
      case dashboardAction.MARK_TOUR_VISITED:
        return {
          ...state,
        mark_visited: action.postdata,
        };
      case dashboardAction.COMMON_DATA:
        console.log("action.payload",action.payload)
        return {
          ...state,
        common_data: action.payload,
        };
      case dashboardAction.CHART_DATA:
        return {
          ...state,
          chart_data: action.postdata,
        };
      case dashboardAction.GET_CREDIT:
        if(action.payload.success){
          return {
            ...state,
            userCredits: {credits:action.payload.data.totalCredits},
          };
        }
        else{
          return {
            ...state
          };
        }
        
      default:
        return state;
    }
}