import axiosApi, { ClearUserData, errorResponseStatus } from "../api"
import axios from 'axios';
import authenticationAction from "../authentication/action";
import cookie from 'react-cookies';
const userData = JSON.parse(localStorage.getItem('userData')),
  base_url = process.env.REACT_APP_BASE_URL,
  authToken = userData == null ? '' : userData.authToken,
  userId = userData == null ? '' : userData._id,
  bilingtoken = userData == null ? '' : userData.billingToken;

const didGroups = {

  COMMON_NUMBER_LIST: 'COMMON_NUMBER_LIST',
  GET_DID_GROUPS: 'GET_DID_GROUPS',
  ADD_DID_GROUP: 'ADD_DID_GROUP',
  EDIT_DID_GROUP: 'EDIT_DID_GROUP',
  UPDATE_DID_GROUP: 'UPDATE_DID_GROUP',
  DELETE_DID_GROUP: 'DELETE_DID_GROUP',

  didGroupsList: (postData,query={}) => dispatch => {
    let pagination = query.pagination ? `&pagination=${query.pagination}` : ''
    let search = query.search ? `&search=${encodeURIComponent(query.search)}` : '';
    dispatch({ type: "CLEAR_CUSTOM_MESSAGE" });
    return axiosApi(`${base_url}number/didGroup/list?current=${query.current ? query.current : 1}${pagination}${search}`, "get", {}, dispatch, `${didGroups.GET_DID_GROUPS}`).then((result) => {
      return result;
    })
  },
  commonNumberList: () => dispatch => axiosApi(`${base_url}number/common/list?didGroups=true`, "get", {}, dispatch, `${didGroups.COMMON_NUMBER_LIST}`),

  addDidGroups: postData => dispatch => axiosApi(`${base_url}number/didGroup/${postData.user}/add`, "post", postData, dispatch, `${didGroups.ADD_DID_GROUP}`),
  editDidGroups: postData => dispatch => axiosApi(`${base_url}number/didGroup/${postData}/details`, "get", {}, dispatch, `${didGroups.EDIT_DID_GROUP}`),
  updateDidGroup: (postData ,updateIndex) => dispatch => axiosApi(`${base_url}number/didGroup/${postData._id}`, "put", postData, dispatch, `${didGroups.UPDATE_DID_GROUP}`,updateIndex),
  deleteDidGroup: (postData, deleteId) => dispatch => axiosApi(`${base_url}number/didGroup/${postData}`, "delete", {}, dispatch, `${didGroups.DELETE_DID_GROUP}`, deleteId),
}
export default didGroups;
