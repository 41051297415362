import { GET_CAMPAIGN_LIST, DWNLD_CAMPAIGN, DEL_CAMPAIGN, ADD_CAMPAIGN, GET_FREE_CAMPAIGN_MINUTES} from './actions';
const initState = {
  get_campaign_list: [],
  dwnld_campaign: [],
  del_campaign: [],
  add_campaign: [],
  free_campaign_minutes: [],
  }

export default function campaignReducer(state = initState, action) {
  switch (action.type) {
    case GET_CAMPAIGN_LIST:
      return {
        ...state,
        get_campaign_list: action.payload,
      };
    case DWNLD_CAMPAIGN:
      return {
        ...state,
        dwnld_campaign: action.payload,
      };
    case DEL_CAMPAIGN:
      return {
        ...state,
        del_campaign: action.payload,
      };
    case ADD_CAMPAIGN:
      return {
        ...state,
        add_campaign: action.payload,
      };
    case GET_FREE_CAMPAIGN_MINUTES:
      return {
        ...state,
        free_campaign_minutes: action.payload,
      };
    default:
      return state;
  }
};