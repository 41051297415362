import Pusher from "pusher-js";
import React, {Fragment,memo } from "react";
import dashboardActions from '@iso/redux/dashboard/actions';
import { useDispatch } from 'react-redux';
const { changeUserData, changeUserSetting } = dashboardActions;


const PusherConnection=(props)=> {
    let userData = props.userData
    const dispatch = useDispatch();
    if(userData&&userData?.roleData?.priority && [1,1.5,2,3,3.5].includes(userData.roleData.priority)){
        let self = this;
        // let parentId = userData.parentId != undefined ? userData.parentId : userData._id;
        let parentId = (userData.roleData.priority === 3 || userData.roleData.priority == 3.5) ? userData._id : userData.parentId != undefined ? userData.parentId : userData._id;
        const authUrl = process.env.REACT_APP_BASE_URL + "pusher/auth/" + parentId;
        const pusherDta = localStorage.getItem('socket_id');
        const pusherList = JSON.parse(localStorage.getItem('pusher'));
        const pusher = global.pusher;
        pusher.connection.bind('error', function (err) {
            if (err.error.data.code === 4004) {
                
            }
        });
        pusher.connection.bind('state_change', function (states) {
            // console.log("pusher state_change")
        });
        pusher.connection.bind('connected', function(res){
            console.log("pusheConnection")
            localStorage.setItem('socket_id',res.socket_id)
        });

        pusher.connection.bind('disconnected', function(err){
            localStorage.removeItem('socket_id')
            console.log("pusher disconnected")
        });
        if (props.pathname.includes("/dashboard") || props.pathname.includes("/predictiveDialer") || props.pathname.includes("/powerDialer")){
            let channelName = userData.roleData.priority === 3 ? 'private-' + parentId + '-manager' : userData.roleData.priority == 3.5 ? 'private-' + parentId + '-supervisor':'private-' + parentId
            if (!(pusher.channel(channelName))) {
                let channel = pusher.subscribe(channelName);
                channel.bind('common-update', function (data) {
                let userData = JSON.parse(localStorage.getItem('userData'));
                if (data.key == 'credit-sidebar') {
                    userData.totalCredits = data.credit;
                    // dispatch(changeUserSetting({ data: { totalCredits: data.credit }, "type":"credit" }));
                }else if (data.key == 'amountDue') {
                    userData.amountDue = data.amountDue;
                    dispatch(changeUserSetting({ data: { amountDue: data.amountDue }, "type":"amountDue" }));
                }else if (data.key == 'userActive') {
                    userData.userActive = data.userActive
                    // dispatch(changeUserSetting({ data: { userActive: data.userActive }, "type":"userActive" }));
                    // ;
                    dispatch(changeUserData({ data: { userActive: data.userActive }, "type":"userActive" }));
                    ;
                }
                localStorage.setItem("userData", JSON.stringify(userData));
                //dispatch(changeUserData({ totalCredits: data.credit }));
                //dispatch(changeUserPlan({ availableCredits: data.credit }));
            });
            channel.bind('livecall-created', function(data) {
                dispatch(changeUserSetting({ data: data, "type":"livecall-created" }));
            });
            channel.bind('livecall-updated', function(data) {
                dispatch(changeUserSetting({ data: data, "type":"livecall-updated" }));
            });
            channel.bind('powerDialer-agent-answer', function(data) {
                dispatch(changeUserSetting({ data: data, "type":"powerDialer-agent-answer" }));
            });
            channel.bind('livecall-removed', function(data) {
                if(userData.isGettingStartedProcessDisplay && data.callBackStatus == "Completed"){
                    dispatch(changeUserData({ data: { isGettingStartedProcessStep: data.callType == "Outgoing" ? "four" : "five" }, "type":"changeAuth" }));
                }
                dispatch(changeUserSetting({ data: data, "type":"livecall-removed" }));
            });
            channel.bind('livecall-completed', function(data) {
                dispatch(changeUserSetting({ data: data, "type":"livecall-completed" }));
            });
            channel.bind('member_added', function(data) {
                dispatch(changeUserSetting({ data: data, "type":"member_added" }));
            });
            channel.bind('member_removed', function(data) {
                dispatch(changeUserSetting({ data: data, "type":"member_removed" }));
            });
            channel.bind('powerDialerCalls-update', function(data) {
                dispatch(changeUserSetting({ data: data, "type":"powerDialerCalls-update" }));
            });
        }
    }else{
        global.pusher && global.pusher.disconnect();
        console.log("Pusher Disconnect",pusher)
    }
    }
    return (<Fragment></Fragment>)       
}
export default memo(PusherConnection)