import callReport from './actions';
const initState = {
  call_report: [],
  call_countries:[],
  user_list:[],
  country_wise : [],
  target_list: [],
  add_target: [],
  remove_target: []
}

export default function callReportReducer(state = initState, action) {
  switch (action.type) {
      case callReport.CALL_REPORT:
      return {
        ...state,
        call_report: action.postData,
      }
      case callReport.CALL_REPORT_CSV:
      return {
        ...state,
        call_report_csv: action.postData,
      }
      case callReport.ABANDONMENT_REPORT:
        return {
        ...state,
        abandonment_report: action.postData,
      }
      case callReport.ABANDONMENT_REPORT_CSV:
        return {
        ...state,
        abandonment_report_csv: action.postData,
      }
      case callReport.CALLSTATUS_REPORT:
        return {
        ...state,
        callStatus_report: action.postData,
      }
      case callReport.CALLSTATUS_REPORT_CSV:
        return {
        ...state,
        callStatus_report_csv: action.postData,
      }
      case callReport.AVAILABILITY_REPORT:
      return                                                                                                {
        ...state,
        availability_report: action.postData,
      }
      case callReport.AVAILABILITY_REPORT_CSV:
      return                                                                                                {
        ...state,
        availability_report_csv: action.postData,
      }
      case callReport.USER_STATUS_REPORT:
      return                                                                                                {
        ...state,
        user_status_report: action.postData,
      }
      case callReport.USER_STATUS_REPORT_CSV:
      return                                                                                                {
        ...state,
        user_status_report_csv: action.postData,
      }
      case callReport.DID_REPORT:
      return                                                                                                {
        ...state,
        did_report: action.postData,
      }
      case callReport.DID_REPORT_CSV:
      return                                                                                                {
        ...state,
        did_report_csv: action.postData,
      }
      case callReport.AVAILABILITY_SLOT:
      return                                                                                                {
        ...state,
        availability_slot: action.postData,
      }
      case callReport.CALL_COUNTRIES:
      return {
        ...state,
        call_countries: action.postData,
      }
      case callReport.USER_LIST:
      return {
        ...state,
        user_list: action.postData,
      }
      case callReport.COUNTRY_WISE:
      return {
        ...state,
        country_wise: action.postData,
      }
      case callReport.TARGET_LIST:
      return {
        ...state,
        target_list: action.postData
      }
      case callReport.ADD_TARGET:
      return {
        ...state,
        add_target: action.postdata
      }
      case callReport.EDIT_TARGET:
        return {
          ...state,
          edit_target: action.postdata
        }
      case callReport.REMOVE_TARGET:
      return {
        ...state,
        remove_target: action.postdata
      }
      case callReport.CALL_DISPOSITION_REPORT:
      return {
        ...state,
        call_disposition_report: action.postdata
      }
    default:
      return state;
  }
}

