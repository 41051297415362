import authantication from './action';
import users from '../users/actions';
import dashboard from '../dashboard/actions';
import Currency from "../../constants";

const initState = {
  isAuthenticated: false,
  userData: {},
  isTourOpen: false,
  isTourStarted: false,
  tempTour: false,
  isOnBoardingProcessPending: false,
  tourStartAt: 0,
  trigger_add_number_popup: false,
  is2faEnabled: false,
  otpError: false,
  verifyError: false,
  invalidLogin:false,
  userSetting: {
    isBlocked: false,
    amountDue: false,
    credit: 0
  },
  blocked: false,
  resend_email_verification: []
}

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case authantication.AUTHENTICATION:
      if (action && action.postdata && action.postdata.success) {
        let isOnBoardingProcessPending = process.env.REACT_APP_ENVIRONMENT !== 'JENKINS' && action.postdata.data && action.postdata.data.isOnBoardingProcessPending && !action.postdata.data.amountDue && !action.postdata.data.isBlocked ? true : false;
        let tourStatus = action.postdata.data && (action.postdata.data.isTourVisited || action.postdata.data.isProfileRemaining || action.postdata.data.isDocVerificationRequired != "approved") ? false : true;
        let tempTour = tourStatus;
        tourStatus = !isOnBoardingProcessPending ? tourStatus : false;
        let userSetting = { ...state.userSetting };
        if (action.postdata && action.postdata.data) {
          userSetting.isBlocked = action.postdata.data.isBlocked
          userSetting.amountDue = action.postdata.data.amountDue
        }
        return {
          ...state,
          userData: action.postdata.success ? action.postdata : {},
          isAuthenticated: action.postdata.success ? true : false,
          loading: false,
          isTourOpen: tourStatus,
          isTourStarted: tourStatus,
          tempTour: tempTour,
          isOnBoardingProcessPending: isOnBoardingProcessPending,
          userSetting: userSetting
        };
      } else {
        return initState
      }

    case authantication.TWO_FACTOR_AUTH_REDIRECT:
      return {
        ...state,
        is2faEnabled: true
      }

    case authantication.TWO_FACTOR_AUTH_FAILURE:
      return {
        ...state,
        otpError: true
      }
    case authantication.ACCOUNT_NOT_VERIFY:
      return {
        ...state,
        verifyError: true
      }

    case authantication.RESET_OTP_ERROR:
      var otpErr = state.otpError;
      otpErr = false;
      return {
        ...state,
        otpError: otpErr
      }

    case authantication.BLOCK_ACCOUNT:
      return {
        ...state,
        blocked: true
      }

    case authantication.INVALID_LOGIN:
      return {
        ...state,
        invalidLogin: true
        }
    case "CHANGE_CALL_REMINDER_LENGTH":
      let newUser = { ...state.userData };
      let newUserData = JSON.parse(localStorage.getItem("userData"));
      newUserData.countPendingReminder = action.payload;
      newUser.data.countPendingReminder = action.payload;
      localStorage.setItem("userData", JSON.stringify(newUserData));
      return {
        ...state,
        userData: newUser
      }
    case users.UPDATE_COMMON_SETTINGS:
      if (action.payload.data && action.payload.data.success) {
        let userData = state.userData, isReturn = false;
        if (userData.data.fullName !== action.payload.config.data.fullName && action.payload.config.data && action.payload.config.data.fullName) {
          userData.data.fullName = action.payload.config.data.fullName;
          isReturn = true;
        }
        if (action.payload.data && action.payload.data.fullName && action.payload.data.currentUser) {
          userData.data.fullName = action.payload.data.fullName;
          isReturn = true;
        }
        if (userData.data.isProfileRemaining !== action.payload.config.data.isProfileRemaining) {
          userData.data.isProfileRemaining = action.payload.config.data.isProfileRemaining;
          isReturn = true;
        }
        if (isReturn) {
          return {
            ...state,
            userData: userData
          }
        }
      }
    case dashboard.MARK_TOUR_VISITED:
      if (action.payload.success) {
        let userData = state.userData;
        userData.data.isTourVisited = true;
        return {
          ...state,
          userData: userData,
          isTourOpen: false,
          isTourStarted: false,
        }
      }
    case dashboard.UPDATE_DASHBOARD_BANNER:
      if (action.payload.success) {
        let userData = state.userData;
        userData.data.isDashboardBanner = true;
        return {
          ...state,
          userData: userData,
          isDashboardBanner: true,
        }
      }
    case authantication.UPDATE_ONBOARDING_DETAILS:
      if (action.payload.success) {
        let userData = state.userData;
        // userData.data.isOnBoardingProcessPending = false;
        let userDetail = JSON.parse(localStorage.getItem('userData'));
        // userDetail.isOnBoardingProcessPending = false;
        localStorage.setItem("userData", JSON.stringify(userDetail));
        return {
          ...state,
          // isOnBoardingProcessPending: false,
          isTourOpen: state.tempTour,
          isTourStarted: state.tempTour,
          userData: userData,
        }
      }
    case dashboard.TRIGGER_TOUR:
      return {
        ...state,
        ...action.payload
      }
    case dashboard.TRIGGER_ADD_NUMBER_POPUP:
      return {
        ...state,
        trigger_add_number_popup: action.payload
      }
    case authantication.UPDATE_COMMON_USER_DATA:
      let userData = state.userData;
      if (action.payload) {
        let userDetail = JSON.parse(localStorage.getItem('userData'));
        if (action.payload.plans !== undefined) {
          userData.data.plan = action.payload.plans;
          userDetail.plan = userData.data.plan;
          if (action.payload.expiredFreeTrailDate !== undefined) {
            userData.data.expiredFreeTrailDate = action.payload.expiredFreeTrailDate;
            userDetail.expiredFreeTrailDate = action.payload.expiredFreeTrailDate;
          }
          localStorage.setItem("userData", JSON.stringify(userDetail));
        }
        if (action.payload.planPeriod != undefined) {
          userData.data.planPeriod = action.payload.planPeriod;
          userDetail.planPeriod = action.payload.planPeriod;
        }
        if (action.payload.data && action.payload.data.currency) {
          userDetail.selectedCurrency = action.payload.data.currency;
          userDetail.currency = action.payload.data.currency;
          userData.data.selectedCurrency = action.payload.data.currency;
          userData.data.currency = action.payload.data.currency;
          userDetail.currencySign = Currency[action.payload.data.currency].sign;
          userData.data.currencySign = Currency[action.payload.data.currency].sign;
        }
        if (action.payload.amountDueChanges !== undefined) {
          userData.data.amountDue = action.payload.amountDueChanges;
          userData.data.isBlocked = action.payload.isBlocked;
          userData.data.blockReason = action.payload.blockReason;
        }
        if (action.payload.totalCredits !== undefined) {
          userData.data.totalCredits = action.payload.totalCredits;
        }
        if (action.payload.timezone !== undefined) {
          userData.data.timezone = action.payload.timezone;
        }
        if (action.payload.type == "addNumber") {
          userData.data.numbers = action.payload.data.numberCount;
          userData.data.deletedNumbers = action.payload.data.deletedNumberCount;
          userData.data.numbersList = action.payload.data.numbersList;
          userData.data.allNumberCount = action.payload.data.allNumberCount;
          userDetail.numbers = action.payload.data.numberCount;
          userDetail.deletedNumbers = action.payload.data.deletedNumberCount;
          userDetail.numbersList = action.payload.data.numbersList;
          userDetail.allNumberCount = action.payload.data.allNumberCount;
        }
        if (action.payload.type == "userActive") {
          userData.data.userActive = action.payload.data.userActive;
          userDetail.userActive = action.payload.data.userActive;

        }
        if (action.payload.type == "changeEmail") {
          userData.data.email = action.payload.data.email;
          userDetail.email = action.payload.data.email;
        }
        if (action.payload.type == "numberMasking") {
          userData.data.numberMasking = action.payload.data;
          userDetail.numberMasking = action.payload.data;
        }
        if (action.payload.type == "userRole") {
          userData.data.role = action.payload.data.role;
          userDetail.role = action.payload.data.role;

          userData.data.roleData = action.payload.data.roleData;
          userDetail.roleData = action.payload.data.roleData;
        }

        if(action.payload.type == "userRoleAdmin"){
          userData.data.adminType = action.payload.data.adminType;
          userDetail.adminType = action.payload.data.adminType;
        }
        if(action.payload.type == "emailDownload"){
          userData.data.emailDownload = action.payload.data.emailDownload;
          userDetail.emailDownload = action.payload.data.emailDownload;
        }

        if (action.payload.type == "updateRoleUsers") {
          if (action.payload.data.allUsers.length) {
            userData.data.allUsers = action.payload.data.allUsers;
            userDetail.allUsers = action.payload.data.allUsers;
            userData.data.userTeamList = action.payload.data.userTeamList.userTeam;
            userDetail.userTeamList = action.payload.data.userTeamList.userTeam;
          }
        }
        if (action.payload.type == "stripeId") {
          userData.data.stripeId = action.payload.data.stripeId;
          if(action.payload.data.currency) {
            userData.data.currency = action.payload.data.currency
          }
          userDetail.stripeId = action.payload.data.stripeId;
          if(action.payload.data.subscriptionId){
            userData.data.subscriptionId = action.payload.data.subscriptionId;
            userDetail.subscriptionId = action.payload.data.subscriptionId;
          }

          if (action.payload.data.numbers) {
            userData.data.numbers = action.payload.data.numbers;
            userDetail.numbers = action.payload.data.numbers;
          }
          if (action.payload.data.currency) {
            userDetail.selectedCurrency = action.payload.data.currency;
            userDetail.currency = action.payload.data.currency;
            userData.data.selectedCurrency = action.payload.data.currency;
            userData.data.currency = action.payload.data.currency;
            userDetail.currencySign = Currency[action.payload.data.currency].sign;
            userData.data.currencySign = Currency[action.payload.data.currency].sign;
          }

        }
        if (action.payload.type == "freeTrialUser") {
          userData.data.isFreeTrialUser = action.payload.data.isFreeTrialUser;
          userDetail.isFreeTrialUser = action.payload.data.isFreeTrialUser;
        }
        if (action.payload.type === "plan") {
          userData.data.plan = { ...userData.data.plan, ...action.payload.data }
          userDetail.plan = { ...userDetail.plan, ...action.payload.data }
        }
        if (action.payload.type === "tagData") {
          if(userData.data.tagData){
            userData.data.tagData.data = action.payload.data
            userDetail.tagData.data = action.payload.data
          }
        }
        if (action.payload.type === "changeAuth") {
          userData.data = { ...userData.data, ...action.payload.data }
          userDetail = { ...userDetail, ...action.payload.data }
        }

        if (action.payload.type == "numbersList") {
          if(userData.data.numbersList && userData.data.numbersList.length > 0){
            let indexId = userData.data.numbersList.findIndex((nlist) => nlist._id == action.payload.data.numberId);
            // userData.data.numbersList[indexId].recordingTranscript = action.payload.data.recordingTranscript;
            // userDetail.numbersList[indexId].recordingTranscript = action.payload.data.recordingTranscript;
          }
        }
        if(action.payload.type == "amountDueStatus"){
          console.log( action.payload.data ,"amountDueStatus action.payload.data")
          userDetail.amountDueStatus = action.payload.data.amountDueStatus;
          userData.data.amountDueStatus = action.payload.data.amountDueStatus;
          if(action.payload.data.isBlocked &&  action.payload.data.amountDue){
            userDetail.isBlocked = action.payload.data.isBlocked;
            userData.data.isBlocked = action.payload.data.isBlocked;
            userDetail.amountDue = action.payload.data.amountDue;
            userData.data.amountDue = action.payload.data.amountDue;
          }
        }
        if(action.payload.type == "pauseAccount"){
            userDetail.acoountPauseReason = '';
            userData.data.acoountPauseReason = '';
        }
        if(action.payload.type == "addon"){
          userDetail.userAddonFeatures = action.payload.data.userAddonFeatures;
          userData.data.userAddonFeatures = action.payload.data.userAddonFeatures;
        }
        //console.log("userDetail", userDetail)
        localStorage.setItem("userData", JSON.stringify(userDetail));
      }
      return {
        ...state,
        userData: userData,
      }
    case authantication.RESEND_EMAIL_VERIFICATION:
      return {
        ...state,
        resend_email_verification: action.postdata
      }
    default:
      return state;
  }
}

