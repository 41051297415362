import axiosApi, {errorResponseStatus} from '../api';
import {ClearUserData} from "../api";
import Axios from 'axios';
export const GET_CALLSCRIPT_LIST = 'GET_CALLSCRIPT_LIST';
export const DELETE_CALLSCRIPT = 'DELETE_CALLSCRIPT';

const base_url = process.env.REACT_APP_BASE_URL;

export const allScripts = (history,postData={}) => dispatch => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  let parentId = userData.parentId?userData.parentId:userData._id;
  let url = base_url + `api/v1/allcallscripts/${parentId}?current=${postData.current?postData.current:1}`;
  if(postData.searchText){
    url = url + '&search='+postData.searchText;
  }
  return axiosApi(`${url}`, "get", {} , dispatch, `${GET_CALLSCRIPT_LIST}`).then(function(res){
    console.log("response",res,url);
   if (res && !res.payload.success) {  
     return dispatch({
       type: "SET_CUSTOM_MESSAGE",
       payload: { message: res.payload.error.error, type: "error" }
      });
    }
    return res; 
});

};

export const deleteScript = (postData={}) => (dispatch,state) => {
  const userData = JSON.parse(localStorage.getItem('userData')),
  base_url = process.env.REACT_APP_BASE_URL,
  authToken = userData == null ? '' : userData.authToken,
  userId = userData == null ? '' : userData._id,
  bilingtoken = userData == null ? '' : userData.billingToken;
  let parentId = userData.parentId?userData.parentId:userData._id;
  let url = base_url + `api/v1/update/callscript`;
  dispatch({ type: "CLEAR_CUSTOM_MESSAGE" });
  return Axios.delete(url,{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken
        },
        data:{
          parentId,
          ...postData
        }
      }
  ).then((result) => {
    dispatch({
      type:DELETE_CALLSCRIPT,
      payload:result.data
    })
    dispatch({
      type:"SET_CUSTOM_MESSAGE",
      payload:{
        message:"Script deleted successfully.",
        type:"success"
      }
    })
  }).catch((err) => {
    console.log("error",err);
    if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
      return ClearUserData(err.response);
    }
    if(err.response.status == 403){
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: {message: err.response.data.error, type:"error"}
      });
    }else{
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: err.response.data.error.error, type:"error" }
      })
    }
  })
};

export const updateScript = (postData={},history) => (dispatch,state) => {
  const userData = JSON.parse(localStorage.getItem('userData')),
  base_url = process.env.REACT_APP_BASE_URL,
  authToken = userData == null ? '' : userData.authToken,
  userId = userData == null ? '' : userData._id,
  bilingtoken = userData == null ? '' : userData.billingToken;
  let parentId = userData.parentId?userData.parentId:userData._id;
  let url = base_url + `api/v1/update/callscript`;
  dispatch({ type: "CLEAR_CUSTOM_MESSAGE" });
  return Axios.post(url,{parentId,...postData},{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken
        }
      }
  ).then((result) => {

    if(history){
       history.push("/callscript")
       return dispatch({
        type:"SET_CUSTOM_MESSAGE",
        payload:{
          message:"Script updated successfully.",
          type:"success"
        }
      })
    }

    let {scripts,scriptCount} = state().callScript.get_callscript_list;
    let newScripts = [...scripts];
    newScripts[postData.index] = {
      ...newScripts[postData.index],
      isActive:!newScripts[postData.index].isActive
    }
    dispatch({
      type:GET_CALLSCRIPT_LIST,
      payload:{success:true,scriptCount:scriptCount,scripts:newScripts}//scriptCount-1
    })
    
    dispatch({
      type:"SET_CUSTOM_MESSAGE",
      payload:{
        message:`Script ${newScripts[postData.index].isActive?"activated":"inactivated"} successfully.`,
        type:"success"
      }
    })
    
  }).catch((err) => {
    console.log("error",err);
    if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
      return ClearUserData(err.response);
    }
    if(err.response.status == 403){
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: {message: err.response.data.error, type:"error"}
      });
    }else{
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: err.response.data.error.error, type:"error" }
      })
    }
  })
};

export const addScript = (postData={},history) => (dispatch,state) => {
  console.log("addScript",postData);
  const userData = JSON.parse(localStorage.getItem('userData')),
  base_url = process.env.REACT_APP_BASE_URL,
  authToken = userData == null ? '' : userData.authToken,
  userId = userData == null ? '' : userData._id,
  bilingtoken = userData == null ? '' : userData.billingToken;
  let parentId = userData.parentId?userData.parentId:userData._id;
  console.log("parentId",parentId);
  postData.id = parentId;
  let url = base_url + `api/v1/callscript/add`;
  dispatch({ type: "CLEAR_CUSTOM_MESSAGE" });
  return Axios.post(url,postData,{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken
        }
      }
  ).then((result) => {
    dispatch({
      type:"SET_CUSTOM_MESSAGE",
      payload:{message:"Script added successfully.",type:"success"}
    })
    history.push("/callscript")
  }).catch((err) => {
    console.log("error",err);
    if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
      return ClearUserData(err.response);
    }
    dispatch({
      type: "SET_CUSTOM_MESSAGE",
      payload: { message: err.response.data.error.error, type:"error" }
    })
  })
};

export const getScript = (scriptId) => {
  const userData = JSON.parse(localStorage.getItem('userData')),
  base_url = process.env.REACT_APP_BASE_URL,
  authToken = userData == null ? '' : userData.authToken,
  userId = userData == null ? '' : userData._id,
  bilingtoken = userData == null ? '' : userData.billingToken;
  let parentId = userData.parentId?userData.parentId:userData._id;
  console.log("parentId",parentId);
  let url = base_url + `api/v1/callscript/${parentId}/${scriptId}`
  return Axios.get(url,{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken
        }
      }
  );
}