import { GET_POWER_DIALER_LIST, GET_CAMPAIGN_SETTING, START_STOP_CAMPAIGN, GET_ACTIVITY_DATA, DOWNLOAD_CAMPAIGN, DELETE_CAMPAIGN, GET_USER_TEAM_LIST, UPDATE_CAMPAIGNS, ADD_CAMPAIGNS, GET_ACTVITYFEED_POWER_DIALER_LIST, GET_CONTACTS, UPDATE_CONTACTS, DELETE_CONTACT, GET_SINGLE_CAMPAIGN_AGENT, UPDATE_CAMPAIGN_SETTING, GET_AUTODIALER_DATA, GET_INTEGRATION_CAMPAIGN_DATA, UPDATE_AUTODIALER_DATA, ADD_AUTODIALER_DATA, DELETE_AUTODIALER_DATA } from './actions';
const initState = {
  get_power_list: [],
  get_activityfeed_power_list: [],
  start_stop_campaign: [],
  download_campaign: [],
  delete_campaign: [],
  get_user_team_list: [],
  get_single_campaign_agent:[],
  add_campaigns: [],
  update_campaigns: [],
  get_broadcast_list:[],
  get_contacts:[],
  get_activityData:[],
  update_contacts:[],
  delete_contact:[],
  campaign_setting: [],
  campaign_setting_update: [],
  get_autoDialer_data: [],
  get_integration_campaign_data: [],
  delete_autoDialer_data: [],
  update_autoDialer_data: [],
  add_autoDialer_data: [],
  }

export default function powerDialerReducer(state = initState, action) {
  switch (action.type) {
    case GET_POWER_DIALER_LIST:
      return {
        ...state,
        get_power_list: action.payload,
      };
    case START_STOP_CAMPAIGN:
      return {
        ...state,
        start_stop_campaign: action.payload,
      };
    case DOWNLOAD_CAMPAIGN:
      return {
        ...state,
        download_campaign: action.payload,
      };
    case DELETE_CAMPAIGN:
      return {
        ...state,
        delete_campaign: action.payload,
      };
    case GET_USER_TEAM_LIST:
      return {
        ...state,
        get_user_team_list: action.payload,
      };
    case ADD_CAMPAIGNS:
      return {
        ...state,
        add_campaigns: action.payload,
      };
    case UPDATE_CAMPAIGNS:
      return {
        ...state,
        update_campaigns: action.payload,
      };
    case "GET_BROADCAST_LIST":
      return {
        ...state,
        get_broadcast_list: action.payload
      }
    case GET_ACTVITYFEED_POWER_DIALER_LIST:
      return {
        ...state,
        get_activityfeed_power_list: action.payload,
      };
      case GET_CONTACTS:
      return {
        ...state,
        get_contacts: action.payload,
      };
      case GET_ACTIVITY_DATA:
      return {
        ...state,
        get_activityData: action.payload,
      };
      case UPDATE_CONTACTS:
      return {
        ...state,
        update_contacts: action.payload,
      };
      case DELETE_CONTACT:
      return {
        ...state,
        delete_contact: action.payload,
      };
      case GET_CAMPAIGN_SETTING:
      return {
        ...state,
        campaign_setting: action.payload,
      };
      case GET_AUTODIALER_DATA:
        return {
          ...state,
          get_autoDialer_data: action.payload,
        };
        case GET_INTEGRATION_CAMPAIGN_DATA:
          return {
            ...state,
            get_integration_campaign_data: action.payload,
          };
      case DELETE_AUTODIALER_DATA:
        return {
          ...state,
          delete_autoDialer_data: action.payload,
        };
      case UPDATE_CAMPAIGN_SETTING:
      return {
        ...state,
        campaign_setting_update: action.payload,
      };
      case UPDATE_AUTODIALER_DATA:
        return {
          ...state,
          update_autoDialer_data: action.payload,
        };
      case ADD_AUTODIALER_DATA:
        return {
          ...state,
          add_autoDialer_data: action.payload,
        };
      case GET_SINGLE_CAMPAIGN_AGENT:
        return {
          ...state,
          get_single_campaign_agent: action.payload,
        };

    default:
      return state;
  }
};