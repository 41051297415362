import React, { Component } from 'react';
import { Input, Col, Popover,Button,message, Form } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Changeemailpopover from './changeemailpopover.style';
import authenticationAction from '@iso/redux/authentication/action';
import usersAction from '@iso/redux/users/actions';
import dashboardActions from '@iso/redux/dashboard/actions';
import AddNoPopUp from '../../containers/AddNumber/addNoPopUp'
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

const { changeUserData ,triggerAddNumberPopup} = dashboardActions;
const { resendEmailVeification } =authenticationAction;
const { getUserDetails } = usersAction;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
class ChangeResendEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addPopUpVisible: false,
      visible:false,
      freeTrialVisible:true,
      email:this.props.userData.data && this.props.userData.data.email,
      newEmail:this.props.userData.data && this.props.userData.data.email,
      docRequiredForVerification:false,
      docVerificationPending:false
    }
  }
  onFinish = (values) => {
    console.log(values);
  };
  handleclose = async () => {
    await this.setState({
        addPopUpVisible: false,
      })
    }

  changeStateFromChild = async(obj) => {
    this.props.triggerAddNumberPopup(false);
      if(this.state.addPopUpVisible){
        await this.setState({
          addPopUpVisible: false,
        })
      }
      if(obj.upgradePlan){
        this.props.triggerAddNumberPopup(true)
      }
  }
    buttonClick=()=>{
      this.setState({
        addPopUpVisible:false
      })
    }
    buttonOpenClick=()=>{
      this.setState({
        addPopUpVisible:true
      })
    }
  onClickResendEmail =()=>{
      let email = this.props.userData.data && this.props.userData.data.email;
      let userId = this.props.userData.data && this.props.userData.data._id;
      let fullName = this.props.userData.data && this.props.userData.data.fullName;

      email && this.props.resendEmailVeification({email,userId,fullName,resendType:true});
      message.destroy();
      // message.success("Verification link sent successfully.");
  }

  onClickChangeEmail =()=>{
 
    // this.setState({ visible:false });

  }
  handleVisibleChange = visible => {
    this.setState({ visible });
  };
  handleHideChange =() => {
    this.setState({ visible:false });
  };

  componentDidMount() {
    if(this.props.userData && this.props.userData.data && this.props.userData.data._id){
      this.props.getUserDetails(this.props.userData.data._id);
    }
  }

  componentDidUpdate(prevProps, prevState){

    if(prevProps.resend_email_verification !=this.props.resend_email_verification){
      if(this.props.resend_email_verification.success){
        this.props.changeUserData({ data: { email:this.state.newEmail }, "type":"changeEmail" });
      }
    }

    if (prevProps.user_details !== this.props.user_details) {
      if (this.props.user_details) {
        let userData = this.props.user_details;
        let fileData = []
        userData.documents.forEach((file, index) => {
          fileData.push({
            uid: file._id, name: file.fileName,
            url: file.filePath,
          })
        })
        
        if (this.props.user_details && this.props.user_details.isDocVerificationRequired != 'approved') {
          if(fileData && fileData.length){
            this.setState({
              docRequiredForVerification:'pending',
              docVerificationPending:true
            });
          }else{
            this.setState({
              docRequiredForVerification:true,
              docVerificationPending:false
            });
          }
        }
      } else {

        message.destroy();
        if(this.props.user_details){
          message.error(this.props.user_details.error);
        }
      }
    }
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        let newEmail = values.email;
        this.setState({newEmail})
        let userId = this.props.userData.data && this.props.userData.data._id;
        let fullName = this.props.userData.data && this.props.userData.data.fullName;
        let email = this.props.userData.data && this.props.userData.data.email;

        if(email==newEmail){
          message.destroy();
          message.error("New email can not be the same as previous email . Please enter different email address.");
          return
        }
        // let postData = {email,newEmail:values.email,isEmailChanged:true};
        // console.log(postData,"postData..............")
        // this.props.updateSettingData(userId,postData,true);
        this.props.resendEmailVeification({email:newEmail,userId:userId,fullName,resendType:false});
        // message.success("Verification link sent successfully.");
      }
    });
  };

  render() {
      const {userData} = this.props;
      const {email, freeTrialVisible, docRequiredForVerification, docVerificationPending} = this.state;
      const { getFieldDecorator } = this.props.form;

      const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    const emailcontent = (
      <Changeemailpopover>
          
          <div className="changeemailwrapper">
            <div className="signuptext"><span className="material-icons sigupicon">email</span>Signed up using the wrong email address?</div>      
            <Form {...layout} name="nest-messages" onSubmit={this.handleSubmit} >

            <Form.Item label="E-mail" className="changemailform">
                {getFieldDecorator('email', {
                    initialValue:userData.data &&  userData.data.email ? userData.data.email : "",
                    rules: [
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail!',
                    },
                    ],
                })(<Input />)}
                    </Form.Item>
                
                <Col span={24}>
                <Button type="default" className="changeemailcancelbtn" onClick={this.handleHideChange}>Cancel</Button>
                <Button type="primary" htmlType="submit">Send Activation</Button>
                </Col>
            </Form>

            </div>

         </Changeemailpopover>
       );
    return (
      <>
      {userData.data.isFreeTrialUser && !userData.data.expiredFreeTrailDate && !userData.data.stripeId && freeTrialVisible ?
      <Changeemailpopover className="ChangeResendEmailInner">

        {/* Need to add condtion to show message exact time */}       
        {/* sticky message until user's account is verified Start */}
        {/* docRequiredForVerification && docVerificationPending && <Col span={24} className="activationemailwrapper freeTrialCol">Your documents are under verification, it will take max 72 hours.</Col> */}
        {/* docRequiredForVerification && !docVerificationPending && <Col span={24} className="activationemailwrapper freeTrialCol">Please <Link to="/uploadDocument?status=428">&nbsp;submit&nbsp;</Link> your documents for the verification.</Col> */}
        {/* sticky message until user's account is verified End */}
        
        <Col span={24} className="activationemailwrapper freeTrialCol">Get started with your 10 days free trial. <p className="startnowText" onClick={this.buttonOpenClick}> Start Here</p><i className="material-icons freeTrialClose" onClick={() => this.setState({freeTrialVisible: false})}>clear</i></Col>        

      </Changeemailpopover>
       :<Changeemailpopover>

       {/* Need to add condtion to show message exact time */}       
       {/* sticky message until user's account is verified Start */}
       {/* docRequiredForVerification && docVerificationPending && <Col span={24} className="activationemailwrapper freeTrialCol">Your documents are under verification, it will take max 72 hours.</Col> */}
       {/* docRequiredForVerification && !docVerificationPending && <Col span={24} className="activationemailwrapper freeTrialCol">Please <Link to="/uploadDocument?status=428">&nbsp;submit&nbsp;</Link> your documents for the verification.</Col> */}
       {/* sticky message until user's account is verified End */}   
     </Changeemailpopover>}  
     {userData.data.amountDueStatus && userData.data.isBlocked && userData.data.amountDue && this.props.get_plan && this.props.get_plan.data && this.props.get_plan.data.user ?
      <Changeemailpopover>
        <Col span={24} className="activationemailwrapper freeTrialCol">{[1,1.5].includes(userData.data.roleData.priority) ? <p>Your account is blocked as your invoice for {userData.data.currencySign} {this.props.get_plan.data.user.dueInvoiceAmount ? this.props.get_plan.data.user.dueInvoiceAmount : this.props.get_plan.data.user.nextBillingAmount} is due by {this.props.get_plan.data.user.amountDue && this.props.get_plan.data.user.amountDueDate ? moment(this.props.get_plan.data.user.amountDueDate).format('DD MMMM YYYY') : moment.unix(this.props.get_plan.data.user.next_billing_at).format('DD MMMM YYYY')}. <a className="startnowText" href="#billingContacts">Pay Now</a></p>: 'Please contact your Owner/Admin to process pending payment.'}</Col>
      </Changeemailpopover> : <></>
     }
      <AddNoPopUp visible={this.state.addPopUpVisible} newPlan={true} buttonClick={this.buttonClick} contactName='' addressVerifiedNoteHide='' parent_state='' number='' changeStateFromChild={this.changeStateFromChild} premium='' close={this.handleclose} userbilling='' get_avail_number='' user_data={this.props.userData.data} user_details={this.props.userData.data} secondNumberPopupOk='' history={this.props.history} />
     </>
    );
  }
}
const WrappedFormWIthSubmissionButton = Form.create()(ChangeResendEmail);
const mapStateToProps = state => ({
  userData: state.authentication.userData,
  resend_email_verification: state.authentication.resend_email_verification,
  user_details: state.usersReducer.user_details,
  get_plan: state.planReducer.get_plan
});

export default connect(mapStateToProps, {resendEmailVeification,changeUserData,triggerAddNumberPopup, getUserDetails })(withRouter(WrappedFormWIthSubmissionButton));
