import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const isoModal = ComponentName => WithDirection(styled(ComponentName)`
.ant-modal-close{
    display:none;
}
.ant-modal-title img.chinfologowidth{
    width:150px;
    margin-bottom:5px;
}   
.ant-modal-title .chinfotitlewrapper{
    text-align:center;
} 
.infopopupwrapper .ant-row:after {
        display: block;       
}
.teamsizewrapper{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    label.ant-radio-button-wrapper{
        width: 94px;
        padding:0px 12px !important;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #d9d9d9;
    }
    .ant-radio-button-wrapper:last-child  {
        margin-right:0px;
    }
    .ant-radio-button-wrapper:hover{
        color:#000000a6;
    }
    .ant-radio-button-wrapper:not(:first-child)::before{
      content:unset;
    }    
    label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
    label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:is(:hover,:active){
        color: #fff !important;
        background: #e27849;
        border-color: #e27849;
        box-shadow:unset;
    }
}
.infopopupbtn{
    color:#fff;
    background: #e37949;
    border-color: #e37949;
}
.infopopupbtn.ant-btn:hover, .infopopupbtn.ant-btn:focus{
    color:#fff;
    background: #e37949;
    border-color: #e37949;
}
.infopopupwrapper .ant-form-item-label{
    padding: 0 0 2px;
}
.infopopupwrapper .ant-row.ant-form-item{
    margin-bottom:10px;
}
`);

export default isoModal;