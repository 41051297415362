import Axios from 'axios';
import axiosApi, { ClearUserData, errorResponseStatus } from "../api"
import axios from "axios";
import cookie from 'react-cookies';
import { APP_PRIVATE_ROUTE } from '@iso/components/root/routeConstants';
import dashboardAction from "../dashboard/actions";
import history from '@iso/lib/helpers/history';
const { USER_PROFILE_DOCUMENT } = APP_PRIVATE_ROUTE;

const base_url = process.env.REACT_APP_BASE_URL;
const numberAction = {
  GET_NUMBER: 'GET_NUMBER',
  GET_UNVERIFIED_DOCS: 'GET_UNVERIFIED_DOCS',
  GET_COUNTRIES: 'GET_COUNTRIES',
  GET_AVAIL_NUMBER: 'GET_AVAIL_NUMBER',
  USER_BILLING: 'USER_BILLING',
  GET_LOCATION: 'GET_LOCATION',
  CART_UP_CREATE: 'CART_UP_CREATE',
  CHARGEBEE_NEW_USER: 'CHARGEBEE_NEW_USER',
  SEND_DOCUMENT: 'SEND_DOCUMENT',
  NUMBER_SETTING: 'NUMBER_SETTING',
  USER: 'USER',
  RESTORE_NUMBER : 'RESTORE_NUMBER',
  SYSTEM_VERIFIED_DOCS: 'SYSTEM_VERIFIED_DOCS',
  SYSTEM_VERIFIED_DOCS_CLEAR: 'SYSTEM_VERIFIED_DOCS_CLEAR',
  NUMBER_DELETE: 'NUMBER_DELETE',
  NUMBER_SETTING_UPDATE: 'NUMBER_SETTING_UPDATE',
  ALLOCATION_TYPE: 'ALLOCATION_TYPE',
  ASSIGN_USER: 'ASSIGN_USER',
  IVR: 'IVR',
  SURVEY_ANSWER: 'SURVEY_ANSWER',
  DELETE_IVR: 'DELETE_IVR',
  DELETE_ANSWER: 'DELETE_ANSWER',
  TEAM_LIST: "TEAM_LIST",
  TEAM_ASSIGN: "TEAM_ASSIGN",
  GET_USERS: "GET_USERS",
  GET_USER_PROFILE: "GET_USER_PROFILE",
  UPLOAD_MUSIC: "UPLOAD_MUSIC",
  SAVE_NUMBER: "SAVE_NUMBER",
  BUY_NUMBER: "BUY_NUMBER",
  FEATURE_UPGRADE: "FEATURE_UPGRADE",
  SEND_VOXBONE_DETAILS: "SEND_VOXBONE_DETAILS",
  CHECK_ACCOUNT_IN_CHARGEBEE: "CHECK_ACCOUNT_IN_CHARGEBEE",
  AVAILABILITY_TIME: "AVAILABILITY_TIME",
  TIME_OPERATION: "TIME_OPERATION",
  SEND_OTP_ON_CALL: "SEND_OTP_ON_CALL",
  VERIFY_OTP_ON_CALL: "VERIFY_OTP_ON_CALL",
  REMOVE_CUSTOM_CALLER_ID : "REMOVE_CUSTOM_CALLER_ID",
  CANCEL_CALLERID_POPUP : "CANCEL_CALLERID_POPUP",
  WHATSAPP_DATA : "WHATSAPP_DATA",
  INTEGRATE_WHATSAPP_DATA : "INTEGRATE_WHATSAPP_DATA",
  SMS_USECASE:"SMS_USECASE",
  SMS_VERIFICATION : "SMS_VERIFICATION",
  EDIT_SMS_VERIFICATION : "EDIT_SMS_VERIFICATION",
  USE_CASE_PROFILE : "USE_CASE_PROFILE",
  GET_USE_CASE_PROFILE : "GET_USE_CASE_PROFILE",
  GET_USE_CASE_REVIEW : "GET_USE_CASE_REVIEW",
  SMS_USE_CASE : "SMS_USE_CASE",
  ASSIGN_ALL: "ASSIGN_ALL",
  REMOVE_ADDRESS_DOCUMENT_ID : "REMOVE_ADDRESS_DOCUMENT_ID",
  REMOVE_INTEGRATE_WHATSAPP_DATA:"REMOVE_INTEGRATE_WHATSAPP_DATA",
  SYSTEM_DEFAULT_NUMBER_ASSIGN:"SYSTEM_DEFAULT_NUMBER_ASSIGN",
  CLONE_NUMBER_SETTING:"CLONE_NUMBER_SETTING",
  PORT_NUMBER:"PORT_NUMBER",
  GET_PORT_NUMBER:"GET_PORT_NUMBER",
  DELETE_PORT_NUMBER:"DELETE_PORT_NUMBER",
  getAvailabilityTime: id => dispatch => axiosApi(`${base_url}number/${id}/setting/time`, "get", {}, dispatch, `${numberAction.AVAILABILITY_TIME}`),
  smsUseCase: (id, postData) => dispatch => axiosApi(`${base_url}user/${id}/smsUseCase`, "post", postData, dispatch, `${numberAction.SMS_USE_CASE}`),
  useCaseProfile: (id, postData) => dispatch => axiosApi(`${base_url}user/${id}/useCaseProfile`, "post", postData, dispatch, `${numberAction.USE_CASE_PROFILE}`),
  getUseCaseProfile: id => dispatch => axiosApi(`${base_url}user/${id}/useCaseProfile`, "get", {}, dispatch, `${numberAction.GET_USE_CASE_PROFILE}`),
  useCaseProfileReview: id => dispatch => axiosApi(`${base_url}user/${id}/useCaseProfileReview`, "get", {}, dispatch, `${numberAction.GET_USE_CASE_REVIEW}`),
  timeOperation: (id, postData) => dispatch => axiosApi(`${base_url}number/${id}/setting/customTime`, "post", postData, dispatch, `${numberAction.TIME_OPERATION}`),
  cloneNumberSetting: (postData) => dispatch => axiosApi(`${base_url}numbers/clonesettings`, "post", postData, dispatch, `${numberAction.CLONE_NUMBER_SETTING}`),
  //after clicked by user for any number for particular country,user click on pay button for annual and monthly popup
  buyNumber: postData => dispatch => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    return axios.post(base_url + 'numbers/number/create/buy', postData.number,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'id': postData.userId,
          'authtoken': postData.authToken,
          'billingtoken': postData.billingtoken,
        }
      }
    ).then(post => {
      let payloadData = {
        type: numberAction.BUY_NUMBER,
        payload: post
      }
      dispatch(payloadData)
      return payloadData
    }).catch((error) => {
      if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
        return ClearUserData(error.response);
      }
      let payloadData = {
        type: numberAction.BUY_NUMBER,
        payload: error.response
      }
      dispatch(payloadData);
      return payloadData
    })
  },

  sendDetailsForVoxbone: postData => dispatch => {
    axiosApi(`${base_url}number/sendDetailsForVoxbone/`, "post", postData, dispatch, `${numberAction.SEND_VOXBONE_DETAILS}`);
  },

  saveNumber: (numberId, numberData) => dispatch => {
    axiosApi(`${base_url}number/assignusers/` + numberId, "post", numberData, dispatch, `${numberAction.SAVE_NUMBER}`);
  },

  getNumbers: (postData = {}) => dispatch => {
    let pagination = postData.pagination ? `&pagination=${postData.pagination}` : '';
    let numberStatus = postData.numberStatus ? `&numberStatus=${postData.numberStatus}` : '';
    let search = postData.search ? `&search=${encodeURIComponent(postData.search)}` : '';
    let allData = postData.allData ? `&allData=${postData.allData}` : '';
    let systemDefaultNumberModule = postData.systemDefaultNumberModule ? `&systemDefaultNumberModule=${postData.systemDefaultNumberModule}` : '';
    return axiosApi(`${base_url}number/list?isAddUser=0&current=${postData.current ? postData.current : 1}${pagination}${numberStatus}${search}${allData}${systemDefaultNumberModule}`, "get", {}, dispatch, `${numberAction.GET_NUMBER}`)
  },

  getPortNumber: (id) => dispatch => {
    /* let pagination = postData.pagination ? `&pagination=${postData.pagination}` : '';
    let numberStatus = postData.numberStatus ? `&numberStatus=${postData.numberStatus}` : '';
    let search = postData.search ? `&search=${encodeURIComponent(postData.search)}` : '';
    let allData = postData.allData ? `&allData=${postData.allData}` : '';
    let systemDefaultNumberModule = postData.systemDefaultNumberModule ? `&systemDefaultNumberModule=${postData.systemDefaultNumberModule}` : ''; */
    return axiosApi(`${base_url}number/portnumber/${id || 'list'}`, "get", {}, dispatch, `${numberAction.GET_PORT_NUMBER}`)
  },
  deletePortNumber: id => dispatch => axiosApi(`${base_url}number/portnumber/${id}`, "delete", {}, dispatch, `${numberAction.DELETE_PORT_NUMBER}`),
  systemDefaultNumberAssign: postData => dispatch => axiosApi(`${base_url}numbers/number/systemDefaultNumberAssign`,"put", postData, dispatch, `${numberAction.SYSTEM_DEFAULT_NUMBER_ASSIGN}`),

  // getUnverifiedDocs: postdata => {
  //   return (dispatch, getstate) => {
  //     Axios.get(base_url + 'number/unveryfiednumberdoclist/' + postdata.userId,
  //       {
  //         headers: {
  //           'Accept': 'application/json',
  //           'Content-Type': 'application/json',
  //           'id': postdata.userId,
  //           'authtoken': postdata.authToken,
  //           'billingtoken': postdata.billingToken,
  //         }
  //       }
  //     ).then(postdata => {
  //       dispatch({
  //         type: numberAction.GET_UNVERIFIED_DOCS,
  //         postdata,
  //       })
  //     }).catch((error) => {
  //       if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
  //         return ClearUserData(error.response);
  //       }
  //       const postdata = error.response;
  //       dispatch({
  //         type: numberAction.GET_UNVERIFIED_DOCS,
  //         postdata,
  //       })
  //     });
  //   }
  // },
  getUnverifiedDocs: postdata => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    return (dispatch, getstate) => {
      Axios.get(base_url + 'number/unveryfiednumberdoclist/' + userId,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }
      ).then(postdata => {
        dispatch({
          type: numberAction.GET_UNVERIFIED_DOCS,
          postdata,
        })
      }).catch((error) => {
        if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
          return ClearUserData(error.response);
        }
        const postdata = error.response;
        dispatch({
          type: numberAction.GET_UNVERIFIED_DOCS,
          postdata,
        })
      });
    }
  },
  getCountries: postdata => {
    return (dispatch, getstate) => {
      Axios.get(base_url + 'number/countries',
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': postdata.userId,
            'authtoken': postdata.authToken,
            'billingtoken': postdata.billingToken,
          }
        }
      ).then(postdata => {
        dispatch({
          type: numberAction.GET_COUNTRIES,
          postdata,
        })
      }).catch((error) => {
        if(error && error.response && error.response.status && error.response.status==428){
          dispatch({
            type: numberAction.GET_COUNTRIES,
            postdata:{status:error.response.status, success:error.response.data.success, errMsg:error.response.data.error},
          });
        }else{
          if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
            return ClearUserData(error.response);
          }
          dispatch({
            type: "SET_CUSTOM_MESSAGE",
            payload: { message: error.response, type: "error" }
          });
        }
      });
    }
  },
  getAvailNumbers: postdata => {
    return (dispatch, getstate) => {
      // axiosApi(`${base_url}number/list?isAddUser=0`,"get",{},dispatch,`${numberAction.GET_NUMBER}`)
      Axios.post(base_url + 'numbers/number/create/available', postdata,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': postdata.userId,
            'authtoken': postdata.authToken,
            'billingtoken': postdata.billingtoken,
          }
        }
      ).then(postdata => {

        dispatch({
          type: numberAction.GET_AVAIL_NUMBER,
          postdata,
        })
      }).catch((error) => {
        if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
          return ClearUserData(error.response);
        }
        dispatch({
          type: numberAction.GET_AVAIL_NUMBER,
          postdata: error.response
        });
      });
    }
  },
  userbilling: postdata => {
    return (dispatch, getstate) => {
      Axios.get(base_url + 'number/' + postdata.userId + '/userbilling',
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': postdata.userId,
            'authtoken': postdata.authToken,
            'billingtoken': postdata.billingtoken,
          }
        }
      ).then(postdata => {
        dispatch({
          type: numberAction.USER_BILLING,
          postdata,
        })
      }).catch((error) => {
        if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
          return ClearUserData(error.response);
        } else {
          const postdata = error.response;
          if(postdata.status && postdata.status==428){
            history.push({  pathname: USER_PROFILE_DOCUMENT, state: {status:428}});
          }else if(postdata.status && postdata.status!=428){
            dispatch({
              type: numberAction.USER_BILLING,
              postdata,
            })
            dispatch({
              type: "SET_CUSTOM_MESSAGE",
              payload: { message: error.response.data && error.response.data.error.error ? error.response.data && error.response.data.error.error : "Something went wrong", type: "error" }
            });
          }
        }
      });
    }
  },
  getlocation: (id, skip, search) => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    return (dispatch, getstate) => {
      Axios.get(base_url + 'getlocation/' + id + '?' + (search ? 'searchString=' + search : '') + '&' + 'skip=' + skip,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }
      ).then(postdata => {
        dispatch({
          type: numberAction.GET_LOCATION,
          postdata,
        })
      }).catch((error) => {
        if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
          return ClearUserData(error.response);
        }
        const postdata = error.response
        dispatch({
          type: numberAction.GET_COUNTRIES,
          postdata,
        })
      });
    }
  },
  clearSystemVerifiedDocs: () => {
    return (dispatch) => {
      dispatch({
        type: numberAction.SYSTEM_VERIFIED_DOCS_CLEAR
      })
    }
  },
  getSystemVerifiedDocs: data => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    return (dispatch, getstate) => {
      Axios.post(base_url + 'number/systemverifieddocs/', data,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }
      ).then(postdata => {
        dispatch({
          type: numberAction.SYSTEM_VERIFIED_DOCS,
          postdata,
        })
      }).catch((error) => {
        if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
          return ClearUserData(error.response);
        }
        const postdata = error.response;
        dispatch({
          type: numberAction.SYSTEM_VERIFIED_DOCS,
          postdata,
        })
      });
    }
  },
  user: data => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    return (dispatch, getstate) => {
      Axios.post(base_url + 'billing/plan/user', data,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': data.userId,
            'authtoken': data.authToken,
            'billingtoken': data.billingtoken,
          }
        }
      ).then(postdata => {
        dispatch({
          type: numberAction.USER,
          postdata,
        })
        
        const prvAmountDueValue = userData.amountDue ? true : false ;
        if(postdata?.data?.data?.user && postdata.data.data.user.amountDue != prvAmountDueValue){
          let payload = { data: { amountDue: postdata.data.data.user.amountDue, blockReason : postdata.data.data.user.blockReason  }, "type": "amountDue" };
          dispatch({
            type: dashboardAction.PUSHER_DATA,
            payload,
          })
        }
      }).catch((error) => {
        if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
          return ClearUserData(error.response);
        }
        dispatch({
          type: numberAction.USER,
          payload: { message: error.response }
        });
      });
    }
  },
  cart_sub_create: data => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    return (dispatch, getstate) => {
      Axios.post('https://callhippotest-test.chargebee.com/api/v2/subscriptions/cart_sub_create?hp_token=YJwMrutDejR6YIeqceS0Ocd3JNs61EXcd6', data,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }
      ).then(postdata => {
        dispatch({
          type: numberAction.CART_UP_CREATE,
          postdata,
        })
      }).catch((error) => {
        if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
          return ClearUserData(error.response);
        }
        dispatch({
          type: "SET_CUSTOM_MESSAGE",
          payload: { message: error.response, type: "error" }
        });
      });
    }
  },
  chargebeeNewUser: data => dispatch => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    data.request_token = cookie.load("castleRequestToken") ? cookie.load("castleRequestToken") : ""
    return Axios.post(base_url + 'chargebee/user/v3/' + userId, data,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken,
        }
      }
    ).then(postdata => {
      let payloadData = {
        type: numberAction.CHARGEBEE_NEW_USER,
        postdata
      }
      dispatch(payloadData)
      return {payload: payloadData.postdata.data}
    }).catch((error) => {
      // if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
      //   return ClearUserData(error.response);
      // }
      const postdata = error.response;
      let payloadData = {
        type: numberAction.CHARGEBEE_NEW_USER,
        postdata
      }
      dispatch(payloadData)
      return {payload: payloadData?.postdata?.data || {}}
    });
  },
  createCbFtCustomerNSubcription: data => dispatch => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = !userData  ? '' : userData.authToken,
      userId = !userData  ? '' : userData._id,
      bilingtoken = !userData ? '' : userData.billingToken;
    return Axios.post(`${base_url}createCbFtCustomerNSubcription`, data,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken,
        }
      }
    ).then(postdata => {
      let payloadData = {
        type: numberAction.CHARGEBEE_NEW_USER,
        postdata
      }
      dispatch(payloadData)
      return {payload: payloadData.postdata.data}
    }).catch((error) => {
      const postdata = error.response;
      let payloadData = {
        type: numberAction.CHARGEBEE_NEW_USER,
        postdata
      }
      dispatch(payloadData)
      return {payload: payloadData?.postdata?.data || {}}
    });
  },
  sendDocument: (data) => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    return (dispatch, getstate) => {
      Axios.post(base_url + 'user/' + userId + '/address', data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }
      ).then(postdata => {
        dispatch({
          type: numberAction.SEND_DOCUMENT,
          postdata,
        })
      }).catch((error) => {
        if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
          return ClearUserData(error.response);
        }
        const postdata = error.response;
        dispatch({
          type: numberAction.SEND_DOCUMENT,
          postdata,
        })
      });
    }
  },
  portNumber: (data) => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    return (dispatch, getstate) => {
      return Axios.post(base_url + 'number/portnumber/' + userId, data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }
      ).then(postdata => {
        let returnData = {
          type: numberAction.PORT_NUMBER,
          payload: postdata.data,
        }
        dispatch(returnData)
        return returnData
      }).catch((error) => {
        if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
          return ClearUserData(error.response);
        }
        const postdata = error.response;
        let returnData = {
          type: numberAction.PORT_NUMBER,
          payload: postdata.data,
        }
        dispatch(returnData)
        return returnData
      });
    }
  },
  setting: (numberid) => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    return (dispatch, getstate) => {
      Axios.get(base_url + 'number/' + numberid + '/setting/details',
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }
      ).then(postdata => {
        dispatch({
          type: numberAction.NUMBER_SETTING,
          postdata,
        })
      }).catch((error) => {
        console.log("error", error)
        if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
          return ClearUserData(error.response);
        }
        dispatch({
          type: "SET_CUSTOM_MESSAGE",
          payload: { message: error.response, type: "error" }
        });
      });
    }
  },
  settingupdate: (postdata, numberid) => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    return (dispatch, getstate) => {
      if (numberid !== "dummy") {
        return Axios.put(base_url + 'number/' + numberid + '/setting', postdata,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'id': userId,
              'authtoken': authToken,
              'billingtoken': bilingtoken,
            }
          }
        ).then(postdata => {
          console.log('postdata-->>', postdata)
          dispatch({
            type: numberAction.NUMBER_SETTING_UPDATE,
            postdata,
          })
          return {payload: postdata.data}
        }).catch((error) => {
          console.log('error-->>', error)
          if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
            return ClearUserData(error.response);
          }      
          if (error && error.response && error.response.data) {
            let returnData = {
              type: "SET_CUSTOM_MESSAGE",
              payload: { message: error.response.data.error.error, type: "error" }
            }
            dispatch(returnData)
            return returnData
          }
          if (error.response.status == 403) {
            dispatch({
              type: "SET_CUSTOM_MESSAGE",
              payload: { message: error.response.data.error, type: "error" }
            });
          } else {
            dispatch({
              type: "SET_CUSTOM_MESSAGE",
              payload: { message: error.response, type: "error" }
            });
          }    

        });
      } else {
        dispatch({
          type: "SET_CUSTOM_MESSAGE",
          payload: { message: "Number updated successfully.", type: "success" }
        })
      }
    }
  },
  deleteNumber: (id, index) => dispatch => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    dispatch({ type: "CLEAR_CUSTOM_MESSAGE" });
    return axios.delete(`${base_url}number/${id}`,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken
        }
      }
    ).then(postData => {
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: "Number deleted successfully.", type: "success" }
      })
      dispatch({
        type: numberAction.NUMBER_DELETE,
        payload: index,
      });
      return postData;
    }).catch(error => {
      if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
        return ClearUserData(error.response);
      }
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: error.response && error.response.data ? error.response.data.error.error : "something went wrong", type: "error" }
      });
      return error
    })

  },

  restoreNumber: (id, index) => dispatch => axiosApi(`${base_url}number/${id}`,"put",{id:id},dispatch,`${numberAction.RESTORE_NUMBER}`),

  deleteivr: (numberid) => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    return (dispatch, getstate) => {
      Axios.delete(base_url + 'number/ivr/' + numberid,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }
      ).then(postdata => {
        dispatch({
          type: numberAction.DELETE_IVR,
          postdata,
        })
      }).catch((error) => {
        if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
          return ClearUserData(error.response);
        }
        dispatch({
          type: "SET_CUSTOM_MESSAGE",
          payload: { message: error.response, type: "error" }
        });
      });
    }
  },
  allocationtype: (postdata, numberid) => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    return (dispatch, getstate) => {
      if (numberid != "dummy") {
        Axios.put(base_url + 'number/allocationType/' + numberid, postdata,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'id': userId,
              'authtoken': authToken,
              'billingtoken': bilingtoken,
            }
          }
        ).then(postdata => {
          dispatch({
            type: numberAction.ALLOCATION_TYPE,
            postdata,
          })
        }).catch((error) => {
          if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
            return ClearUserData(error.response);
          }
          if (error.response.status == 403) {
            dispatch({
              type: "SET_CUSTOM_MESSAGE",
              payload: { message: error.response.data.error, type: "error" }
            });
          } else {
            dispatch({
              type: "SET_CUSTOM_MESSAGE",
              payload: { message: error.response, type: "error" }
            });
          }
        });
      }
    }
  },
  assignuser: (postdata, numberid) => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    let status = postdata.status;
    return (dispatch, getstate) => {
      Axios.put(base_url + 'number/' + numberid + '/assignUser', postdata,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }
      ).then(postdata => {
        dispatch({
          type: "SET_CUSTOM_MESSAGE",
          payload: { message: `User ${status == true ? 'allocated' : 'deallocated'} successfully.`, type: "success" },
        })
        dispatch({
          type: numberAction.ASSIGN_USER,
          postdata,
        })
      }).catch((error) => {
        if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
          return ClearUserData(error.response);
        }
        if (error.response.status == 403) {
          dispatch({
            type: "SET_CUSTOM_MESSAGE",
            payload: { message: error.response.data.error, type: "error" }
          });
        } else {
          dispatch({
            type: "SET_CUSTOM_MESSAGE",
            payload: { message: error.response, type: "error" }
          });
        }
      });
    }
  },
  assignMultipleUsers: (postData, numberid) => dispatch => axiosApi(`${base_url}number/assignMultipleUsers/${numberid}`, "post", postData, dispatch, `${numberAction.ASSIGN_ALL}`),
  assignteam: (postdata, numberid) => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    let status
    if (postdata.teamId != "") {
      status = true
    } else {
      status = false
    }
    return (dispatch, getstate) => {
      Axios.put(base_url + 'number/team/' + numberid, postdata,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }
      ).then(postdata => {
        dispatch({
          type: "SET_CUSTOM_MESSAGE",
          payload: { message: `Team ${status == true ? 'allocated' : 'deallocated'} successfully.`, type: "success" },
        })
        dispatch({
          type: numberAction.TEAM_ASSIGN,
          postdata,
        })
      }).catch((error) => {
        if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
          return ClearUserData(error.response);
        }
        if (error.response.status == 403) {
          dispatch({
            type: "SET_CUSTOM_MESSAGE",
            payload: { message: error.response.data.error, type: "error" }
          });
        } else {
          dispatch({
            type: "SET_CUSTOM_MESSAGE",
            payload: { message: error.response, type: "error" }
          });
        }
      });
    }
  },
  ivr: (postdata) => dispatch => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    return Axios.post(base_url + 'number/ivr', postdata,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken,
        }
      }
    ).then(postdata => {
      return dispatch({
        type: numberAction.IVR,
        postdata,
      })
    }).catch((error) => {
      if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
        return ClearUserData(error.response);
      }
      if (error.response.status == 403) {
        dispatch({
          type: "SET_CUSTOM_MESSAGE",
          payload: { message: error.response.data.error, type: "error" }
        });
      } else {
        dispatch({
          type: "SET_CUSTOM_MESSAGE",
          payload: { message: error.response, type: "error" }
        });
      }
    });

  },
  editivr: (postdata, id) => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    return (dispatch, getstate) => {
      Axios.put(base_url + 'number/ivr/' + id, postdata,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }
      ).then(postdata => {
        dispatch({
          type: numberAction.IVR,
          postdata,
        })
      }).catch((error) => {
        if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
          return ClearUserData(error.response);
        }
        dispatch({
          type: "SET_CUSTOM_MESSAGE",
          payload: { message: error.response, type: "error" }
        });
      });
    }
  },
  surveyAnswer: (postdata) => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    return (dispatch, getstate) => {
      Axios.post(base_url + 'number/surveyAnswer', postdata,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }
      ).then(postdata => {
        dispatch({
          type: numberAction.SURVEY_ANSWER,
          postdata,
        })
      }).catch((error) => {
        if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
          return ClearUserData(error.response);
        }
        dispatch({
          type: "SET_CUSTOM_MESSAGE",
          payload: { message: error.response, type: "error" }
        });
      });
    }
  },

  editanswer: (postdata, id) => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    return (dispatch, getstate) => {
      Axios.put(base_url + 'number/surveyAnswer/'+postdata.number, postdata,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }
      ).then(postdata => {
        dispatch({
          type: numberAction.SURVEY_ANSWER,
          postdata,
        })
      }).catch((error) => {
        if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
          return ClearUserData(error.response);
        }
        dispatch({
          type: "SET_CUSTOM_MESSAGE",
          payload: { message: error.response, type: "error" }
        });
      });
    }
  },

  deleteAnswer: (postdata, answerId) => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    return (dispatch, getstate) => {
      Axios.put(base_url + 'number/answer', postdata,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }
      ).then(postdata => {
        dispatch({
          type: numberAction.DELETE_ANSWER,
          postdata,
        })
      }).catch((error) => {
        if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
          return ClearUserData(error.response);
        }
        dispatch({
          type: "SET_CUSTOM_MESSAGE",
          payload: { message: error.response, type: "error" }
        });
      });
    }
  },
  teamlist: (id, postData) => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    let allTeamList = postData.allTeamList ? postData.allTeamList : false
    return (dispatch, getstate) => {
      Axios.get(base_url + 'team/list/' + id + '?allTeamList=' + allTeamList,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }
      ).then(postdata => {
        dispatch({
          type: numberAction.TEAM_LIST,
          postdata,
        })
      }).catch((error) => {
        if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
          return ClearUserData(error.response);
        }
        dispatch({
          type: "SET_CUSTOM_MESSAGE",
          payload: { message: error.response && error.response.data ? error.response.data.error.error : "something went wrong", type: "error" }
        });
      });
    }
  },
  getUsers: postData => dispatch => {
    let allUserList = postData && postData.allUserList ? `&allUserList=${postData.allUserList}` : '';
    axiosApi(`${base_url}user/list?current=${allUserList}`, "get", {}, dispatch, `${numberAction.GET_USERS}`)
  },

  getUserProfile: postdata => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    return (dispatch) => {
      return Axios.get(`${base_url}user/profile`,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }
      ).then(postdata => {
        dispatch({
          type: numberAction.GET_USER_PROFILE,
          postdata : postdata.data,
        })
        const prvAmountDueValue = userData.amountDue ? true : false ;
        if(postdata?.data?.data && postdata.data.data.amountDue != prvAmountDueValue){
          let payload = { data: { amountDue: postdata.data.data.amountDue, blockReason : postdata.data.data.blockReason }, "type": "amountDue" };
          dispatch({
            type: dashboardAction.PUSHER_DATA,
            payload,
          })
        }
        return {
          type: numberAction.GET_USER_PROFILE,
          payload : postdata.data,
        }
      }).catch((error) => {
      });
    }
  },

  uploadMusic: (postData, id, isUpdate) => (dispatch, getState) => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
    dispatch({ type: "CLEAR_CUSTOM_MESSAGE" })
    axios.post(`${base_url}number/${id}/uploadfile?isUpdate=${isUpdate}`, postData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken
        }
      }
    ).then((result) => {
      let numberDetail = { ...getState().number.number_setting };
      numberDetail.data.data.voiceMail = result.data.data.numberRes.voiceMail;
      numberDetail.data.data.afterHour = result.data.data.numberRes.afterHour;
      numberDetail.data.data.queueMusic = result.data.data.numberRes.queueMusic;
      numberDetail.data.data.callHoldFileName = result.data.data.numberRes.callHoldFileName;
      numberDetail.data.data.callHoldFilePath = result.data.data.numberRes.callHoldFilePath;
      numberDetail.data.data.ivrMusic = result.data.data.numberRes.ivrMusic;
      numberDetail.data.data.messageInfo = result.data.data.numberRes.messageInfo;


      dispatch({
        type: numberAction.NUMBER_SETTING,
        postdata: numberDetail
      });
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: "File uploaded successfully.", type: "success" }
      });
    }).catch((error) => {
      if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
        return ClearUserData(error.response);
      }
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: error.response, type: "error" }
      });
    })
  },

  featureUpgrade: (userCount, type, userId) => dispatch => {
    axiosApi(`${base_url}number/featureUpgrade/:userId`, "post", { userCount, type }, dispatch, `${numberAction.FEATURE_UPGRADE}`)
  },

  checkAccountInChargebee: (parentId) => dispatch => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken,
      id =  userData && userData.parentId ? userData.parentId  : userData && userData._id ? userData._id  : parentId;
    return Axios.post(base_url + 'checkAccountInChargebee/' + id,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken,
        }
      }
    ).then(postdata => {
      return dispatch({
        type: numberAction.CHECK_ACCOUNT_IN_CHARGEBEE,
        postdata,
      })
    }).catch((error) => {
      if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
        return ClearUserData(error.response);
      }
      dispatch({
        type: numberAction.CHECK_ACCOUNT_IN_CHARGEBEE,
        postdata: error.response
      });
    });
  },
  slackNotify: (postData) => () => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken,
      id =  userData && userData.parentId ? userData.parentId  :  userData._id ;
    return Axios.post(base_url + 'slacknotify',postData,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken,
        }
      }
    ).then(postdata => {    
    }).catch((error) => {     
    });
  },

  sendOTPOnCallForVerify: postData => dispatch => {
    axiosApi(`${base_url}number/sendOTPOnCallForVerify/`, "post", postData, dispatch, `${numberAction.SEND_OTP_ON_CALL}`);
  },
  verifyOTPOnCall: postData => dispatch => {
    axiosApi(`${base_url}number/verifyOTPOnCall/`, "post", postData, dispatch, `${numberAction.VERIFY_OTP_ON_CALL}`);
  },

  removeCustomCallerId: postData => dispatch => {
    axiosApi(`${base_url}number/removeCustomCallerId/`, "post", postData, dispatch, `${numberAction.REMOVE_CUSTOM_CALLER_ID}`);
  },

  cancelCallerIdPopUp: postData => dispatch => {
    axiosApi(`${base_url}number/cancelCallerIdPopUp/`, "post", postData, dispatch, `${numberAction.CANCEL_CALLERID_POPUP}`);
  },
  getWhatsappData: () => dispatch => axiosApi(`${base_url}whatsappnumber`, "get", {}, dispatch, `${numberAction.WHATSAPP_DATA}`),
  updateWhatsappData: (id) => dispatch => axiosApi(`${base_url}whatsappnumber`, "post", {id}, dispatch, `${numberAction.WHATSAPP_DATA}`),
  integrateWhatsapp: postData => dispatch => axiosApi(`${base_url}integratewhatsapp`, "post",postData , dispatch, `${numberAction.INTEGRATE_WHATSAPP_DATA}`),
  removeIntegrateWhatsapp : postData => dispatch => axiosApi(`${base_url}removeIntegrateWhatsapp`, "post",postData , dispatch, `${numberAction.REMOVE_INTEGRATE_WHATSAPP_DATA}`),
  getSmsUseCase: () => dispatch => axiosApi(`${base_url}number/getSmsUseCase`, "get", {}, dispatch, `${numberAction.SMS_USECASE}`),
  smsVerification : (postData,numberid) => {     
    const userData = JSON.parse(localStorage.getItem('userData')),
    authToken = userData == null ? '' : userData.authToken,
    userId = userData == null ? '' : userData._id,
    bilingtoken = userData == null ? '' : userData.billingToken;
    return (dispatch) => {
    if (numberid !== "dummy") {
      return Axios.post(base_url + 'number/' + numberid + '/smsUseCase', postData.smsUseCaseInfo,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }
      ).then(postdata => {
        dispatch({
          type: numberAction.SMS_VERIFICATION,
          postdata,
        })
        return {payload: postdata.data}
      }).catch((error) => {
        console.log('error-->>', error)
        if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
          return ClearUserData(error.response);
        }      
        if (error && error.response && error.response.data) {
          let returnData = {
            type: "SET_CUSTOM_MESSAGE",
            payload: { message: error.response.data.error.error, type: "error" }
          }
          dispatch(returnData)
          return returnData
        }
        if (error.response.status == 403) {
          dispatch({
            type: "SET_CUSTOM_MESSAGE",
            payload: { message: error.response.data.error, type: "error" }
          });
        } else {
          dispatch({
            type: "SET_CUSTOM_MESSAGE",
            payload: { message: error.response, type: "error" }
          });
        }
      });
    } else {
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: "Number updated successfully.", type: "success" }
      })
    }
  }},
  editSmsVerification : (postData,numberid) =>{
    const userData = JSON.parse(localStorage.getItem('userData')),
    authToken = userData == null ? '' : userData.authToken,
    userId = userData == null ? '' : userData._id,
    bilingtoken = userData == null ? '' : userData.billingToken;
    return (dispatch) => {
    if (numberid !== "dummy") {
      return Axios.post(base_url + 'number/' + numberid + '/smsUseCase', postData,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }
      ).then(postdata => {
        dispatch({
          type: numberAction.SMS_VERIFICATION,
          postdata,
        })
        return {payload: postdata.data}
      }).catch((error) => {
        console.log('error-->>', error)
        if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
          return ClearUserData(error.response);
        }      
        if (error && error.response && error.response.data) {
          let returnData = {
            type: "SET_CUSTOM_MESSAGE",
            payload: { message: error.response.data.error.error, type: "error" }
          }
          dispatch(returnData)
          return returnData
        }
        if (error.response.status == 403) {
          dispatch({
            type: "SET_CUSTOM_MESSAGE",
            payload: { message: error.response.data.error, type: "error" }
          });
        } else {
          dispatch({
            type: "SET_CUSTOM_MESSAGE",
            payload: { message: error.response, type: "error" }
          });
        }
      });
    } else {
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: "Number updated successfully.", type: "success" }
      })
    }
    }
  },
  removeRejectDocument: postData => dispatch => {
    axiosApi(`${base_url}number/removeRejectDocument/`, "post", postData, dispatch, `${numberAction.REMOVE_ADDRESS_DOCUMENT_ID}`);
  },
}
export default numberAction;
