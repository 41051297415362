import React, { memo, useEffect } from 'react';
import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import { BrowserRouter } from 'react-router-dom';
import PusherConnection from './pusher';
import moment from "moment";
import 'react-multi-carousel/lib/styles.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import KommunicateChat from "./kommunicateChat";

loadProgressBar({ showSpinner: false });
const App = (props) => {

  useEffect(() => {    
    // if(
    //   props.auth.isAuthenticated &&
    //   props.auth.userData.data &&
    //   props.auth.userData.data.createdDate &&
    //   moment().diff(moment(props.auth.userData.data.createdDate), 'days') > 7
    // ){
    //   const script = document.createElement("script");
    //   // fscript.src = "//fast.appcues.com/106946.js";
    //   document.body.appendChild(script);
    // }
    if(
      process.env.REACT_APP_ENVIRONMENT !== "LOCAL" &&
      props.auth.isAuthenticated &&
      props.auth.userData.data &&
      props.auth.userData.data.createdDate && 
      moment().diff(moment(props.auth.userData.data.createdDate), 'days') > 7
    ){
      const pfscript = document.createElement("script");
      var userInfo = {
        username: props.auth.userData.data?.email, // REQUIRED - any unique user identifier
        email: props.auth.userData.data?.email,      
        signUpAt: props.auth.userData.data.createdDate,
        role: props.auth.userData.data?.roleData?.name,
        props: { 
          name: props.auth.userData.data?.fullName,
          plan: props.auth.userData.data?.plan?.planDisplayName,
          credit: props.auth.userData.data?.totalCredits,
          sub_users: props.auth.userData.data?.users,
          numbers: props.auth.userData.data?.numbers
        }
      };
      userInfo = JSON.stringify(userInfo)
      // pfscript.innerHTML = "$productFruits.push(['init', 'nFpIQGVf7DztQcnc', 'en', { username: '"+props.auth.userData.data.email+"' }]);";
      pfscript.innerHTML = `$productFruits.push(['init', '${process.env.REACT_APP_PRODUCT_FRUIT_KEY}', 'en', ${userInfo}]);`;
      document.body.appendChild(pfscript);
    }
  },[props.auth.isAuthenticated]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID}>
      <AppProvider>
        <>
          {process.env.REACT_APP_ENVIRONMENT !== "LOCAL" && <KommunicateChat />}
          <GlobalStyles />
          {props.auth.isAuthenticated == true && props.auth.userData.data && Object.keys(props.auth.userData.data).length >= 1}
          {/* {props.auth.isAuthenticated == true && <PusherConnection userData ={props.auth.userData.data} />} */}
          <Routes />
        </>
      </AppProvider>
    </GoogleOAuthProvider>
)};

Boot()
  .then(() => App())
  .catch(error => console.error(error));

const mapStateToProps = (state) => {
  return {
    auth: state.authentication
  }
}

export default connect(mapStateToProps, {})(memo(App));
