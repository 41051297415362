import React from 'react';
import { Link } from 'react-router-dom';
//import chlogo from '../../assets/images/chlogo.svg';
import chcollapselogo from '../../assets/images/chcollapselogo.svg';
import { APP_PRIVATE_ROUTE } from '@iso/components/root/routeConstants';
const imageUrl = process.env.REACT_APP_IMAGE_URL;
const chlogo = imageUrl+'chlogo.svg';
const { DASHBOARD, ACTIVITYFEED } = APP_PRIVATE_ROUTE;
export default ({ collapsed, isBlocked }) => {
  let HOME = process.env.REACT_APP_REPORTS_APP ? ACTIVITYFEED : DASHBOARD;
  return (
    <div className="isoLogoWrapper chflex chflexcenter">
      {/* {collapsed ? (
        <div>
          <h3>
            <Link to={!isBlocked && HOME}>
              <img className="chlogocollapse" src={chcollapselogo} alt="chlogo" />
            </Link>
          </h3>
        </div>
      ) : (
      )} */}
      <Link to={!isBlocked && HOME} className='chLogoLink'>
        <img className="chlogot" src={collapsed ? chcollapselogo : chlogo} alt="Callhippo Logo" />
      </Link>
    </div>
  );
};
