import React, { lazy, Suspense } from 'react';
import PrivateRoute from "./privateRoute";
import Home from "./home";
import {
  Route,
  //BrowserRouter as Router,
  Router,
  // HashRouter as Router,
  Switch,
} from 'react-router-dom';
import history from '@iso/lib/helpers/history';

import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from '@iso/components/root/routeConstants';
import Loader from '@iso/components/utility/loader';
import ToastMessage from "./ToastMessageComponent";

let publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.LOGIN_IN,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() => import('@iso/containers/Pages/SignIn/ResetPassword')),
  },
  {
    path: PUBLIC_ROUTE.ATTEMPT_FAIL,
    component: lazy(() => import('@iso/containers/Pages/SignIn/AttemptFail')),
  },
  {
    path: PUBLIC_ROUTE.SUB_USER_INVITE,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SubUserInvite')),
  },
  {
    path: PUBLIC_ROUTE.MAGIC_LINK,
    component: lazy(() => import('@iso/containers/Pages/SignIn/MagicLink')),
  },
  {
    path: PUBLIC_ROUTE.GOOGLE_SIGNIN,
    component: lazy(() => import('@iso/containers/Pages/SignIn/GoogleDirectLogin')),
  },
  {
    path: PUBLIC_ROUTE.NORMAL_DIRECT_LOGIN,
    component: lazy(() => import('@iso/containers/Pages/SignIn/NormalDirectLogin')),
  },
  {
    path: PUBLIC_ROUTE.DIRECT_LOGIN,
    component: lazy(() => import('@iso/containers/DirectLogin/index')),
  },
  {
    path: PUBLIC_ROUTE.CONFIRM_SIGN_UP_ACCOUNT,
    component: lazy(() => import('@iso/containers/Pages/SignUp/ConfirmSignUpAccount')),
  },
  {
    path: PUBLIC_ROUTE.CONFIRM_SIGN_UP_ACCOUNT_OLD,
    component: lazy(() => import('@iso/containers/Pages/SignUp/ConfirmSignUpAccount')),
  },
  {
    path: PUBLIC_ROUTE.CHANGE_EMAIL,
    component: lazy(() => import('@iso/containers/UserProfile/changeEmail')),
  },
  {
    path: PUBLIC_ROUTE.AUTH_PROFILE,
    component: lazy(() => import('@iso/containers/AuthProfile'))
  },
  {
    path: PUBLIC_ROUTE.SET_PASSWORD,
    component: lazy(() => import('@iso/containers/AuthProfile'))
  },
  {
    path: PUBLIC_ROUTE.INSTALL_BITRIX,
    component: lazy(() => import('@iso/containers/Integrations/installBitrix'))
  },
  {
    path: PUBLIC_ROUTE.DESKTOP_ONBOARDING_APP,
    component: lazy(() => import('@iso/containers/DesktopApp'))
  },
  {
    path: PUBLIC_ROUTE.SCHEDULE_DEMO,
    component: lazy(() => import('@iso/containers/ScheduleDemo/index.js')),
  },
];

if (process.env.REACT_APP_ENVIRONMENT !== "LIVE") {
  publicRoutes.push(
    {
      path: PUBLIC_ROUTE.SIGN_UP,
      component: lazy(() => import('@iso/containers/Pages/SignUp/SignUp')),
    }
  );
}

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <ToastMessage />
        <Router history={history}>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
