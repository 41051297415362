import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';
import dashboardActions from '@iso/redux/dashboard/actions';
import AddNumberModalStyles from './AddNumberModal.styles';
import integrationsvg from '../../assets/images/Integrations.svg';
import { APP_PRIVATE_ROUTE } from '@iso/components/root/routeConstants';
const imageUrl = process.env.REACT_APP_IMAGE_URL;
const localNumber = imageUrl+"local_number-1.svg";
const voiceMail = imageUrl+"voice_mail-1.svg";
const ivr = imageUrl+"IVR_81147.svg";
const { ADD_NUMBER } = APP_PRIVATE_ROUTE;

const { triggerAddNumberPopup } = dashboardActions;
const IsoAddNumberModal = AddNumberModalStyles(Modal);
const AddNumberModal = WithDirection(IsoAddNumberModal);

class AddNumberPopup extends Component {

    constructor(props) {
        super(props);
        /* this.state = {} */
    }

    handleCancel = () => {
        console.log("Purchase a Number...",this.props.userData.data)
        window.mixpanel.track("Click on purchase a number.",{distinct_id: this.props.userData && this.props.userData.data.email,parent_id:  this.props.userData.data && this.props.userData.data.parentEmail?this.props.userData.data.parentEmail:this.props.userData.data.email});
        this.props.triggerAddNumberPopup(false)
    };
    render() {
        return (
            <div>
            { (this.props.userData && this.props.userData.data && this.props.userData.data.roleData && this.props.userData.data.roleData.priority != 4 && !this.props.userData.data.accountStatus) ? (<AddNumberModal
                className="addNumberModal"                
                visible= {!this.props.isOnBoardingProcessPending && this.props.trigger_add_number_popup && this.props.history.location.pathname != '/addnumber'&& this.props.history.location.pathname != '/dashboard' && (!(this.props.userData.data.numbers || this.props.userData.data.deletedNumbers))}
                onCancel={this.handleCancel}
                title={<span>Hey {this.props.userData.data.fullName} Welcome Aboard
                        <p  className="callcountryheading">A glimpse of our top features!!</p>  
                    </span>}
                footer={[
                    null
                ]}
            >
                <div>
                    <div className="addNumberModalWrapper">
                        <div className="callcountries">
                            <img src={ivr} className="callcountryimage" />
                            <div className="callcountryheading">Auto Dialer</div>
                        </div>
                        <div className="callcountries">
                            <img src={integrationsvg} className="callcountryimage" />
                            <div className="callcountryheading">Integrations</div>
                        </div>
                        <div className="callcountries">
                            <img src={localNumber} className="callcountryimage" />
                            <div className="callcountryheading">Smart DID Routing</div>
                        </div>
                    </div>
                    {/* <div className="endtourtextwrapper">
                        <h4 className="manymoretext">Click here for <a href="https://callhippo.com/features" target="_blank">Many More</a></h4>
                    </div> */}
                    <div className="endtourtextwrapper">
                        <h4 className="manymoretext">Let’s get started by buying a number.</h4>
                    </div>
                    <div className="endtourbtnwrapper">
                        <button className="etaddnumber" onClick={() => { this.handleCancel(); this.props.history.push(ADD_NUMBER)}}  style={{textTransform:'capitalize'}}>Buy A Number</button>
                    </div>
                </div>
            </AddNumberModal>):null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.authentication.userData,
        trigger_add_number_popup: state.authentication.trigger_add_number_popup,
        numbers:state.number.get_number,
        isOnBoardingProcessPending:state.authentication.isOnBoardingProcessPending
    }
}
export default connect(mapStateToProps, { triggerAddNumberPopup })(withRouter(AddNumberPopup));