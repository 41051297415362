import getLogs from './actions';
const moment = require('moment-timezone');
const callStatusOp = ['Completed', 'Missed', 'No Answer', 'Voicemail', 'Rejected', 'Cancelled', 'Call not setup', 'Unavailable', 'IVR message', 'Welcome message'];
const userData = JSON.parse(localStorage.getItem('userData'));
var startDate = userData && userData.timezone ? moment().tz(userData.timezone).subtract(7,'days') : "";

const initState = {
    get_calllogs: [],
    get_calllogs_csv: [],
    get_filter_count : 0,
    query:{
                skip:0,
                limit:50,
                startDate: startDate && startDate._d ? startDate._d  : moment().subtract(7,'days').toDate()
                // endDate: new Date()
    },
    get_smslogs: [],
    get_smslogs_csv: [],
    get_feedback: [],
    add_feedback: [],
    get_calltranscript: [],
    selected_callsid: {
        payload: [],
        callSid: []
    } ,
    delete_calllogs: [],
    get_callCount: {},
    get_tags: [],
    get_tracking_calllogs: [],
    tracking_log: [],
    tracking_report: [],
    export_tracking_report:[],
    get_tracking_logs_csv:[],
    get_leaderboard_list: [],
    numberMasking: []
}

export default function activityReducer(state = initState, action) {
    let filterCount = 0;
    switch (action.type) {
        case getLogs.GET_CALLLOGS:
            let newCallLogs = [];
            
            if(action.filter){
                newCallLogs = action.postData.data.data.callLogs
                filterCount = action.postData.data.data.filterCount
            }else{
                newCallLogs = [...state.get_calllogs, ...action.postData.data.data.callLogs];
                filterCount = action.postData.data.data.filterCount
            }
            return {
                ...state,
                get_calllogs: newCallLogs,
                get_callCount: action.postData.data.data,
                get_filter_count : filterCount,
                numberMasking: action.postData.data.commonData.numberMasking
            };

        case getLogs.GET_CALLLOGS_CSV:
            return {
                ...state,
                get_calllogs_csv: action.postData,
            };
            
        case getLogs.GET_SMSLOGS:
            let logs;
            if(action.filter){
                logs = action.postData.data.data;
                filterCount = action.postData.data.data.filterCount;
            }else{
                if(action.postData && action.postData.data && action.postData.data.data && action.postData.data.data.smsLogs && state.get_smslogs && state.get_smslogs.smsLogs){
                    action.postData.data.data.smsLogs = state.get_smslogs.smsLogs.concat(action.postData.data.data.smsLogs);
                    logs = action.postData.data.data
                    filterCount = action.postData.data.data.filterCount
                }else{
                    logs = state.get_smslogs
                    filterCount = action.postData.data.data.filterCount
                }
            }
            return {
                ...state,
                get_smslogs: logs,
                numberMasking: action.postData.data.commonData.numberMasking,
                get_filter_count : filterCount
            };

        case getLogs.GET_SMSLOGS_CSV:
            return {
                ...state,
                get_smslogs_csv: action.postData,
            };
        case getLogs.GET_CALLRECORD_CSV:
            return {
                ...state,
                get_callrecord_csv: action.postData,
            };

        case getLogs.GET_FEEDBACK:
            return {
                ...state,
                get_feedback: action.postData,
            }
        case getLogs.ADD_FEEDBACK:
            return{
                ...state,
                add_feedback: action.postData,
            };
            
        case getLogs.GET_CALLTRANSCRIPT:
            return {
                ...state,
                get_calltranscript: action.postData
            };
        case getLogs.GET_LEADERBOARD_LIST:
            return {
                ...state,
                get_leaderboard_list: action.postData
            };
        case "CHANGE_QUERY":
            return {
                ...state,
                query:action.payload 
            };
            
        case "SELECTED_CALLSID":
            let callLogs = {...state.get_calllogs};
            let allCallSidsCount = {...state.get_callCount}
            let unSelectCallSidData = {...state.unSelectCallSid}
            if(action.payload == 'all'){
                let data ={
                    payload: action.payload,
                    callSid : []                
                }
                let unSelectCallSid ;
                Object.keys(callLogs).forEach(callLog => {
                    // data.callSid.push(callLogs[callLog].callSid); // Some call log have no CallSid 
                    // console.log(callLogs[callLog]._id,"callLogs[callLog]")
                    // data.callSid.push(callLogs[callLog].callSid ? callLogs[callLog].callSid:callLogs[callLog]._id);
                    data.callSid.push(callLogs[callLog]._id);
                });
                if(Object.keys(unSelectCallSidData).length > 0){
                    unSelectCallSid = []
                    Object.values(unSelectCallSidData).map((callSid ,index)=> {
                        data.callSid.splice(data.callSid.indexOf(callSid), 1)
                        unSelectCallSid.push(callSid);
                    });
                }else{
                    unSelectCallSid = []
                }
                data.allCallSidsCount = allCallSidsCount.callLogCount
                return {
                    ...state,
                    selected_callsid:data,
                    unSelectCallSid:unSelectCallSid.length > 0 ? unSelectCallSid:[]
                };
            }else if(action.payload == 'none'){
                return {
                    ...state,
                    selected_callsid:{
                        payload: action.payload,
                        callSid: [],
                        allCallSidsCount : allCallSidsCount.callLogCount
                    },
                    unSelectCallSid:[]
                };
            }else if(Object.keys(callLogs).length == 0){
                return {
                    ...state,
                    selected_callsid:{
                        payload: action.payload,
                        callSid: []
                    },
                    unSelectCallSid:[]
                };
            }else{
                let allCallSidsCount = {...state.get_callCount}
                return {
                    ...state,
                    selected_callsid:{
                        payload: action.payload,
                        callSid:action.payload.callSid,
                        allCallSidsCount : allCallSidsCount.callLogCount
                    },
                    unSelectCallSid:action.payload.unCallsid
                };
            }

        case "DELETE_CALLLOGS":
            return {
                ...state,
                delete_calllogs:action.postData.data
            };
        case getLogs.GET_TAGS:
            return {
                ...state,
                get_tags:action.postData.data
            };

        case getLogs.GET_TRACKING_CALLLOGS:
            let newTrackingCallLogs = [];
            if(action.filter){
                newTrackingCallLogs = action.postData.data.data.callLogs
            }else{
                newTrackingCallLogs = state.get_tracking_calllogs.concat(action.postData.data.data.callLogs);
            }
            return {
                ...state,
                get_tracking_calllogs: newTrackingCallLogs,
            };
        case getLogs.GET_TRACKING_LOG:            
            return {
                ...state,
                tracking_log: action.postData.data.data.callLog,
            };     
        case getLogs.GET_TRACKING_REPORT:       
            return {
                ...state,
                tracking_report: action.postData.data.data.report,
            };
        case getLogs.EXPORT_TRACKING_REPORT:        
            return {
                ...state,
                export_tracking_report: action.postData,
            };                   
        case getLogs.GET_TRACKING_LOGS_CSV:
                return {
                    ...state,
                    get_tracking_logs_csv: action.postData,
                };    
        // case "CHANGE_FROM_NUMBER":
        //  let newFrom = {...state.query};
        //  newFrom['fromNumber'] = action.payload;
        //  return {
        //      ...state,
        //      query:newFrom
        //  }
        //  case "CHANGE_TO_NUMBER":
        //     let newTo = {...state.query};
        //     newTo['toNumber'] = action.payload;
        //     return {
        //         ...state,
        //         query:newFrom
        //     }
        //   case "CHANGE_START_DATE":
        //     let newStart = {...state.query};
        //     newStart['startDate'] = action.payload;
        //     return {
        //         ...state,
        //         query:newStart
        //     }
        //   case "CHANGE_CALL_TYPE":
        //     let newCallType = {...state.query};
        //     newCallType["callType"] = action.payload;
        //       return {
        //         ...state,
        //         query:newCallType
        //       }
        //   case "CHANGE_USERS":
        //     let newUserCheckList = {...state.query};
        //     console.log("change_users",newUserCheckList);
        //     newUserCheckList["user"] = action.payload;
        //     return {
        //         ...state,
        //         query:newUserCheckList
        //     }
        //  case "CHANGE_STATUS_CHECKLIST":
        //   let newChangeStatus = {...state.query};
        //   let statusCheckList = action.payload;
        //   if(action.paylod.includes("check-all")){
        //         statusCheckList = callStatusOp;
        //     }else if(action.payload.includes("uncheck-all")){
        //         statusCheckList = []
        //     }
        // newChangeStatus['callBackStatus'] = statusCheckList;
        //   return {
        //       ...state,
        //       query:newChangeStatus
        //   }
        //  case "CHANGE_POWER_DIALER":
        //    let changePowerDialer = {...state.query};
        //    changePowerDialer["callType"] = action.payload
        //   return {
        //       ...state,
        //       query:changePowerDialer
        //   }  
        case getLogs.UPDATE_CALLLOGS_DATA:
            const callLogArr = state.get_calllogs;
            console.log(action.payload.callSid,"action.payload.callSid")
            console.log("action",action)
            const findArrayIndex = callLogArr && callLogArr.map(function(e) { return e.callSid; }).indexOf(action.payload.callSid);
            console.log(findArrayIndex,"findArrayIndex",callLogArr[findArrayIndex])
            if(action.payload.noteText){
                callLogArr[findArrayIndex].callNotes ={description:action.payload.noteText};
            }
            if(action.payload.callTags){
                callLogArr[findArrayIndex].callTags = action.payload.callTags;
            }
            console.log(callLogArr,"callLogArr")
                return {
                    ...state,
                    get_calllogs: callLogArr,
                };   
        case getLogs.GET_CALLLOGS_DOWNLOAD_COLUMN:
            return {
                ...state,
                get_calllog_download_column: action.postData.data.data
            };
        case getLogs.UPDATE_CALLLOGS_DOWNLOAD_COLUMN:
            return {
                ...state,
                update_calllog_download_column: action.postData
            };

        default:
            return state;
    }
}