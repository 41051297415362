import planSetting from './actions';
const initState = {
  get_plan: [],
  get_scheduled_changes: [],
  rech_status: [],
  coupon_status: [],
  cards: [],
  api_token: [],
  user_profile: [],
  add_contact: [],
  remove_contact: [],
  num_block: [],
  num_list: [],
  add_credits: [],
  rech_value: [],
  plan_upgrade: [],
  rmnum_block: [],
  get_ip_address: [],
  add_ip_address: [],
  delete_ip_address: [],
  access_module: [],
  get_holiday_data: [],
  delete_holiday: [],
  get_users_team_list: [],
  add_edit_holiday: [],
  voicemail_holiday: [],
  recording_rights : [],
  price_limit: [],
  get_amountdue: [],
  pay_amountdue: [],
  defaultCountryCode:"",
  audit_logs : [],
  report_status : [],
  set_reports : [],
  add_webhook_url : [],
  get_webhook_list:[],
  remove_webhook_url:[],
  update_webhook_url : [],

  update_addons : [],
  update_userSeat:[],
  delete_account:[],
  resume_account:[],
  get_estimation:[],
  reactivate_account:[]

}

export default function planReducer(state = initState, action) {
  switch (action.type) {
    case planSetting.GET_PLAN:
      return {
        ...state,
        get_plan: action.payload
      };
    case planSetting.GET_SCHEDULED_CHANGES:
      return{
        ...state,
        get_scheduled_changes: action.payload
      };
    case planSetting.RECH_STATUS:
      return {
        ...state,
        rech_status: action.payload
      };
    case planSetting.CARDS:
      return {
        ...state,
        cards: action.payload
      };
    case planSetting.API_TOKEN:
      return {
        ...state,
        api_token: action.payload
      };
    case planSetting.CONTROLHIPPO_TOKEN:
      return {
        ...state,
        api_token: action.payload
      };
    case planSetting.USER_PROFILE:
      return {
        ...state,
        user_profile: action.payload
      };
    case planSetting.ADD_CONTACT:
      return {
        ...state,
        add_contact: action.payload
      };
    case planSetting.REMOVE_CONTACT:
      return {
        ...state,
        remove_contact: action.payload
      };
    case planSetting.NUM_BLOCK:
      return {
        ...state,
        num_block: action.payload
      };
    case planSetting.NUM_LIST:
      return {
        ...state,
        num_list: action.payload
      };
    case planSetting.ADD_CREDITS:
      return {
        ...state,
        add_credits: action.payload
      };
    case planSetting.RECH_VALUE:
      return {
        ...state,
        rech_value: action.payload
      };
    case planSetting.PLAN_UPGRADE:
      return {
        ...state,
        plan_upgrade: action.payload
      };
    case planSetting.RMNUM_BLOCK:
      return {
        ...state,
        rmnum_block: action.payload
      };

    case planSetting.UPDATE_NUM_BLOCK:
      return {
        ...state,
        update_num_block: action.payload
      }

    case planSetting.GET_IP_ADDRESS:
      return {
        ...state,
        get_ip_address: action.payload
      };
    case planSetting.ADD_IP_ADDRESS:
      return {
        ...state,
        add_ip_address: action.payload
      };
    case planSetting.DELETE_IP_ADDRESS:
      return {
        ...state,
        delete_ip_address: action.payload
      };
    case planSetting.ACCESS_MODULE:
      return {
        ...state,
        access_module: action.payload
      };
    case planSetting.GET_HOLIDAY_DATA:
      return {
        ...state,
        get_holiday_data: action.payload
      };
    case planSetting.DELETE_HOLIDAY:
      return {
        ...state,
        delete_holiday: action.payload
      };
    case planSetting.ADD_EDIT_HOLIDAY:
      return {
        ...state,
        add_edit_holiday: action.payload
      };
    case planSetting.GET_USERS_TEAM_LIST:
      return {
        ...state,
        get_users_team_list: action.payload
      };
    case planSetting.VOICEMAIL_HOLIDAY:
      return {
        ...state,
        voicemail_holiday: action.payload
      };
      case planSetting.RECORDING_RIGHTS:
      return {
        ...state,
        recording_rights: action.payload
      };
    case planSetting.price_limit:
      return {
        ...state,
        price_limit: action.payload
      };
    case planSetting.DEFAULT_COUNTRY_CODE:
      console.log('147 DEFAULT_COUNTRY_CODE from reducer',action)
      return{
        ...state,
        defaultCountryCode:action.payload
      };
    case planSetting.GET_AMOUNT_DUE:
      return{
        ...state,
        get_amountdue:action.payload
      };
    case planSetting.PAY_AMOUNT_DUE:
      return{
        ...state,
        pay_amountdue:action.payload
      };
      case planSetting.UPDATE_USER_PLAN_DATA:
        let get_plan = state.get_plan;
        get_plan.data.availableCredits.availableCredits = action.payload.availableCredits;
      return {
        ...state,
        get_plan: get_plan
      };
      case planSetting.AUDIT_LOGS:
      return{
        ...state,
        audit_logs:action.payload
      };
      case planSetting.REPORT_STATUS:
      return{
        ...state,
        report_status:action.payload
      };
      case planSetting.SET_REPORTS:
      return{
        ...state,
        set_reports:action.payload
      };
      case planSetting.ADD_WEBHOOK_URL:
      return{
        ...state,
        add_webhook_url:action.payload
      };
      case planSetting.GET_WEBHOOK_LIST:
      return{
        ...state,
        get_webhook_list:action.payload
      };
      case planSetting.REMOVE_WEBHOOK_URL:
        return{
          ...state,
          remove_webhook_url:action.payload
        };
      case planSetting.UPDATE_WEBHOOK_URL:
        return{
          ...state,
          update_webhook_url:action.payload
        };
      case planSetting.UPDATE_ADDONS:
      return{
        ...state,
        update_addons:action.payload
      };
      case planSetting.UPDATE_USERSEAT:
      return{
        ...state,
        update_userSeat:action.payload
      };
      case planSetting.DELETE_ACCOUNT:
      return{
        ...state,
        delete_account:action.payload
      };
      case planSetting.RESUME_ACCOUNT:
      return{
        ...state,
        resume_account:action.payload
      };
      case planSetting.GET_ESTIMATION:
      return{
        ...state,
        get_estimation :action.payload
      }
      case planSetting.REACTIVATE_ACCOUNT:
      return{
        ...state,
        reactivate_account :action.payload
      }

      case planSetting.DOWNLOAD_DNC:
      return{
        ...state,
        download_dnc: action.payload
      }
    default:
      return state;
  }
}

