import styled from 'styled-components';
const TickIcon = 'tick_svg_new.svg'

const AddNumPopup = ComponentName => styled(ComponentName)`
    top:-20px !important;
    display: flex;
    .ant-modal-content {
        width: 100%;
    }
    //new css for new addno popup 02-11-23 start
    .ant-modal-title{
        color: #000;
        font-size: 22px !important;
        font-weight: 500 !important;
        line-height: normal !important;
        text-align: center !important;
    }
    .ant-modal-header{
        padding:26px 0px 0px !important;
        border-bottom: unset !important;
        border-radius: 14px 14px 0 0;
    }
    .ant-modal-content{
        border-radius: 14px !important;
        .ant-modal-close{
            top: 10PX !important;
            right: 10PX !important;
        }
        .ant-modal-close-x{
            width: 16PX;
            height: 16PX;
            display: flex;
            align-items: center;
            justify-content: center;
            .anticon{
                font-size:14px !important;
            }
        }
    }
    .ant-modal-body {
        height: 100%;
        width: 100%;
        padding:23px 32px !important;
        .ant-radio-group{
            display:flex;
            gap:20px !important;
            .callCentreSolutionBtn,.officePhoneBtn{
                border-radius: 6px;
                width: 159px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height:normal !important;
                font-size: 14px;
                font-weight: 600;
                border:unset !important;
            }
            .officePhoneBtn{
                background: #FFE3D7 !important;
                color: #A93500 !important;
            }
            .callCentreSolutionBtn{
                background: #CFE2FF !important;
                color: #0845A5 !important;
            }
            .ant-radio-button-wrapper.officePhoneSysCard,
            .ant-radio-button-wrapper.officePhoneSysCard .freeTrialUserCard{
                background: linear-gradient(134deg, #FDF6EE 2.39%, #FBFCFE 129.82%) !important;
            }
            .ant-radio-button-wrapper.callCentreSolution,
            .ant-radio-button-wrapper.callCentreSolution .freeTrialUserCard{
                background: linear-gradient(134deg, #EFF6FF 2.39%, #FBFCFE 129.82%) !important;
            }
            .ant-radio-button-wrapper-checked.officePhoneSysCard{
                .planSelectBottom .selectPlanBtn{
                    background-color:#E37949 !important;
                    color: #fff !important;
                    border-color:#E37949 !important;
                    .textWithIcon{
                        display: flex !important;
                    }
                    .textOnly{
                        display: none !important;
                    }
                }
            }
            .ant-radio-button-wrapper-checked.callCentreSolution{
                .planSelectBottom .selectPlanBtn{
                    background-color:#0265FF !important;
                    color: #fff !important;
                    border-color:#0265FF !important;
                    .textWithIcon{
                        display: flex !important;
                    }
                    .textOnly{
                        display: none !important;
                    }
                }
            }
            .officePhoneSysCard{
                .planSelectBottom .selectPlanBtn{
                    border: 1px solid #E37949;
                    color: #E37949 !important;
                    .textWithIcon{
                        display: none !important;
                    }
                    .textOnly{
                        display: inline-block !important;
                    }
                }
            }
            .callCentreSolution{
                .planSelectBottom .selectPlanBtn{
                    border: 1px solid #0265FF;
                    color: #0265FF !important;
                    .textWithIcon{
                        display: none !important;
                    }
                    .textOnly{
                        display: inline-block !important;
                    }
                }
            }
            .ant-radio-button-wrapper {
                height: 100% !important;
                border-radius: 10px !important;
                border: 2px solid transparent !important;
                padding: 0 !important;
                .freeTrialUserCard{
                    border-radius: 10px !important;
                    border: unset !important;
                    padding: 18px !important;
                    flex:1 !important;
                    .planSelectCardBodyInner,
                    .planSelectCardBodyInnerTxt,
                    .planSelectCardBodyInnerTxt ul{
                        display: flex !important
                        flex-direction: column !important;
                    }
                    .planSelectCardBodyInner{
                        // height: 82.5% !important;
                        margin:10px 0px 0px !important;
                        justify-content: space-between !important;
                        .planSelectCardBodyInnerTxt{
                            gap: 16px !important;
                        }
                        p{
                            line-height:1.5em !important;
                            margin-top:8px !important;
                        }
                        p,ul li{
                            font-size:12px !important;
                            color: #000 !important;
                        }
                        ul{
                            gap:12px !important;
                            list-style:none !important;
                            li{
                                padding-left:16px !important;
                                position:relative !important;
                                line-height:normal !important;
                                &:before{
                                    content:"";
                                    position:absolute;
                                    background-image:url(${process.env.REACT_APP_IMAGE_URL + TickIcon});
                                    background-repeat:no-repeat;
                                    background-size:contain;
                                    background-position:center;
                                    left:0 !important;
                                    top:0px !important;
                                    height:12px !important;
                                    width:12px !important;
                                    display:inline-block;
                                }
                            }
                        }
                        .planSelectBottom {
                            width: 100% !important;
                            .selectPlanBtn{
                                border-radius: 4px;
                                box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 138px;
                                height: 33px;
                                background: #FFF;
                                margin: 0 auto  !important;
                                box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
                                .textWithIcon{
                                    align-items: center;
                                    justify-content: center;
                                    gap: 3px !important;
                                }
                            }
                        }
                    }
                }
            }
            .officePhoneSysCard.ant-radio-button-wrapper-checked,
            .officePhoneSysCard.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
                border: 2px solid #E37949 !important;
                box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.12) !important;
            }
            .callCentreSolution.ant-radio-button-wrapper-checked,
            .callCentreSolution.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
                border: 2px solid #0265FF !important;
                box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.12) !important;
            }
            .ant-radio-button-wrapper:not(:first-child)::before{
                content:unset !important;
            }
        }
    }
    .ant-modal-footer{
        padding:4px 10px 20px!important;
        border-top: unset !important;
        width: 100%;
        .addnumpopupFooter{
            text-align: center;
            width:100% !important;
            .start_free_trial,
            .start_free_trial:focus{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 169px;
                font-size:15px;
                font-weight:500;
                letter-spacing:1px;
                height: 42px;
                background-color:#E37949 !important;
                color: #fff !important;
                border-color:#E37949 !important;
                margin:0px auto 10px !important;
                &:hover{
                    background-color:#f0723a !important;
                    border-color:#f0723a !important;
                }
            }
            .setupcallLink{
                text-decoration:underline !important;
            }
            p{                
                color: #7D7D7D;
                text-align: center;
                font-size: 11px;
            }
        }
    }
    //new css for new addno popup 02-11-23 end
`;

export default AddNumPopup;