import React, { useState, useEffect } from 'react';
import { Form, Input, Modal, Select, message, Spin } from 'antd-v5';
import addressState from './addressState.json';
import addressCountry from './addressCountry.json';
import planAction from '../../redux/plan/actions';
import { useDispatch, useSelector } from 'react-redux';
import modalActions from '@iso/redux/modal/actions';

const { closeModal } = modalActions;
const { addUserAddress, getGstDetails } = planAction;

const ChargebeeAddressPopup = () => {
  const [form] = Form.useForm();
  const userData = useSelector(state => state.authentication.userData.data);
  const [selectedCountry, setSelectedCountry] = useState();
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [gstNumber, setGstNumber] = useState(userData?.gstinNumber ? userData?.gstinNumber : '');
  const [gstDetails, setGstDetails] = useState(null);
  const [formFeildsdisable, setFormFeildsdisable] = useState(false);
  const { modalVisibility, modalType, modalProps } = useSelector(state => state.modal);
  const dispatch = useDispatch();

  const onCreate = (values) => {
    if (userData?.selectedCurrency == "INR" && values.country !== "India") {
      message.destroy()
      return message.error("INR payments can't be processed for customers with non-Indian addresses.")
    }/* else if(userData?.selectedCurrency != "INR" && values.country === "India"){
      message.destroy()
      return message.error("Non INR payments can't be processed for customers with Indian addresses.")
    } */
    let stateCode = addressState.find(obj => obj.value === values.state)?.shotCode
    if (stateCode) {
      values.stateCode = stateCode
    }
    values.countryCode = addressCountry.find(obj => obj.value === values.country).shotCode
    if (userData?.selectedCurrency) values.selectedCurrency = userData.selectedCurrency
    setDisable(true)
    dispatch(addUserAddress(values)).then(({ payload }) => {
      setDisable(false)
      if (payload.success) {
        if (modalProps.callBackSuccess) {
          modalProps.callBackSuccess(values)
        }
        handleCancel()
      } else {
        message.destroy()
        message.error(payload.error || "Something went wrong while updating address")
      }
    })
  };

  const handleChange = (value) => {
  };

  const handleGstinBlur = async (e) => {
    setDisable(true)
    const gstNumber = e.target.value;
    setGstNumber(gstNumber);
    if (gstNumber.length === 15) {
      setLoading(true); 
      setDisable(true)
      try {
        const { payload } = await dispatch(getGstDetails({ gstNumber }));
        if (payload.success) {
          setGstDetails(payload.data?.data?.data);
          setFormFeildsdisable(true);
          message.destroy();
          setDisable(false)
        } else {
          message.destroy();
          message.error(payload.error.message || "Failed to fetch GST details");
          setDisable(true)
          setGstDetails(null);
          setFormFeildsdisable(false);
        }
      } catch (err) {
        message.destroy();
        message.error(err.message);
        setGstDetails(null);
        setFormFeildsdisable(false);
      }
      finally {
        setLoading(false);
      }
    } else if ((gstNumber.length > 0 && gstNumber.length < 15) || gstNumber.length > 15) {
      message.destroy();
      message.error("Invalid GSTIN number");
      e.target.focus();
    }
    else{
      setFormFeildsdisable(false);
      setDisable(false)
    }
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    form.setFieldValue("state", "")
    console.log(`selected ${value}`);
  };

  const handleCancel = (value) => {
    form.resetFields();
    dispatch(closeModal());
    setFormFeildsdisable(false);
    setSelectedCountry();
    Modal.destroyAll();
  };


  useEffect(() => {
    if (modalVisibility) {
      if(userData?.selectedCurrency === "INR" ){
        setSelectedCountry("India");
      } else {
        if(userData?.userAddress?.country){
          const countryData = addressCountry.find(country => country.shotCode === userData.userAddress.country);
          if (countryData) {
            setSelectedCountry(countryData.value);
          } else {
            setSelectedCountry(userData?.userAddress?.country);
          }
        }else{
          setSelectedCountry();
        }
      }
    }
  }, [modalVisibility])

  useEffect(() => {
    form.setFieldsValue({
      country: selectedCountry
    });
  }, [selectedCountry]);

  useEffect(() => {
    if (gstDetails) {
      const { tradeNam, pradr: { addr } } = gstDetails;
      form.setFieldsValue({
        companyName: tradeNam,
        address1: `${addr.bno}, ${addr.bnm}`,
        address2: `${addr.st}, ${addr.loc}`,
        city: addr.dst,
        zip: addr.pncd,
        state: addr.stcd
      });
    }
  }, [gstDetails]);

  return (
    <>  
      <Modal
        open={modalVisibility && modalType == "chargebeeAddressPopup"}
        title="Add your address"
        className="customAddressPopup"
        width={400}
        okText="Next"
        cancelText=""
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ disabled: disable }}
        onOk={() => form.submit()}
        onCancel={() => { handleCancel(); if (modalProps.callBackFail) modalProps.callBackFail() }}
        destroyOnClose
      >
        {loading ? (
          <div className="form-loading">
            <Spin tip="Loading..." />
          </div>
        ) : (
        <Form
          layout="vertical"
          form={form}
          name="form_in_modal"
          onFinish={onCreate}
          initialValues={{ 
            firstName: userData?.userAddress?.first_name,
            lastName: userData?.userAddress?.last_name,
            companyName: userData?.userAddress?.company,
            address1: userData?.userAddress?.line1,
            address2: userData?.userAddress?.line2,
            city: userData?.userAddress?.city,
            state: userData?.userAddress?.state,
            zip: userData?.userAddress?.zip
           }}
           className={loading ? 'loading-form' : ''}
        >
          <Form.Item
            name="firstName"
            rules={[
              { required: true, message: 'Please input your First Name!' },
              { pattern: /^[A-Za-z\s]+$/, message: 'First Name must contain only letters and spaces!' }
            ]}
          >
            <Input placeholder="First Name" maxLength={50} />
          </Form.Item>

          <Form.Item
            name="lastName"
            rules={[
              { required: true, message: 'Please input your Last Name!' },
              { pattern: /^[A-Za-z\s]+$/, message: 'Last Name must contain only letters and spaces!' }
            ]}
          >
            <Input placeholder="Last Name" maxLength={50} />
          </Form.Item>
          {userData?.selectedCurrency === "INR" && selectedCountry === 'India' && (
              <Form.Item
                  name = "gstinNumber"
                  onBlur={handleGstinBlur}
                  rules={[
                    { pattern: /^[a-zA-Z0-9]+$/, message: 'GSTIN must contain only alphanumeric characters!' },
                    { max: 15, message: 'GSTIN cannot exceed 15 characters!' }
                  ]}
                >
                  <Input placeholder="GSTIN (Optional)" maxLength={15}/>
                </Form.Item>
            )}

            
          <Form.Item
            name="companyName"
          >
            <Input placeholder="Company Name (Optional)" disabled={formFeildsdisable} />
          </Form.Item>
          <Form.Item
            name="address1"
            rules={[
              { required: true, message: 'Please input your address!' },
              { max: 250, message: 'Address cannot exceed 250 characters!' }
            ]}
          >
            <Input placeholder="Address Line 1" maxLength={250} />
          </Form.Item>

          <Form.Item
            name="address2"
            rules={[
              { max: 250, message: 'Address Line 2 cannot exceed 250 characters!' }
            ]}
          >
            <Input placeholder="Address Line 2 (Optional)" maxLength={250} />
          </Form.Item>

          <div className="address-wrap-wr cnflex">
            <Form.Item
              name="city"
              rules={[
                { required: true, message: 'Please input your city!' },
                { pattern: /^[A-Za-z\s]+$/, message: 'City must contain only letters and spaces!' },
                { max: 50, message: 'City name cannot exceed 50 characters!' }
              ]}
            >
              <Input placeholder="City" disabled={formFeildsdisable} maxLength={50} />
            </Form.Item>

            <Form.Item
              name="zip"
              rules={[
                { required: true, message: 'Please input your zip Code!' },
                { pattern: /^[a-zA-Z0-9-]+$/, message: 'Zip code must contain only alphanumeric characters and hyphens!' },
                { max: 8, message: 'Zip Code cannot exceed 8 digits!' }
              ]}
            >
              <Input placeholder="Zip" maxLength={8} disabled={formFeildsdisable} />
            </Form.Item>

            <Form.Item
              name="country"
              rules={[{ required: true, message: 'Please select your country!' }]}
            >
              <Select
                placeholder="Country"
                onChange={handleCountryChange}
                options={addressCountry}
                showSearch
                disabled={formFeildsdisable}
              />
            </Form.Item>

            {selectedCountry === 'India' ? (
                <Form.Item
                  name="state"
                  rules={[{ required: true, message: 'Please select your state!' }]}
                >
                  <Select
                    placeholder="State"
                    disabled={formFeildsdisable}
                    onChange={handleChange}
                    options={addressState}
                    showSearch
                  />
                </Form.Item>
            ) : (
              <Form.Item
                name="state"
                rules={[
                  { required: true, message: 'Please input your state!' },
                  { pattern: /^[A-Za-z\s]+$/, message: 'State must contain only letters!' },
                  { max: 20, message: 'State name cannot exceed 20 characters!' }
                ]}
              >
                <Input placeholder="State" maxLength={20} />
              </Form.Item>
            )}
          </div>
        </Form>
      )}
      </Modal>
    </>
  );
};

export default ChargebeeAddressPopup;
