import numberAction from './actions';
import users from "../users/actions";
const initState = {
    get_number: [],
    get_countries: [],
    get_avail_number: [],
    user_billing: [],
    get_location: [],
    user_data: [],
    cart_up_create: [],
    chargebee_new_user: [],
    number_setting: [],
    number_delete: [],
    number_setting_update: [],
    allocation_type: [],
    assign_user: [],
    ivr_data: [],
    answer_data : [],
    delete_ivr: [],
    delete_answer : [],
    team_list: [],
    team_assign: [],
    get_users: [],
    get_users_list:[],
    get_user_profile: [],
    get_system_verified_docs:null,
    system_verified_document:{},
    port_number:{},
    get_port_number:{},
    delete_port_number:{},
    save_number:{},
    buy_number:{},
    feature_upgrade:{},
    send_voxbone_details:{},
    chargbee_data:{},
    availability_time:[],
    time_operation:[],
    send_otp_on_call : [],
    verify_otp_on_call : [],
    remove_custom_caller_id : [],
    unverifiedData : [],
    cancel_callerid_popup : [],
    whatsapp_data:[],
    integrate_whatsapp_data:[],
    smsVerification:[],
    smsEditVerification:[],
    use_case_profile:[],
    get_use_case_profile:[],
    get_use_case_review:[],
    sms_use_case: [],
    assign_all: [],
    restore_number :[],
    system_default_number_assign :[]
}

export default function numberReducer(state = initState, action) {
    switch (action.type) {
        case numberAction.GET_NUMBER:
      return {
        ...state,
        get_number: action.payload,
      };
      case numberAction.SAVE_NUMBER:
      return {
        ...state,
        save_number: action.payload,
      };
      case numberAction.BUY_NUMBER:
      return {
        ...state,
        buy_number: action.payload,
      };
      case numberAction.GET_UNVERIFIED_DOCS:
      return {
        ...state,
        get_unverified_docs: action.postdata,
      };
      case numberAction.GET_COUNTRIES:
        return {
          ...state,
          get_countries: action.postdata,
        };
        case numberAction.GET_AVAIL_NUMBER:
            return {
                ...state,
                get_avail_number: action.postdata,
            };
        case numberAction.USER_BILLING:
            return {
                ...state,
                user_billing: action.postdata,
            };
        case numberAction.GET_LOCATION:
            return {
                ...state,
                get_location: action.postdata,
            };
        case numberAction.USER:
            return {
                ...state,
                user_data: action.postdata,
            };
        case numberAction.CART_UP_CREATE:
            return {
                ...state,
                cart_up_create: action.postdata,
            };
        case numberAction.RESTORE_NUMBER:
            return {
                ...state,
                restore_number: action.postdata,
            };
        case numberAction.SYSTEM_DEFAULT_NUMBER_ASSIGN:
            return {
                ...state,
                system_default_number_assign: action.postdata,
            };
        case numberAction.CHARGEBEE_NEW_USER:
            return {
                ...state,
                chargebee_new_user: action.postdata,
            };
        case numberAction.NUMBER_SETTING_UPDATE:
            return {
                ...state,
                number_setting_update: action.postdata,
            };
        case numberAction.NUMBER_SETTING:
            return {
                ...state,
                number_setting: action.postdata,
            };
        case numberAction.NUMBER_DELETE:
            let data = {
                "data":state.get_number.data.filter((item, index) => index !== action.payload)
            }
            return {
                ...state,
                get_number: data,
            };
        case numberAction.ALLOCATION_TYPE:
            return {
                ...state,
                allocation_type: action.postdata,
            };
        case numberAction.ASSIGN_USER:
            return {
                ...state,
                assign_user: action.postdata,
            };
        case numberAction.IVR:
            return {
                ...state,
                ivr_data: action.postdata,
            };
        case numberAction.SURVEY_ANSWER:
            return {
                ...state,
                answer_data: action.postdata,
            };
        case numberAction.DELETE_IVR:
            return {
                ...state,
                delete_ivr: action.postdata,
            };
        case numberAction.DELETE_ANSWER:
        return {
            ...state,
            delete_answer: action.postdata,
        };
        case numberAction.TEAM_LIST:
            return {
                ...state,
                team_list: action.postdata,
            };
        case numberAction.TEAM_ASSIGN:
            return {
                ...state,
                team_assign: action.postdata,
            };
        case numberAction.GET_USERS:
            return {
                ...state,
                get_users_list: action.payload,
            };
        case numberAction.SYSTEM_VERIFIED_DOCS:
            return{
                ...state,
                get_system_verified_docs: action.postdata,
            }
        case numberAction.SYSTEM_VERIFIED_DOCS_CLEAR:
            return{
                ...state,
                get_system_verified_docs: null,
            }
        case numberAction.UPLOAD_MUSIC:
            return {
                ...state,
                upload_music: action.payload,
            };
        case numberAction.GET_USER_PROFILE:
            return {
                ...state,
                get_users: action.postdata,
              };
            case numberAction.SEND_DOCUMENT:
              return{
                ...state,
                system_verified_document: action.postdata,
              }
            case numberAction.PORT_NUMBER:
              return{
                ...state,
                port_number: action.postdata,
              }
            case numberAction.GET_PORT_NUMBER:
              return{
                ...state,
                get_port_number: action.postdata,
              }
            case numberAction.DELETE_PORT_NUMBER:
              return{
                ...state,
                delete_port_number: action.postdata,
              }
            case numberAction.USERP:
              return{
                ...state,
                system_verified_document: action.postdata,
              }
              case numberAction.FEATURE_UPGRADE:
              return{
                ...state,
                feature_upgrade: action.postdata,
              }
            case numberAction.SEND_VOXBONE_DETAILS:
                return{
                    ...state,
                    send_voxbone_details: action.payload
                }
            case numberAction.CHECK_ACCOUNT_IN_CHARGEBEE:
                return {
                    ...state,
                    chargbee_data: action.postdata,
            };
        case numberAction.AVAILABILITY_TIME:
            return {
                ...state,
                availability_time: action.payload
        };
        case numberAction.TIME_OPERATION:
        return {
            ...state,
            time_operation: action.payload
        };
        case numberAction.SEND_OTP_ON_CALL:
            return{
                ...state,
                send_otp_on_call: action.payload
            }
        case numberAction.VERIFY_OTP_ON_CALL:
            return{
                ...state,
                verify_otp_on_call: action.payload
            }
        case numberAction.REMOVE_CUSTOM_CALLER_ID:
            return{
                ...state,
                remove_custom_caller_id: action.payload
            }
        case numberAction.CANCEL_CALLERID_POPUP:
            return{
                ...state,
                cancel_callerid_popup: action.payload
            }
        case numberAction.WHATSAPP_DATA:
            return{
                ...state,
                whatsapp_data: action.payload
            }
        case numberAction.INTEGRATE_WHATSAPP_DATA:
            return{
                ...state,
                integrate_whatsapp_data: action.payload
            }
        case numberAction.REMOVE_INTEGRATE_WHATSAPP_DATA:
            return {
                ...state,
                remove_integrate_whatsapp: action.payload
            }
        case numberAction.SMS_USECASE:
            return {
                ...state,
                smsVerification: action.payload
            }
        case numberAction.SMS_VERIFICATION:
            return{
                ...state,
                getSmsUseCase: action.payload
            }
        case numberAction.EDIT_SMS_VERIFICATION:
            return{
                ...state,
                smsEditVerification: action.payload
            }
        case numberAction.USE_CASE_PROFILE:
            return{
                ...state,
                use_case_profile: action.payload
            }
        case numberAction.GET_USE_CASE_PROFILE:
            return{
                ...state,
                get_use_case_profile: action.payload
            }
        case numberAction.GET_USE_CASE_REVIEW:
            return{
                ...state,
                get_use_case_review: action.payload
            }
        case numberAction.SMS_USE_CASE:
            return{
                ...state,
                sms_use_case: action.payload
            }
        case numberAction.ASSIGN_ALL:
            return{
                ...state,
                assign_all: action.payload
            }
        case numberAction.REMOVE_ADDRESS_DOCUMENT_ID:
            return{
                ...state,
                unverifiedData: action.payload
            }
      default:
        return state;
    }
}

