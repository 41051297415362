import axiosApi from "../api";
import Axios from 'axios';
const base_url = process.env.REACT_APP_BASE_URL;

const teamsActions = {

  TEAM_LIST: 'TEAM_LIST',
  TEAM_PRIORITY: 'TEAM_PRIORITY',
  ADD_TEAM: 'ADD_TEAM',
  EDIT_TEAM: 'EDIT_TEAM',
  UPDATE_TEAM: 'UPDATE_TEAM',
  DELETE_TEAM: 'DELETE_TEAM',
  TEAM_NAME_LIST : 'TEAM_NAME_LIST',

  teamList: (postData,query={}) => dispatch => {
    let pagination = query.pagination?`&pagination=${query.pagination}`:''
    let search = query.search?`&search=${query.search}`:''

   return axiosApi(`${base_url}team/list/${postData}?current=${query.current?query.current:1}${pagination}${search}`, "get", {}, dispatch, `${teamsActions.TEAM_LIST}`).then((result) => {
    return result;
  })
  },

  teamNameList: postData => dispatch => axiosApi(`${base_url}team/list/${postData}?nameFlag=true`, "get", {}, dispatch, `${teamsActions.TEAM_NAME_LIST}`),

  teamPriority: (postData, userId) => dispatch => axiosApi(`${base_url}team/teamPriority/list/${postData}/${userId}`, "get", {}, dispatch, `${teamsActions.TEAM_PRIORITY}`),

  addTeam: postData => dispatch => axiosApi(`${base_url}team/create`, "post", postData, dispatch, `${teamsActions.ADD_TEAM}`),

  editTeam: postData => dispatch => axiosApi(`${base_url}team/${postData}/details`, "get", {}, dispatch, `${teamsActions.EDIT_TEAM}`),

  updateTeam: (postData, updateIndex) => dispatch => axiosApi(`${base_url}team/${postData._id}`, "put", postData, dispatch, `${teamsActions.UPDATE_TEAM}`, updateIndex),

  deleteTeam: (postData, deleteId) => dispatch => axiosApi(`${base_url}team/${postData}`, "delete", {}, dispatch, `${teamsActions.DELETE_TEAM}`, deleteId),

}
export default teamsActions;