import React, { useEffect,useState,useRef } from 'react';
import cookie from 'react-cookies';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Menu, Button, Dropdown, Icon, Input,AutoComplete} from 'antd';
import appActions from '@iso/redux/app/actions';
import numberAction from "@iso/redux/number/actions";
import TopbarUser from './TopbarUser';
import TopbarWrapper from './Topbar.styles';
import message from '@iso/components/Feedback/Message';
import queryString from "query-string"
import { useHistory } from "react-router-dom";
import { Link, withRouter } from 'react-router-dom';
import BroadcastPopup from './BrodcastPopup';
import modalActions from '@iso/redux/modal/actions';
import styled from 'styled-components';
import './TopbarUser.css'
import { APP_PUBLIC_ROUTE } from '@iso/components/root/routeConstants';
import globalSearchList from "../../components/globalSearch.json"
import { HashLink } from 'react-router-hash-link';
import CustomToast from '../../components/toastmessage';
import planSetting from '@iso/redux/plan/actions';
import moment from 'moment';
import CallHippoAIIcon from '../../assets/images/chcollapselogo.svg'
const { Header } = Layout;
const { toggleCollapsed, closeSubmenus } = appActions;
const dialerUrl = process.env.REACT_APP_DIALER_URL;
const phoneUrl = process.env.REACT_APP_PHONE_URL;
const { SubMenu } = Menu;
const {sendDetailsForVoxbone} = numberAction;
const { openModal } = modalActions;
const imageUrl = process.env.REACT_APP_IMAGE_URL;
const bpsIcon = imageUrl + 'BusinessPhoneSystem_icon.svg';
const wsiIcon = 'https://d1x9dsge91xf6g.cloudfront.net/callhippo/images/ch-svg/controllhippo_white_inner_icon.svg';
const callTrackIcon = imageUrl + 'call_tracking_picon.svg';
const speechAnalyticsIcon = imageUrl + 'speechai_picon.svg';
const broadcastIcon = imageUrl + 'voicepr.svg';
const whatsappBusinessIcon = imageUrl + 'perm_phone_msg_black.svg';
const { AUTH_PROFILE, DESKTOP_ONBOARDING_APP } = APP_PUBLIC_ROUTE;
const { Option, OptGroup } = AutoComplete;
const { getControlHippoToken, getApiToken } = planSetting;

const AppsMenuStyles = styled.div`
.bpsIcon_main,
.callTrack_main,
.speechAnalytics_main,
.broadcast_main,
.whatsapp_main,
.callhippo_ai_main,
.wsiIcon_main {
  color: #fff;
  border-radius: 20px;
  margin-right: 10px;
  height: 35px;
  width: 35px;
  min-width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bpsIcon_main{
  background-color: #1890ff;
}
.wsiIcon_main{
  background-color: #0029C7;
}
.bpsIcon,
.callTrackIcon,
.speechAnalyticsIcon,
.broadcastIcon,
.whatsappIcon,
.wsiIcon{
  height: 15px;
  width: 15px;
  display: inline-block;
  background-repeat: no-repeat !important;
}
.bpsIcon {
  background-image: url(${bpsIcon});
}
.wsiIcon{
  background-image: url(${wsiIcon});
}
.callTrack_main {
  background-color: #ffa800;
}
.callTrackIcon {
  background-image: url(${callTrackIcon});
}
.speechAnalytics_main {
  background-color: #ff5969;
}
.speechAnalyticsIcon {
  background-image: url(${speechAnalyticsIcon});
}
.broadcast_main {
  background-color: #25c16f;
}
.broadcastIcon {
  background-image: url(${broadcastIcon});
}
.whatsapp_main {
  background-color: #9b3a99;
}
.whatsappIcon {
  background-image: url(${whatsappBusinessIcon});
}
.callhippo_ai_main {
   background-image: url(${CallHippoAIIcon});
}
.border_bottom_apps {
  border-bottom: 1px solid rgb(0 0 0 / 13%)
}
.app_list_text {
  color: #000;
  font-weight: 500;
}
.lineheightnormal{
  line-height: normal !important;
}
.ant-menu-vertical > .ant-menu-item {
  height: auto !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.ant-menu-item:active,
.ant-menu-item:hover,
.ant-menu-item:focus,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgb(250 173 20 / 20%);
}
.app_menu_list {
  .ant-menu-item{
    padding: 0px !important;
  }
  .mg_li_list {
    margin-top: 6px;
    margin-bottom: 6px;
    padding:0px 15px!important;
  }
}
.callhippoai_text{
  background: #e27646;
  padding: 2px 4px;
  color: #fff;
  border-radius: 4px;
}
`;

export default function Topbar(props) {
  let history = useHistory();
  const [selectedItem, setSelectedItem] = React.useState('');
  const [firstTimeLogin, setFirstTimeLogin] = React.useState(true);
  const [scheduleDemoBtn, setScheduleDemoBtn] = React.useState(true);
  const [showbroadcastpopup,setShowbroadcastPopup] = React.useState(false);
  const customizedTheme = useSelector(state => state.ThemeSwitcher.topbarTheme);
  const { collapsed, openDrawer } = useSelector(state => state.App);
  const headernav = useRef(null);
  const [slideLeft, setSlideLeft] = React.useState(0);
  const [hideButtonLeft, setHideButtonLeft] = React.useState(true);
  const [hideButtonRight, setHideButtonRight] = React.useState(false);
  const [sliderWidth, setSliderWidth] = React.useState(0);
  const numberData = useSelector(state => state.number.get_number.data);
  const [inputValue, setInputValue] = React.useState();
  const userData = useSelector(state => state.authentication.userData.data);
  const isDummyUser = userData.stripeId ? false :true;
  const userReport = userData.plan && userData.plan.report ? true : false;
  const isLeaderBoard = userData.plan && userData.plan.isLeaderboard ? true : false;
  const isUserSupervisor = userData.roleData.name === "Supervisor" ? true : false;
  const isSsoEnabled = userData && userData.isSsoEnabled ? true : false;
  const [showToast, setShowToast] = React.useState(false);
  const [errorMessage ,setErrorMessage] = React.useState()
  const voiceBroadcast = userData.plan && userData.plan.voiceBroadcast ? true : false;
  const callPlanner = userData.plan && userData.plan.callReminder ? true : false;
  const [whatsappRedirectEnable,setWhatsappRedirectEnable] = React.useState(false);
  const [showDemoButton, setShowDemoButtom] = React.useState(false);
  const [releaseCount, setReleaseCount] = React.useState(0);

  const dispatch = useDispatch();
  const handleToggle = React.useCallback(() => {
    dispatch(toggleCollapsed());
    if (!collapsed) {
      dispatch(closeSubmenus());
    }
  }, [dispatch, collapsed]);
  const [currentkey, setCurrentKey] = useState(history.location.pathname !=="calltracking" && history.location.pathname !=="coach/callSummary" ? 'dashboard' : history.location.pathname.replace('/', ''));
  const handleMakeCallsSms = () => {
    let exp = new Date();
    exp.setMonth(exp.getMonth()+12);
    cookie.save('firstTimeLogin', 0, { path: '/', exp });
    setFirstTimeLogin(false);
  }
  
  // const onHScroll = () => {
  //   const el = document.getElementById(`hscroll`).scrollLeft;
  //   if (el > 0) {
  //     setHideButtonLeft(false);
  //   } else {
  //     setHideButtonLeft(true);
  //   }
  //   if (el < sliderWidth) {
  //     setHideButtonRight(false);
  //   } else {
  //     setHideButtonRight(true);
  //   }
  // };
  // const moveRight = () => {    
  //   const el = document.getElementById(`hscroll`);
  //   setSlideLeft((el.scrollLeft += 200));
  // };
  // const moveLeft = () => {
  //   const el = document.getElementById(`hscroll`);
  //   setSlideLeft((el.scrollLeft -= 200));
  // };
  // useEffect(() => {
  //   setSliderWidth(
  //     process.env.REACT_APP_REPORTS_APP ? 100 : 
  //     document.getElementById("hscroll").scrollWidth -
  //       document.getElementById("hscroll").offsetWidth
  //       );
  // },[]);

  
  // useEffect(() => {          
  //   var config = {
  //     selector: ".rmtte",
  //     account: "ypAgDx"
  //   };
  //   window.Headway && window.Headway.init(config);
  // }, [window.Headway] );

  useEffect(() => {
    cookie.load('firstTimeLogin') === '0' && setFirstTimeLogin(false);
  }, [firstTimeLogin] );
  useEffect(() => {
    let userCreatedDate=userData?.parentCreatedDate?userData.parentCreatedDate:userData.createdDate;
    let day=moment().diff(moment(userCreatedDate), 'days')
    if(userData?.teamsize!="Just me" && day<=21){
        setShowDemoButtom(true)
    }
  }, []);
  
  
  useEffect(() => {
    let createdDate = userData.createdDate;
    var currentDate = new Date().getTime(),
      lastDate = new Date(createdDate);
          
    lastDate.setDate(lastDate.getDate() + 30);
    lastDate = new Date(lastDate).getTime();

    if(currentDate > lastDate){
      setScheduleDemoBtn(false);
    }else{
      setScheduleDemoBtn(true);
    }      
    }, [scheduleDemoBtn] );
    
  const isCollapsed = collapsed && !openDrawer;
  const styling = {
    background: customizedTheme.backgroundColor,
    position: 'fixed',
    width: '100%',
    height: 70,
  };

  useEffect(() => {
    if(history.location.pathname.includes("broadcast")){
      setCurrentKey("broadcast")
    }else if( history.location.pathname !=="/calltracking" && history.location.pathname !=="/coach/callSummary"){
      setCurrentKey("dashboard");
    }else{
      setCurrentKey(history.location.pathname.replace('/', ''));
    }
    // console.log('history.location====', history.location)
    if(history.location.pathname.includes("/calltracking") || history.location.pathname.indexOf("/calldetail") > -1){
      setCurrentKey("calltracking");
    }
    if(history.location.pathname.includes("/coach/callAnalysis") || history.location.pathname.includes("/coach/evaluationCoaching") || history.location.pathname.includes("/coach/manage/themes")) {
      setCurrentKey("coach/callSummary");
    }
    // test.passUrl(history.location.pathname)
  }, [history.location.pathname] );
  const handleClick = e => {
    if(userData.isProfileRemaining){
      return history.push(AUTH_PROFILE);
    } 
    if((e.key == "/broadcast" || e.key == "broadcast") && userData.plan.voiceBroadcast){
      setCurrentKey(e.key)
    }else if(e.key != "/broadcast" || e.key != "broadcast"){
      setCurrentKey(e.key)
    }
    // setCurrentKey(e.key)
    // history.push('/'+e.key)
    let user=JSON.parse(localStorage.getItem('userData'));
    if(e.key == 'dashboard'){
      window.mixpanel.track("Clicked on Call Center Solution button",{distinct_id : user.email,parent_id: user.parentEmail? user.parentEmail: user.email});
    }else if(e.key == 'calltracking'){
      window.mixpanel.track("Clicked on Call Tracking button",{distinct_id : user.email,parent_id: user.parentEmail? user.parentEmail: user.email});
    }else if(e.key == 'coach/callSummary'){
      window.mixpanel.track("Clicked on Coach button",{distinct_id : user.email,parent_id: user.parentEmail? user.parentEmail: user.email});
    }
  }
  const voicebroadcastpopup = () => {
    setShowbroadcastPopup(true);
  }

  const clickOnWhatsappShared = () => {
    let user=JSON.parse(localStorage.getItem('userData'));
    window.mixpanel.track("Clicking on the whatsapp shared inbox.",{distinct_id : user.email,parent_id: user.parentEmail? user.parentEmail: user.email});
    // generate token and redirect to link
    dispatch(getControlHippoToken({})).then(({ payload }) => {
      if (payload.success) {
        let payloadObj = {
          token: payload?.data?.apiToken
        }
        window.open(`${process.env.REACT_APP_WHATSAPP_SHARED_INBOX_URL}?${queryString.stringify(payloadObj)}`);
      } else message.error(payload?.error?.error || payload?.error || "Something went wrong" )
    });
  }

  const handleRequestSend = e => {
    dispatch(sendDetailsForVoxbone({type : 'addcampaign',featureName:"Voice Broadcast."}));
      setShowbroadcastPopup(false);
      message.destroy(); 
      message.success("Your request has been submitted successfully.")
  }
  const handleCancelvoicebroadcastPopup = () => {
    setShowbroadcastPopup(false);
  }
  const warningMsg = msg => { message.destroy(); message.warning(msg) };
  const userIsBlocked = () => {
    userData.isBlocked && !userData.amountDue && warningMsg(userData.blockReason);
    //userData.isBlocked && userData.amountDue && warningMsg(userData.blockReason + " Please update your payment method.");
  };
  const voiceBroadCastCheck = () => {
    if(userData.isProfileRemaining){
      return;
    }
    //if (this.props.userInfo.isDisableSms) {
      var isDummyUser = userData.numbers || userData.deletedNumbers ? false : true
    if (userData.plan.voiceBroadcast || isDummyUser) {
      history.push('/broadcast');
    } else {
      window.mixpanel.track("Clicked on BroadCast button",{distinct_id : JSON.parse(localStorage.getItem('userData')).email,parent_id:  JSON.parse(localStorage.getItem('userData')).parentEmail?  JSON.parse(localStorage.getItem('userData')).parentEmail:  JSON.parse(localStorage.getItem('userData')).email});
      window.mixpanel.track("Clicked on the BroadCast.",{distinct_id : JSON.parse(localStorage.getItem('userData')).email,parent_id:  JSON.parse(localStorage.getItem('userData')).parentEmail?  JSON.parse(localStorage.getItem('userData')).parentEmail:  JSON.parse(localStorage.getItem('userData')).email});
      if(userData.plan.planDisplayName =="platinum" || userData.plan.planDisplayName =="enterprise" ){
        dispatch(openModal({modalType: 'onRequest', modalProps: { feature:"Voice Broadcast"}}));
      }else{
       setTimeout(function(){

         dispatch(openModal({modalType: 'planUpgrade', modalProps: { planFeatureFlag: 'voiceBroadcast', planFeatureName: 'Voice Broadcast' }}));
        },1000)
        }
    }
    userIsBlocked()
      //  dispatch(openModal({modalType: 'enterprise', modalProps: { feature:"voiceBroadcast"}}));
     // return this.props.openModal({modalType: 'enterprise', modalProps: { feature:"voiceBroadcast"}});

//      setShowbroadcastPopup(true);
  }

  function renderTitle(title) {
    return (
      <span>
        {title}        
      </span>
    );
  }
  let globalSearch  = isDummyUser ? globalSearchList[0].children:globalSearchList[1].children;
  globalSearch.forEach(function (value) {
    if (isUserSupervisor && value.title === "Numbers") {
      value.children.forEach(function (childValue) {
        if (childValue.title === "Port Number") {
          childValue.redirectPath = "/number";
        }
      });
    }
  });
  globalSearch = globalSearch.filter(function (value) {
    if (!(!userReport && ("Reports" == value.title))) {
      return value;
    }
  });
  globalSearch = globalSearch.filter(function (value) {
    if (!(!isLeaderBoard && ("Leader Board" == value.title))) {
      return value;
    }
  });
  globalSearch = globalSearch.filter(function (value) {
    if (!(isSsoEnabled && ("Change Password" == value.title))) {
      return value;
    }
  });
  globalSearch = globalSearch.filter(function (value) {
    if (!(!callPlanner && ("Call Planner" == value.title))) {
      return value;
    }
  });
  globalSearch = globalSearch.filter(function (value) {
    if (!(!voiceBroadcast && ("Add Broadcast" == value.title))) {
      return value;
    }
  });
  globalSearch = globalSearch.filter(function (value) {
    if (!(!voiceBroadcast && ("Broadcast" == value.title))) {
      return value;
    }
  });
  globalSearch = globalSearch.filter(function (value) {
    if (!(!voiceBroadcast && ("Broadcast Campaigns" == value.title))) {
      return value;
    }
  });
  globalSearch = globalSearch.filter(function (value) {
    if (!(!voiceBroadcast && ("Broadcast Activity Feed" == value.title))) {
      return value;
    }
  });
  const options = globalSearch
    .map(group => (
      <OptGroup key={group.title} label={renderTitle(group.title)}>
        {group.children.map((opt, index) => (
          userData.isBlocked ?
            <Option key={opt.title} dataAliasSearch={opt.aliasSearch} value={opt.title}>
              <span>{opt.title}</span>
            </Option>
            :
            <Option key={opt.title} dataAliasSearch={opt.aliasSearch} value={opt.title}>
              <HashLink to={opt.redirectPath}><span>{opt.title}</span></HashLink>
            </Option>
        ))}
      </OptGroup>
    ));
  
    
  const onSelect = (data) => {
    if (userData && userData.isBlocked) {
      setShowToast(true)
      var errorMessage1 = userData && userData.isBlocked && userData.blockReason ? userData.blockReason : "Account Blocked";
      setErrorMessage(errorMessage1)
    }
    if (!voiceBroadcast && (data == "Broadcast" || data == "Broadcast Campaigns" || data == "Broadcast Activity Feed" || data == "Add Broadcast Campaigns")) {
      voiceBroadCastCheck();
    }
    if(userData && userData.roleData && userData.roleData.name &&  (userData.roleData.name == "Manager" || userData.roleData.name == "Supervisor" || userData.roleData.name == "Agent")){
      if(data == "Add Broadcast Campaigns"){
        return history.push("/broadcast/voiceBroadcast");
      }
      else if(data == "Add Power Dialer Campaign"){
        return history.push("/powerDialer");
      }
      else if(data == "Invite User"){
        return history.push("/users");
      }
      else if(data == "Add Campaign Management"){
        return history.push("/campaignManagement")
      }
    }
  };
  useEffect(() => setShowToast(false))
  useEffect(() => {
    if(!userData.isBlocked && userData.roleData && (userData.roleData.priority == 1 || ((userData.whatsAppServiceRights && userData.whatsAppServiceRights.length && userData.whatsAppServiceRights.includes(userData.role)))) && userData.numbersList && userData.numbersList.find((n)=>n.isWhatsappApplied)){
      setWhatsappRedirectEnable(true) 
    }
  }, [userData] );
  // const whatsappRedirectEnable = (!userData.isBlocked && userData.roleData && (userData.roleData.priority == 1 || userData.roleData.priority == 1.5) && userData.numbersList && userData.numbersList.find((n)=>n.isWhatsappApplied))
  const app_menu = (
    <AppsMenuStyles>
    <Menu className='app_menu_list' defaultSelectedKeys={'menu1'}>
      <Menu.Item key="menu1">
        <Link  to={"/dashboard"}>
          <div className='chflex chaligncenter border_bottom_apps'>
            <div className='mg_li_list chflex chaligncenter'>
              <div className='bpsIcon_main'>
                <span className="bpsIcon"></span> 
              </div>
              <span className='app_list_text'>Call Center Solution</span>
            </div>
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item key="menu2">
        <Link onClick={clickOnWhatsappShared}>
          <div className='chflex chaligncenter border_bottom_apps'>
            <div className='mg_li_list chflex chaligncenter'>
              <div className='wsiIcon_main'>
                <span className="wsiIcon"></span> 
              </div>
              <span className='app_list_text'>Whatsapp Shared Inbox</span>
            </div>
          </div>
        </Link>
      </Menu.Item>
      {/* <Menu.Item>
        <Link  to={!userData.isBlocked? "/calltracking": "#"} onClick={userIsBlocked}>
        <div className='chflex chaligncenter border_bottom_apps'>
        <div className='mg_li_list chflex chaligncenter'>
        <div className='callTrack_main'>
        <span className="callTrackIcon"></span> 
        </div>
        <span className='app_list_text'>Call Tracking</span>
        </div>
        </div>
        </Link>
      </Menu.Item> */}
      {/* <Menu.Item>
      <Link  to={!userData.isBlocked && "/coach/callSummary"} onClick={userIsBlocked}>
        <div className='chflex chaligncenter border_bottom_apps'>
        <div className='mg_li_list chflex chaligncenter'>
        <div className='speechAnalytics_main'>
        <span className="speechAnalyticsIcon"></span> 
        </div>
        <span className='app_list_text'>Speech Analytics</span>
        </div>
        </div>
        </Link>
      </Menu.Item> */}
      <Menu.Item key="menu3">
      <Link to={!userData.isBlocked && "/broadcast"} onClick={() => {userData.isBlocked ? userIsBlocked() : voiceBroadCastCheck()}}>
        <div className='chflex chaligncenter border_bottom_apps'>
        <div className='mg_li_list chflex chaligncenter'>
        <div className='broadcast_main'>
        <span className="broadcastIcon"></span> 
        </div>
        <span className='app_list_text'>Voice Broadcast</span>
        </div>
        </div>
        </Link>
      </Menu.Item>
      <Menu.Item key="menu4">
      <Link target={whatsappRedirectEnable && "_blank"} to={{ pathname: whatsappRedirectEnable ? process.env.REACT_APP_WHATSAPP_URL : "/number", search: "s=department"}} onClick={userIsBlocked}>
        <div className='chflex chaligncenter'>
        <div className='mg_li_list chflex chaligncenter'>
        <div className='whatsapp_main'>
        <span className="whatsappIcon"></span> 
        </div>
        <span className='app_list_text'>Whatsapp Business</span>
        </div>
        </div>
        </Link>
      </Menu.Item>
      <Menu.Item key="menu5">
      <a onClick={getApiTokenHandle} className='ctrlh_link' target='_blank'>
        <div className='chflex chaligncenter'>
        <div className='mg_li_list chflex chaligncenter'>
        <div className="callhippo_ai_main">
        </div> 
        <span className='app_list_text'>CallHippo <span className="callhippoai_text">AI</span></span>
        </div>
        </div>
        </a>
      </Menu.Item>
    </Menu>
    </AppsMenuStyles>
  );

  function getApiTokenHandle () {
    // send activity to mixpanel to track
    window.mixpanel.track("Click on the CallHippo AI.",{user_id: userData.email,distinct_id: userData.email,parent_id: userData && userData.parentEmail?userData.parentEmail:userData.email});
    dispatch(getApiToken({generateFromSidebar: true})).then(({ payload }) => {
      if (payload.success) {
        let payloadObj = {
          parentId: userData.parentId || userData._id,
          userId: userData._id,
          fullName: userData.fullName,
          userEmail: userData.email,
          roleName: userData?.roleData?.name || "",
        }
        if(userData.parentEmail) payloadObj.parentEmail = userData.parentEmail
        window.open(`${process.env.REACT_APP_AI_REDIRECTION_URL}?${queryString.stringify(payloadObj)}`);
      } else message.error(payload?.error?.error || payload?.error || "Something went wrong" )
    });
  }

  const handleProductFruite = () => {
    const productFruiteCh = document.getElementById('productFruiteCh');
    window.productFruits.api.announcementsV2.listen('newsfeed-unread-count-changed', (data) => {
      setReleaseCount(data.count);
    });
    window.productFruits.api.announcementsV2.attachNewsWidgetToElement(productFruiteCh);
  }   

  window.addEventListener('productfruits_ready', function() { 
    handleProductFruite()
  });

  return (
    <TopbarWrapper>
      <Header
        style={styling}
        className={`${isCollapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'} chHeaderMain`}>
        <div className="isoLeft headertabbottom">  
        <button
        className={isCollapsed ? 'triggerBtn menuCollapsed closemenu closemenutab' : 'triggerBtn menuOpen collapsibletopbarmenu'}
        style={{ color: customizedTheme.textColor }}
        onClick={() => {
          handleToggle();
          if (props.handleCollapsClick) {
            props.handleCollapsClick();
          }
        }}
      />

          {showDemoButton && userData.roleData && userData.roleData.priority != 5 && <div className='bpsLeft10'>
          <a href="/schedule-demo" className='topBookDemoBtn newTopBookDemoBtn cnflex chflexcenter' target='_blank'>Book A Demo</a>
        </div>}
        {/* <AppsMenuStyles>
        <div className='bpsLeft10'>
        <div className='mgtop5 chflex chaligncenter'>
        {!window.location.pathname.includes('/calltracking') && !window.location.pathname.includes('/coach') && !window.location.pathname.includes('/broadcast') && !window.location.pathname.includes('/whatsappbusiness') &&
        <div className='bpsIcon_main'>
        <span className="bpsIcon"></span> 
        </div>
        }
        {window.location.pathname.includes('/calltracking') &&
        <div className='callTrack_main'>
        <span className="callTrackIcon"></span> 
        </div>
        }
        {window.location.pathname.includes('/coach') &&
        <div className='speechAnalytics_main'>
        <span className="speechAnalyticsIcon"></span> 
        </div>
        }
        {window.location.pathname.includes('/broadcast') &&
        <div className='broadcast_main'>
        <span className="broadcastIcon"></span> 
        </div>
        }
        {window.location.pathname.includes('/whatsappbusiness') &&
        <div className='whatsapp_main'>
        <span className="whatsappIcon"></span> 
        </div>
        }
        <span className='app_list_text lineheightnormal'>
        {!window.location.pathname.includes('/calltracking') && !window.location.pathname.includes('/coach') && !window.location.pathname.includes('/broadcast') && !window.location.pathname.includes('/whatsappbusiness') && 'Call Center Solution'}
        {window.location.pathname.includes('/whatsappbusiness')  && 'Whatsapp Business'}
          {window.location.pathname.includes('/calltracking') && 'Call Tracking'}
          {window.location.pathname.includes('/coach') && 'Speech Analytics'}
          {window.location.pathname.includes('/broadcast') && 'Broadcast'}
        </span>
        </div>
        </div>
        </AppsMenuStyles> */}
        {/* {process.env.REACT_APP_REPORTS_APP && userData.isReportsApp ? '' :   
            <div className={`headernavtabwrapper ${isCollapsed ? '' : 'hidetopmenu'}  `}  id={`hscroll`} onScroll={() => onHScroll()}>    
            <Menu
              onClick={handleClick}                                                        
              selectedKeys={currentkey}
              mode="horizontal"
              className="topbarmenu bpsLeft10"                            
            >
              <Menu.Item key="dashboard" className="headernav" id="bpsLeft10">
                <Link  to={"/dashboard"}>
                    <span className="">                      
                      <span className="nav-text" onClick={() => {window.mixpanel.track("Clicked on Business Phone System button",{distinct_id : JSON.parse(localStorage.getItem('userData')).email});} }>Business Phone System</span>                      
                    </span>
                </Link>
              </Menu.Item> 
              <Menu.Item key="calltracking" className="headernav">
              <Link  to={!userData.isBlocked? "/calltracking": "#"} onClick={userIsBlocked}>
                    <span className="">  
                      <span className="nav-text" onClick={() => {userIsBlocked && window.mixpanel.track("Clicked on Call Tracking button",{distinct_id : JSON.parse(localStorage.getItem('userData')).email});} }>Call Tracking</span>                      
                    </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="coach/callSummary" className="headernav">
                <Link  to={!userData.isBlocked && "/coach/callSummary"} onClick={userIsBlocked}>
                    <span className="">
                      <span className="nav-text" onClick={() => {userIsBlocked && window.mixpanel.track("Clicked on Coach button",{distinct_id : JSON.parse(localStorage.getItem('userData')).email});} }>Speech Analytics</span>                      
                    </span>
                </Link>
              </Menu.Item>              
              <Menu.Item key="broadcast" className="headernav ">                
                <Link to={!userData.isBlocked && "#"} onClick={() => {userData.isBlocked ? userIsBlocked() : voiceBroadCastCheck()}}>
                    <span className="">
                      <span className="nav-text"  onClick={() => {window.mixpanel.track("Clicked on Broadcast button",{distinct_id : JSON.parse(localStorage.getItem('userData')).email});}  }>Broadcast</span>            
                    </span>
                </Link>
              </Menu.Item> 
            </Menu>            
            <button onClick={moveLeft} hidden={hideButtonLeft}  className="topbararrow pricingTab__arrow leftpricingarrow" type="button">
            </button>
            <button onClick={moveRight} hidden={hideButtonRight}  className="topbararrow pricingTab__arrow rightpricingarrow" type="button">
            </button>
            </div>
          }                */}
        </div>

       {/* Parvati Code Start */}
      { process.env.REACT_APP_REPORTS_APP && userData.isReportsApp ? 
        <ul className="isoRight">
            <li className="navchtobmarg" style={{marginTop:'10px'}}> 
            <Button
            className="shdemobtn" 
            href={process.env.REACT_APP_REDIRECT_URL}>Back to Dashboard</Button>
          </li>
        </ul>
      :(userData.roleData && userData.roleData.priority !== 5) ?
        <ul className="isoRight">
          <div className='searchinticon searchclosefix'>
          <AutoComplete
            allowClear={true}
           dataSource={options}
           placeholder="Search"
           optionLabelProp="value"
           dropdownClassName={`certain-category-search-dropdown topbar__Search_Bar ${isCollapsed ? 'ch_cstm_width':''}`}
           notFoundContent="No search results found."
           onSelect={onSelect}
           filterOption={(inputValue, option) =>
              option.props && option.props.dataAliasSearch && option.props.dataAliasSearch.toUpperCase().includes((inputValue.toUpperCase()).trim())
          }
          >
            <Input className='topbar__Search_Bar' />
          </AutoComplete>          
            <Icon type="search" className='listviewsearch ' />
          </div>
          {userData.isBlocked && showToast && <CustomToast message3={errorMessage} show={showToast} />}
        {/* Topbar Search End */}
        <li className="navchtobmarg" data-tour="open-dialer" style={{marginTop:'10px'}}> 
            <div className="btn-group" >
             {window.location.pathname.includes('/whatsappbusiness') && userData.roleData && (userData.roleData.priority == 1 || userData.roleData.priority == 1.5) && numberData && numberData.find((n)=>n.isWhatsappApplied) ?  <a href={process.env.REACT_APP_WHATSAPP_URL} onClick={()=>(window.mixpanel.track("Click on the Open Whatsapp Business.",{distinct_id : JSON.parse(localStorage.getItem('userData')).email}))} target="_blank" data-toggle="tooltip" title="Use Whatsapp Business Dashboard to handle Whatsapp Business numbers." 
              className="callbtn btn-sm btn-info opendialerbutton wiggle wpStyle" >
                <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"/></svg>
                <p>Open Whatsapp Dashboard<span></span></p>
              </a> :              
              <a onClick={()=>(window.mixpanel.track("Click on the Open Dialer.",{distinct_id : JSON.parse(localStorage.getItem('userData')).email}))} target="_blank" data-toggle="tooltip" title="Use dialer to make and receive calls from web browser."
              className="callbtn btn-sm btn-info opendialerbutton wiggle nwpStyle" href={DESKTOP_ONBOARDING_APP}>{/*CAL-I3095: Navigate to Desktop app page*/}
                <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"/></svg>
                <p>Open Dialer<span></span></p>
              </a>
              }
            </div>
          </li>

          <li className="navchtobmarg">
            <div className="rmtte productfruits_bell" id="productFruiteCh"  onClick={()=>handleProductFruite()}>
              <span className="productCtrlhLogo">
                <svg className="chhdwaynt" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M7.58 4.08L6.15 2.65C3.75 4.48 2.17 7.3 2.03 10.5h2c.15-2.65 1.51-4.97 3.55-6.42zm12.39 6.42h2c-.15-3.2-1.73-6.02-4.12-7.85l-1.42 1.43c2.02 1.45 3.39 3.77 3.54 6.42zM18 11c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2v-5zm-6 11c.14 0 .27-.01.4-.04.65-.14 1.18-.58 1.44-1.18.1-.24.15-.5.15-.78h-4c.01 1.1.9 2 2.01 2z"/></svg>
                <span id="HW_badge_cont" className={`HW_badge_cont ${releaseCount != 0 ? "HW_visible" :""}`}>
                  <span id="HW_badge" className={`HW_badge ${releaseCount != 0 ? "HW_softHidden":""}`}>{releaseCount}</span>
                </span>
              </span>
            </div>
          </li>
          <li className="navchtobmarg">
            <div className="rmtte">
            <Dropdown overlay={app_menu} placement="bottomCenter" overlayClassName='app_menu_list_dropdown'>
            <span className="material-icons"></span>    
            </Dropdown>   
            </div>
          </li>

          <li onClick={() => setSelectedItem('user')} className="isoUser">

            <TopbarUser history={history}/>
          </li>
        </ul> : <ul className="isoRight"> 

          <li onClick={() => setSelectedItem('user')} className="isoUser">

            <TopbarUser history={history}/>
          </li>
        </ul> 
      }
        {/* Parvati Code End */}
      </Header>
      <BroadcastPopup broadcast = {showbroadcastpopup} handleCancelvoicebroadcastPopup = {handleCancelvoicebroadcastPopup} handleRequestSend ={handleRequestSend}/>
    </TopbarWrapper>
  );
}
