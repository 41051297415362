import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Layout,Tooltip} from 'antd';
import message from '@iso/components/Feedback/Message';
import Scrollbars from '@iso/components/utility/customScrollBar';
import Menu from '@iso/components/uielements/menu';
import appActions from '@iso/redux/app/actions';
import Logo from '@iso/components/utility/logo';
import SidebarWrapper from './Sidebar.styles';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import CHTour from './CHTour';
import { APP_PRIVATE_ROUTE, PRIVATE_ROUTE } from '@iso/components/root/routeConstants';
import { Helmet } from "react-helmet";
import AddNumberModal from './AddNumberModal.js';
import PlanUpgradePopup from "@iso/components/Popups/PlanUpgrade/PlanUpgradePopup";
import Addonpopup from "../../components/Popups/addOnpopup";
import dashboardAction from "../../redux/dashboard/actions";
import ResumeAccountPopup from "../../components/Popups/resumeAccountPopup";
import {InfoCircleFilled} from '@ant-design/icons';

const imageUrl = process.env.REACT_APP_IMAGE_URL;
const callSummaryImg = imageUrl + 'icon/ch/phone_in_talk_black.svg';
const callSummarywhite = imageUrl + 'icon/ch/phone_in_talk_white.svg';
const callAnalysisImg = imageUrl + 'icon/ch/equalizer_black.svg';
const callAnalysiswhite = imageUrl + 'icon/ch/equalizer_white.svg';
const evaluationImg = imageUrl + 'icon/ch/show_chart_black.svg';
const evaluationwhite = imageUrl + 'icon/ch/show_chart_white.svg';
const libraryImg = imageUrl + 'icon/ch/local_library_black.svg';
const librarywhite = imageUrl + 'icon/ch/local_library_white.svg';
const reportImg = imageUrl + 'icon/ch/description_black.svg';
const reportwhite = imageUrl + 'icon/ch/description_white.svg';
const manageImg = imageUrl + 'icon/ch/person_add_alt_black.svg';
const managewhite = imageUrl + 'icon/ch/person_add_alt_white.svg';

const SidebarCoachWrapper = styled.div`
.ant-menu-item {
margin: 5px 0px !important;
}
.ant-menu-item-disabled {
  pointer-events: none;
}
.usersubmenu .ant-menu-submenu-title:hover {
  background-color: rgb(52, 73, 94);
}
.usersubmenu .ant-menu-submenu-title:hover .nav-text {
  color: rgb(255, 255, 255) !important;
}
.isomorphicSidebar .ant-menu-submenu-inline > .ant-menu-submenu-title {        
  padding-right: 0;
}
.isomorphicSidebar .usermenuleft.ant-menu-submenu-inline > .ant-menu-submenu-title {
  padding-left:0 !important;
}
.isomorphicSidebar .numbersmenuleft.ant-menu-submenu-inline > .ant-menu-submenu-title {
  padding-left:0 !important;
}
.isomorphicSidebar  .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
   color: #000;
   background: #000;
}
.isomorphicSidebar  .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after {
   color: #000;
   background: #000;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before{
   background: #000;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after{
   background: #000;
}
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
   background: #000;
  transform: rotate(43deg) translateX(3px) !important;
}
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
   background: #000;        
   transform: rotate(-42deg) translateX(-3px);
}
.isomorphicSidebar .ant-menu-submenu-inline .ant-menu-inline .ant-menu-item-group .ant-menu-item-group-list .ant-menu-item{
  padding-left: 60px !important;
}
.isomorphicSidebar .ant-menu-submenu-inline .ant-menu-inline .ant-menu-item-group .ant-menu-item-group-title {
  display:none;
}
.isomorphicSidebar .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
   background: #000;
   transform: rotate(-45deg) translateX(3px);
  // transform: rotate(132deg) translateX(3px);
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
  transform: rotate(45deg) translateX(-3px);
}
 .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
   background: #000;
}
 .isomorphicSidebar .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
 .isomorphicSidebar .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,  
 .isomorphicSidebar .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,  
 .isomorphicSidebar .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after {
  background-color:#fff !important;
  color:#fff !important;
}
.callAnalysisIcon {
  background-image: url(${callAnalysisImg});
  height: 18px;
  margin-right: 16px;
  width: 18px;
  background-repeat: no-repeat;
  background-size: contain;
} 
.isoDashboardMenu > li:hover .callAnalysisIcon {
  background-image: url(${callAnalysiswhite});     
} 
.evaluationIcon {
  background-image: url(${evaluationImg});
  height: 18px;
  margin-right: 16px;
  width: 18px;
  background-repeat: no-repeat;
  background-size: contain;
}
.isoDashboardMenu > li:hover .evaluationIcon {
  background-image: url(${evaluationwhite});     
} 
.callSummaryIcon {
  background-image: url(${callSummaryImg});
  height: 18px;
  margin-right: 16px;
  width: 18px;
  background-repeat: no-repeat;
  background-size: contain;
}
.isoDashboardMenu > li:hover .callSummaryIcon {
  background-image: url(${callSummarywhite});     
} 
.libraryIcon {
  background-image: url(${libraryImg});
  height: 18px;
  margin-right: 16px;
  width: 18px;
  background-repeat: no-repeat;
  background-size: contain;
}
.isoDashboardMenu > li:hover .libraryIcon {
  background-image: url(${librarywhite});     
}
.manageIcon {
  background-image: url(${manageImg});
  height: 18px;
  margin-right: 16px;
  width: 18px;
  background-repeat: no-repeat;
  background-size: contain;
}
// .isoDashboardMenu .manage-menu-icon:hover .manageIcon {
//   background-image: url(${managewhite});     
// }
.isoDashboardMenu .managewhitefix .ant-menu-submenu-title:hover .manageIcon{
  background-image: url(${managewhite});     
} 
.reportsIcon {
  background-image: url(${reportImg});
  height: 18px;
  margin-right: 16px;
  width: 18px;
  background-repeat: no-repeat;
  background-size: contain;
}
.isoDashboardMenu > li:hover .reportsIcon {
  background-image: url(${reportwhite});     
}
`;

const { EVALUATIONCOACHING, CALL_SUMMARY, COACHCALLANALYSIS, REPORTS, LIBRARY, MANAGETHEMES, PLAN_BILLING, USER_PROFILE, USER_PROFILE_DOCUMENT, CHANGE_PASSWORD} = APP_PRIVATE_ROUTE;
const { SubMenu } = Menu;
const {getCredits} = dashboardAction ;
const { Sider } = Layout;
const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
} = appActions;

function SidebarCoach(props) {
  const dispatch = useDispatch();
  const {
    view,
    openKeys,
    collapsed,
    openDrawer,
    current,
    height,
  } = useSelector(state => state.App);

  const userData = useSelector(state => state.authentication.userData.data);
  const numberData = useSelector(state => state.number.get_number);
  const user_billing = useSelector(state => state.number.user_billing);
  const get_plan = useSelector(state=>state.planReducer.get_plan);
  const isTourOpen = useSelector(state => state.authentication.isTourOpen);
  const pusherData = useSelector(state => state.dashboard.pusher_data);
  const userCredits = useSelector(state => state.dashboard.userCredits);
  var currentCredit = userCredits && userCredits.credits ? userCredits.credits : 0;
  // Pusher connecttion
  const [showCallingCreditSection, setShowCallingCreditSection] = useState(false);
  const [sidebarCredit, setSidebarCredit] = useState(0);
  const [sidebarCreditRedirect, setSidebarCreditRedirect] = useState(false);

  //This useEffect will call's every time when this component loaded
  useEffect(() => { 
    if (!userData.isTourVisited && !userData.isProfileRemaining) props.history.push(CALL_SUMMARY) ;
    sidebarCreditChange();
  }, []);
  
  //Change credit when push event call
  useEffect(() => { if(pusherData && pusherData.type === "credit") sidebarCreditChange();}, [pusherData]);
  useEffect(() =>{
    if(userData.plan.isAccessEditable && userData.isDisableCredit && !(userData.roleData.priority == 1 || userData.roleData.priority == 1.5)){
      setShowCallingCreditSection(!userData.isDisableCredit)
    }else{
      setShowCallingCreditSection(true)
    }
  }, [userData])
  const customizedTheme = useSelector(
    state => state.ThemeSwitcher.sidebarTheme
  );

  const SIDEBAR_ROUTE = {
    CALL_SUMMARY: {
      URL: CALL_SUMMARY,
      KEY: 'callSummary',
      URL_GROUP: [CALL_SUMMARY]
    },
    COACHCALLANALYSIS: {
      URL: COACHCALLANALYSIS,
      KEY: 'callAnalysis',
      URL_GROUP: [COACHCALLANALYSIS]
    }, 
    EVALUATIONCOACHING: {
      URL: EVALUATIONCOACHING,
      KEY: 'evaluationCoaching',
      URL_GROUP: [EVALUATIONCOACHING]
    },
    LIBRARY: {
      URL: LIBRARY,
      KEY: 'library',
      URL_GROUP: [LIBRARY]
    },
    REPORTS : {
      URL: REPORTS,
      KEY: 'reports',
      URL_GROUP: [REPORTS]
    },
    MANAGETHEMES : {
      URL: MANAGETHEMES,
      KEY: 'manageThemes',
      URL_GROUP: [MANAGETHEMES]
    },
    PLAN_BILLING: {
      URL: PLAN_BILLING,
      KEY: 'planBilling',
      URL_GROUP: [PLAN_BILLING]
    },
    USER_PROFILE_DOCUMENT: {
      URL: USER_PROFILE_DOCUMENT,
      KEY: 'uploadDocument',
      URL_GROUP: [USER_PROFILE_DOCUMENT]
    }
  }

  console.log("")

  useEffect(() => {
    dispatch(changeCurrent([]));
    Object.values(SIDEBAR_ROUTE).filter(obj => {
      obj.URL_GROUP.map((obj2) => {
      })
    })

    if(![USER_PROFILE,USER_PROFILE_DOCUMENT,CHANGE_PASSWORD].includes(props.history.location.pathname) && userData.isDocVerificationRequired != "approved"){
      props.history.push(USER_PROFILE_DOCUMENT);
    }
  }, [props.history.location.pathname])

  function handleClick(e) {
    //dispatch(changeCurrent([e.key]));
    if (view === 'MobileView') {
      setTimeout(() => {
        dispatch(toggleCollapsed());
        // dispatch(toggleOpenDrawer());
      }, 100);

      // clearTimeout(timer);
    }
  }

  function onOpenChange(newOpenKeys) {
    const latestOpenKey = newOpenKeys.find(
      key => !(openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = getAncestorKeys(latestCloseKey);
    }
    dispatch(changeOpenKeys(nextOpenKeys));
  }
  const getAncestorKeys = key => {
    const map = {
      sub3: ['sub2'],
    };
    return map[key] || [];
  };

  const isCollapsed = collapsed && !openDrawer;
  const mode = isCollapsed === true ? 'vertical' : 'inline';
  const onMouseEnter = event => {      
    if(window.outerWidth > 1024){
      if (collapsed && openDrawer === false) {
        dispatch(toggleOpenDrawer());
      }
    }
    return;
  };
  const onMouseLeave = () => {
    if (collapsed && openDrawer === true) {
      dispatch(toggleOpenDrawer());
    }
    return;
  };
  const styling = {
    backgroundColor: customizedTheme.backgroundColor,
  };

  const sidebarCreditChange = () => {
    let finalCredit = 0;
    if (pusherData && pusherData.type === "credit") {
      finalCredit = parseFloat(pusherData.data.totalCredits)
    } else {
      if (!(!numberData || !numberData.data || !numberData.data.length) && user_billing && user_billing.data && user_billing.data.data) {
        finalCredit = parseFloat(user_billing.data.data.availableCredits - user_billing.data.data.usedCredits)
      } else {
        if (userCredits && userCredits.credits) {
          finalCredit = userCredits.credits
        } else {
          if (userData && userData.stripeId && userData.plan && userData.plan.planDisplayName && userData.plan.planDisplayName !== 'free') {
            if (!userData.parentId || (userData.parentId && userData.totalCredits && userData.userModules.indexOf('billing') >= 0)) {
              finalCredit = userData && userData.totalCredits ? userData.totalCredits : 0;
              setSidebarCreditRedirect(true);
            } else if (userData && userData.parentId && userData.userModules && userData.userModules.indexOf('billing') == -1) {
              finalCredit = userData && userData.totalCredits ? userData.totalCredits : 0;
            }
          }
        }
      }
    }
    let userDataLocal = JSON.parse(localStorage.getItem('userData'));
    userDataLocal.totalCredits = finalCredit;
    localStorage.setItem("userData", JSON.stringify(userDataLocal));
    let formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 });
    finalCredit = formatter.format(finalCredit.toFixed(2).toString());
    setSidebarCredit(finalCredit);
  }
  
  const warningMsg = msg => { message.destroy(); message.warning(msg) };
  const userIsBlocked = () => {
    userData.isBlocked && !userData.amountDue && warningMsg(userData.blockReason);
    userData.isBlocked && userData.amountDue && warningMsg(userData.blockReason + " Please update your payment method.");

  };
  const tabTitleChange = () => {
    if (current != []) {
      current.splice(0, current.length)
      current.push("callSummary")
    }

  }
  const checkCredits = () =>{
    props.getCredits().then(({payload})=>{
      if(payload.success){
      let formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 });
      let finalCredit= payload.data ? payload.data.totalCredits ? formatter.format( payload.data.totalCredits.toFixed(2).toString()):sidebarCredit : sidebarCredit;
      let finalCr= payload.data ? payload.data.totalCredits ? payload.data.totalCredits:sidebarCredit : sidebarCredit;
      let userData = JSON.parse(localStorage.getItem('userData'));
      userData.totalCredits = finalCr;
      localStorage.setItem("userData", JSON.stringify(userData));
      setSidebarCredit(finalCredit);  
      }
    });
  }
  function changeTabTitle(currentKey) {
    // const keyName = currentKey[0];
    const keyName = window.location.pathname;
    if (keyName.match('callAnalysis')) {
      return "Coach Call Analysis"
    } else if (keyName.match("evaluationCoaching")) {
      return "Coach Evaluation Coaching"
    } else if (keyName.match("themes")) {
      return "Coach Themes"
    }
    else if (currentKey[0] == undefined || keyName.match('callSummary')) {
      return "Coach Call Summary"
    }
    else {
      return keyName[0].toUpperCase() + keyName.slice(1);
    }
  }
  
  return (
    <div>  
      <SidebarWrapper>
      <SidebarCoachWrapper>
        <Helmet>
          <title>{changeTabTitle(current)} | Callhippo.com</title>
        </Helmet>
        <CHTour isOpen={isTourOpen} />
        {/* <AddNumberModal /> */}
        <PlanUpgradePopup />
        <Addonpopup />
        <ResumeAccountPopup />
        <Sider
          trigger={null}
          collapsible={false}
          collapsed={isCollapsed}
          width={240}
          className={`isomorphicSidebar  ${userData && userData.isProfileRemaining ? "sidebarDisabled" : ''}`}
          style={styling}
        >
          <Logo collapsed={isCollapsed} isBlocked={userData.isBlocked} />
          <Scrollbars className={`sidebarScrollbars scrolltabheight ${userData.isProfileRemaining ? "sidebarDisabledOpacity" : ''}`} 
            style={ isCollapsed ? {height: height - 75} : {height: height - 94}}
          >
            <Menu
              onClick={handleClick}
              theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              // openKeys={isCollapsed ? [] : openKeys}
              selectedKeys={current}
              onOpenChange={onOpenChange}
            >
              <Menu.Item key={SIDEBAR_ROUTE.CALL_SUMMARY.KEY} className={window.location.pathname.match('/callSummary') ? "ant-menu-item-selected" : ''}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.CALL_SUMMARY.URL} >
                    <span className="isoMenuHolder">
                      <i className="callSummaryIcon"></i>  
                      <span className="nav-text">Call Summary</span>
                    </span>
                </Link>
              </Menu.Item>
              
              <Menu.Item key={SIDEBAR_ROUTE.COACHCALLANALYSIS.KEY} className={window.location.pathname.match('/callAnalysis') ? "ant-menu-item-selected" : ''}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.COACHCALLANALYSIS.URL}>
                  <span className="isoMenuHolder">
                    <i className="callAnalysisIcon"></i>  
                    <span className="nav-text">Call Analysis</span>
                    </span>
                    </Link>
                    </Menu.Item>
              <Menu.Item key={SIDEBAR_ROUTE.EVALUATIONCOACHING.KEY} data-tour="tour-activity" className={window.location.pathname.match('/evaluationCoaching') ? "ant-menu-item-selected" : ''}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.EVALUATIONCOACHING.URL}>
                  <span className="isoMenuHolder">
                  <i className="evaluationIcon"></i>  
                    <span className="nav-text">Evaluation/Coaching</span>
                  </span>
                </Link>
              </Menu.Item> 
              <Menu.Item key={SIDEBAR_ROUTE.LIBRARY.KEY} data-tour="tour-activity" className={window.location.pathname.match('/library') ? "ant-menu-item-selected" : ''} disabled={true}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.LIBRARY.URL}>
                  <span className="isoMenuHolder">
                   <i className="libraryIcon"></i>  
                    <span className="nav-text">Library</span>
                  </span>
                </Link>
              </Menu.Item> 
              <Menu.Item key={SIDEBAR_ROUTE.REPORTS.KEY} data-tour="tour-activity" className={window.location.pathname.match('/reports') ? "ant-menu-item-selected" : ''} disabled={true}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.REPORTS.URL}>
                  <span className="isoMenuHolder">
                    <i className="reportsIcon"></i>  
                    <span className="nav-text">Reports</span>
                  </span>
                </Link>
              </Menu.Item> 
              <SubMenu key="sub1"
              className={window.location.pathname == '/themes'  ? "usermenuleft usersubmenu managewhitefix" : 'usersubmenu managewhitefix'}
              title={
                  <Menu.Item>
                  <Link onClick={userIsBlocked}>
                    <span className="isoMenuHolder">
                      <i className="manageIcon"></i>  
                      <span className="nav-text">Manage</span>
                    </span>
                  </Link>
                </Menu.Item> 
                
              }>
                <Menu.ItemGroup key="g1">                  
                <Menu.Item key={SIDEBAR_ROUTE.MANAGETHEMES.KEY} className={window.location.pathname.match('/themes') ? "ant-menu-item-selected" : ''}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.MANAGETHEMES.URL}>
                      <span className="isoMenuHolder">                    
                        <span className="nav-text">Themes</span>
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item disabled={true}>
                    <Link>
                      <span className="isoMenuHolder">
                        <span className="nav-text">Feedback Forms</span>
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item  disabled={true}>
                    <Link>
                      <span className="isoMenuHolder">
                        <span className="nav-text">Agents</span>
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item  disabled={true}>
                    <Link>
                      <span className="isoMenuHolder">
                        <span className="nav-text">Teams</span>
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item  disabled={true}>
                    <Link>
                      <span className="isoMenuHolder">
                        <span className="nav-text">QAs</span>
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item  disabled={true}>
                    <Link>
                      <span className="isoMenuHolder">
                        <span className="nav-text">Clients</span>
                      </span>
                    </Link>
                  </Menu.Item>
                </Menu.ItemGroup>
              </SubMenu> 
            </Menu>
          </Scrollbars>
          {showCallingCreditSection && 
          <div className="sidebarCreditDiv credibtntab chflex chflexcenter" style={{marginTop:"-10px"}}>
            {sidebarCreditRedirect  || !userData.isFreeTrialUser ?
              (
                <>
                  {isCollapsed ? "" :
                  <>
                    <Link to={SIDEBAR_ROUTE.PLAN_BILLING.URL}>
                      <span className="sidebarCreditSpan" onClick={() => tabTitleChange()}>
                        Credits
                        <Tooltip title={<div className='sidebarCreditsTooltip'>Credits are common for calling and SMS</div>} open>
                          <InfoCircleFilled className='creditsInfoIcon' /> 
                        </Tooltip>
                        <span className="currentCreditValue">
                          {(currentCredit > 0) ? sidebarCredit : '$0.00'}
                        </span>
                    </span>
                    </Link>
                    <Tooltip title="Refresh">
                    <div className="refreshwrapper chflex chflexcenter justcenter" onClick={()=> checkCredits()}><div className="refreshbtn"></div></div>
                    </Tooltip>             
                    </>
                  }
                </>
              )
              
            :
                <>
                <span className="sidebarCreditSpan">
                  Credits
                  <Tooltip title={<div className='sidebarCreditsTooltip'>Credits are common for calling and SMS</div>} open>
                    <InfoCircleFilled className='creditsInfoIcon' /> 
                  </Tooltip>
                  <span className="currentCreditValue">
                    {(currentCredit > 0) ? sidebarCredit : '$0.00'}
                  </span>
                </span>
                <Tooltip title="Refresh">
                    <div className="refreshwrapper chflex chflexcenter justcenter" onClick={()=> checkCredits()}><div className="refreshbtn"></div></div>
                </Tooltip>
              </>
            }
            </div>}
        </Sider>
        </SidebarCoachWrapper>
      </SidebarWrapper>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    numbers:state.number.get_number,
    user_billing: state.number.user_billing,
    pusherData: state.dashboard.userData,
  }
}
export default connect(mapStateToProps,{getCredits})(withRouter(SidebarCoach));