import React, { useState, useEffect } from 'react';
import Modals from '@iso/components/Feedback/Modal';
import enterprisewrapper from './enterprisePlanpopup.style';
import {  Button ,Row,Col,Card,Switch  } from 'antd';
import modalActions from '@iso/redux/modal/actions';
import message from '@iso/components/Feedback/Message';
import {useDispatch, useSelector} from "react-redux";
import numberAction from "@iso/redux/number/actions";
const Modal = enterprisewrapper(Modals);
const { closeModal } = modalActions;
const {sendDetailsForVoxbone} = numberAction;

const Enterpriseplanpopup = () => {
    const dispatch = useDispatch();
    const { modalVisibility, modalType, modalProps } = useSelector(state => state.modal);

    const userData = JSON.parse(localStorage.getItem('userData'));
    if(!userData){
        window.location.href ="/login"
    }

    useEffect(() => {
    }, [modalProps])


    const handleCancel = () => {
        dispatch(closeModal());
    };

    const sendRequest = () => {
        dispatch(sendDetailsForVoxbone({type : 'addcampaign',featureName:modalProps.feature}));
        dispatch(closeModal());
        message.destroy();
        message.success("We have received your request successfully. Our Expert team will get in touch with you soon.")
    };

    return (
        <Modal
        visible={modalVisibility && modalType == "enterprise"}
        onCancel={handleCancel}
        width="400px"        
        footer={null}
        >
             <div className="enterprisewrapper">
                 <h2 className="subtitle">Subscription Details</h2>                                                
                <Row  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="monthlyswitch">
                    
                    <Col span={24}>
                        <Card title={[<div>Enterprise Customized <br/> Solution</div>]} className="plancard planDisabled" >                            
                            <p className="planmonth">Tailor-Made for You</p>
                            <div className="planbtncenter">
                                <Button  className="signupbtn" onClick={sendRequest}>Contact Us</Button>
                            </div>
                            <p className="planmonth">One-Stop Call Center Solution For Enterprise Businesses</p>                            
                            <div className="everthingbox">Everything in Platinum +</div>
                            <ul className="planlist">
                               <li className="sublist">Get A Free Trial
                               </li>
                                <li>Personalized Onboarding</li>
                                <li>Prioritized Custom Development</li>
                                <li>Advanced Integrations</li>
                                <li className="sublist">Bring Your Own Carrier (BETA)
                                    <span className="material-icons subinfo">info</span>
                                </li>
                                <li>Custom Integrations</li>
                            </ul>
                            <a href="https://callhippo.com/pricing" target="_blank" className="morefeaturtext">More Features</a>

                        </Card>
                    </Col>                    
                </Row>     
                </div>                      
        </Modal>
    )
}
export default  Enterpriseplanpopup;