import styled from 'styled-components';
import { palette } from 'styled-theme';
import {
  transition,
  borderRadius,
  boxShadow,
} from '@iso/lib/helpers/style_utils';
import WithDirection from '@iso/lib/helpers/rtl';

const imageUrl = process.env.REACT_APP_IMAGE_URL;
const app_icon = imageUrl + 'apps_black.svg';

const TopbarWrapper = styled.div`
  .isomorphicTopbar {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    box-shadow: 0 0px 2px 0 rgba(0,0,0,.14), 0 1px 1px -2px rgba(0,0,0,.2), 0 1px 4px 0 rgba(0,0,0,.12);
    ${'' /* border-bottom:1px solid #e1e1e1; */}
    padding: ${props =>
      props['data-rtl'] === 'rtl' ? '0 265px 0 31px' : '0 31px 0 200px'};
    z-index: 1000;
    ${transition()};

    @media only screen and (max-width: 767px) {
      padding: ${props =>
        props['data-rtl'] === 'rtl'
          ? '0px 260px 0px 15px !important'
          : '0px 15px 0px 260px !important'};
    }

    &.collapsed {
      padding: ${props =>
        props['data-rtl'] === 'rtl' ? '0 109px 0 31px' : '0 31px 0 109px'};
      @media only screen and (max-width: 767px) {
        padding: '0px 15px !important';
      }
    }

    .isoLeft {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 767px) {
        margin: ${props =>
          props['data-rtl'] === 'rtl' ? '0 0 0 20px' : '0 20px 0 0'};
      }

      .triggerBtn {
        width: 24px;
        height: 100%;
        display: -webkit-inline-flex;
        display: -ms-inline-flex;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 0;
        outline: 0;
        position: relative;
        cursor: pointer;

        &:before {
          content: '\f20e';
          font-family: 'Ionicons';
          font-size: 26px;
          color: inherit;
          line-height: 0;
          position: absolute;
        }       

        
      }
    }

    .isoRight {
      display: flex;
      align-items: center;

      li {
        margin-left: ${props => (props['data-rtl'] === 'rtl' ? '35px' : '0')};
        margin-right: ${props => (props['data-rtl'] === 'rtl' ? '0' : '35px')};
        cursor: pointer;
        line-height: normal;
        position: relative;
        display: inline-block;

        @media only screen and (max-width: 360px) {
          margin-left: ${props => (props['data-rtl'] === 'rtl' ? '25px' : '0')};
          margin-right: ${props =>
            props['data-rtl'] === 'rtl' ? '0' : '25px'};
        }

        &:last-child {
          margin: 0;
        }

        i {
          font-size: 24px;
          color: ${palette('text', 0)};
          line-height: 1;
        }

        .isoIconWrapper {
          position: relative;
          line-height: normal;

          span {
            font-size: 12px;
            color: #fff;
            background-color: ${palette('secondary', 1)};
            width: 20px;
            height: 20px;
            display: -webkit-inline-flex;
            display: -ms-inline-flex;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            line-height: 20px;
            position: absolute;
            top: -8px;
            left: ${props =>
              props['data-rtl'] === 'rtl' ? 'inherit' : '10px'};
            right: ${props =>
              props['data-rtl'] === 'rtl' ? '10px' : 'inherit'};
            ${borderRadius('50%')};
          }
        }

        &.isoMail {
          .isoIconWrapper {
            span {
              background-color: ${palette('color', 0)};
            }
          }
        }

        &.isoNotify {
          .isoIconWrapper {
            span {
              background-color: ${palette('primary', 2)};
            }
          }
        }

        &.isoMsg {
          .isoIconWrapper {
            span {
              background-color: ${palette('color', 1)};
            }
          }
        }

        &.isoCart {
          .isoIconWrapper {
            span {
              background-color: ${palette('color', 2)};
            }
          }
        }

        &.isoUser {
          .isoImgWrapper {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            background-color: ${palette('grayscale', 9)};
            ${borderRadius('50%')};

            img {
              height: 100%;
              object-fit: cover;
            }

            .userActivity {
              width: 10px;
              height: 10px;
              display: block;
              background-color: ${palette('color', 3)};
              position: absolute;
              bottom: 0;
              right: 3px;
              border: 1px solid #ffffff;
              ${borderRadius('50%')};
            }
          }
        }
      }
    }
  }

  .isoUserDropdown {
    .ant-popover-inner {
      .ant-popover-inner-content {
        .isoUserDropdownContent {
          padding: 7px 0;
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 0;
          right: 0;
          background-color: #ffffff;
          width: 220px;
          min-width: 160px;
          flex-shrink: 0;
          .isoBorderRadius(5px);
          ${borderRadius('5px')};
          ${boxShadow('0 2px 10px rgba(0,0,0,0.2)')};
          ${transition()};

          .isoDropdownLink {
            font-size: 13px;
            color: ${palette('text', 1)};
            line-height: 1.1;
            padding: 7px 15px;
            background-color: transparent;
            text-decoration: none;
            display: flex;
            justify-content: flex-start;
            ${transition()};

            &:hover {
              background-color: ${palette('secondary', 6)};
            }
          }
        }
      }
    }
  }

  // Dropdown
  .ant-popover {
    .ant-popover-inner {
      .ant-popover-inner-content {
        .isoDropdownContent {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 0;
          right: 0;
          background-color: #ffffff;
          width: 360px;
          min-width: 160px;
          flex-shrink: 0;
          ${borderRadius('5px')};
          ${boxShadow('0 2px 10px rgba(0,0,0,0.2)')};
          ${transition()};

          @media only screen and (max-width: 767px) {
            width: 310px;
          }

          .isoDropdownHeader {
            border-bottom: 1px solid #f1f1f1;
            margin-bottom: 0px;
            padding: 15px 30px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            h3 {
              font-size: 14px;
              font-weight: 500;
              color: ${palette('text', 0)};
              text-align: center;
              text-transform: uppercase;
              margin: 0;
            }
          }

          .isoDropdownBody {
            width: 100%;
            height: 300px;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            background-color: ${palette('grayscale', 6)};

            .isoDropdownListItem {
              padding: 15px 30px;
              flex-shrink: 0;
              text-decoration: none;
              display: flex;
              flex-direction: column;
              text-decoration: none;
              width: 100%;
              ${transition()};

              &:hover {
                background-color: ${palette('grayscale', 3)};
              }

              .isoListHead {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;
              }

              h5 {
                font-size: 13px;
                font-weight: 500;
                color: ${palette('text', 0)};
                margin-top: 0;
              }

              p {
                font-size: 12px;
                font-weight: 400;
                color: ${palette('text', 2)};
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              .isoDate {
                font-size: 11px;
                color: ${palette('grayscale', 1)};
                flex-shrink: 0;
              }
            }
          }

          .isoViewAllBtn {
            font-size: 13px;
            font-weight: 500;
            color: ${palette('text', 2)};
            padding: 10px 15px 20px;
            display: flex;
            text-decoration: none;
            align-items: center;
            justify-content: center;
            text-align: center;
            ${transition()};

            &:hover {
              color: ${palette('primary', 0)};
            }
          }

          .isoDropdownFooterLinks {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 30px 20px;

            a {
              font-size: 13px;
              font-weight: 500;
              color: ${palette('text', 0)};
              text-decoration: none;
              padding: 10px 20px;
              line-height: 1;
              border: 1px solid ${palette('border', 1)};
              display: flex;
              align-items: center;
              justify-content: center;
              ${transition()};

              &:hover {
                background-color: ${palette('primary', 0)};
                border-color: ${palette('primary', 0)};
                color: #ffffff;
              }
            }

            h3 {
              font-size: 14px;
              font-weight: 500;
              color: ${palette('text', 0)};
              line-height: 1.3;
            }
          }

          &.withImg {
            .isoDropdownListItem {
              display: flex;
              flex-direction: row;

              .isoImgWrapper {
                width: 35px;
                height: 35px;
                overflow: hidden;
                margin-right: 15px;
                display: -webkit-inline-flex;
                display: -ms-inline-flex;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                background-color: ${palette('grayscale', 9)};
                ${borderRadius('50%')};

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .isoListContent {
                width: 100%;
                display: flex;
                flex-direction: column;

                .isoListHead {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 10px;
                }

                h5 {
                  margin-bottom: 0;
                  padding-right: 15px;
                }

                .isoDate {
                  font-size: 11px;
                  color: ${palette('grayscale', 1)};
                  flex-shrink: 0;
                }

                p {
                  white-space: normal;
                  line-height: 1.5;
                }
              }
            }
          }
        }
      }
    }

    &.topbarMail {
      .ant-popover-inner {
        .ant-popover-inner-content {
          .isoDropdownContent {
            @media only screen and (max-width: 519px) {
              right: -170px;
            }
          }
        }
      }
    }

    &.topbarMessage {
      .ant-popover-inner {
        .ant-popover-inner-content {
          .isoDropdownContent {
            @media only screen and (max-width: 500px) {
              right: -69px;
            }
          }
        }
      }
    }

    &.topbarNotification {
      .ant-popover-inner {
        .ant-popover-inner-content {
          .isoDropdownContent {
            @media only screen and (max-width: 500px) {
              right: -120px;
            }
          }
        }
      }
    }

    &.topbarAddtoCart {
      .ant-popover-inner {
        .ant-popover-inner-content {
          .isoDropdownContent {
            @media only screen and (max-width: 465px) {
              right: -55px;
            }

            .isoDropdownHeader {
              margin-bottom: 0;
            }

            .isoDropdownBody {
              background-color: ${palette('grayscale', 6)};
            }
          }
        }
      }
    }
  }
  /* top bar custom styles */
  .usrname_topbar {
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90px;
  }
  .nmusercircle {
    display: flex;       
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 35px;
    height: 35px;
    border-radius: 100px;
    background: #2d3446;
    color: white;
  }
  .callbtn {
    color: #fff;
    background-color: #e27646;
    border-color: #ff9800;
    border-radius: 30px;
    border-width: 0;
    padding: 1px 4px;
    box-shadow: inset 0 0 0 1px rgba(0,0,0,.08);
    height: 37px;
    margin-bottom: 4px;
    text-transform: capitalize;
    width: 122px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
  .callbtn i {
    font-size: 18px !important;
    color:white !important;
  }
  .callbtn p {
    font-size: 14px;
    font-weight: 500;
    margin-left: 5px;
  }
  .make-call {
    display: inline-block;
    background: #e2e2e2;
    border: 0;
    line-height: 2.7em;
    padding: 0 1em 0;
    margin-bottom: 1em;
    outline: none;
    text-decoration: none;
    color: #666;
    border-radius: 4px;
    padding-right: 0;
    padding-left: 5px;
    margin-top: 10px;

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
  .make-call svg.firstLoginMsgCancel {
    float: left;
    margin-top: 9px;
    margin-right: 5px;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    top: 5px;
    cursor: pointer;
    font-weight: 600;
    fill: #656565;
}
.clicktext {
  margin-right: 10px;
}
.arrowflani {
  width: 36px;
  float: right;
  height: 39px;
  background: #e27647;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.navarrawc {
  opacity: 0;
  position: absolute;
  left: 93%;
  top: 58%;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: translate3d(-50%,-50%,0);
  transform: translate3d(-50%,-50%,0)
}

.navarrawc-1 {
  -webkit-animation: navarraw-movement 2s ease-in-out infinite;
  animation: navarraw-movement 2s ease-in-out infinite;
  transform: rotate(-87deg) translateX(23%)
}

.navarrawc-2 {
  -webkit-animation: navarraw-movement 2s 1s ease-in-out infinite;
  animation: navarraw-movement 2s 1s ease-in-out infinite;
  transform: rotate(-87deg) translateX(23%)
}

.navarrawc:before,.navarrawc:after {
  background: #fff;
  content: '';
  display: block;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  width: 12px
}

.navarrawc:before {
  -webkit-transform: rotate(45deg) translateX(-23%);
  transform: rotate(45deg) translateX(-23%);
  -webkit-transform-origin: top left;
  transform-origin: top left
}

.navarrawc:after {
  -webkit-transform: rotate(-45deg) translateX(23%);
  transform: rotate(-45deg) translateX(23%);
  -webkit-transform-origin: top right;
  transform-origin: top right
}

@-webkit-keyframes navarraw-movement {
  0% {
      opacity: 0;
      top: 58%;
      left: 90%
  }

  70% {
      opacity: 1
  }

  100% {
      opacity: 0
  }
}

@keyframes navarraw-movement {
  0% {
      opacity: 0;
      top: 58%;
      left: 90%
  }

  70% {
      opacity: 1
  }

  100% {
      opacity: 0
  }
}
.navchtobmarg {
  margin-right: 15px !important;
}

.shdemobtn{
  text-decoration: underline;
  color: #333333;
  font-size: 15px;
}

.isomorphicTopbar .isoRight li.shdemo_clicktext_wrapper{
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}
.isomorphicTopbar .isoRight li .rmtte{
  position: relative;
  display: flex;

  svg.chhdwaynt{
    padding: 7px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: #e27646;
    border-radius: 50px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.14), 0 1px 1px -2px rgba(0,0,0,.2), 0 1px 4px 0 rgba(0,0,0,.12);
  }

  .HW_badge_cont{
    position: absolute;

    .HW_badge{
      left: 20px;
      top: 0;
    }
  }
}
/*header tab*/
.headernav.ant-menu-item-selected{
  color:#e27646;
  border-bottom: 2px solid #e27646;
}
.headernav.ant-menu-item:hover{
  color:#e27646;
  border-bottom: 2px solid #e27646;
}
.headernav.ant-menu-item > a:hover{
  color:#e27646;
}
.headernav.ant-menu-item-selected a{
  color:#333;
  font-weight:500;
}
li.ant-menu-item.headernav{
  padding: 12px 20px;
}
.headernav.ant-menu-item > a{
  transform: translate(0px, 7px);
}

// Parvati Css Start

.opendialerbutton,
.opendialerbutton:hover {
  color: #fff;
  -webkit-animation: wiggle 5s linear infinite;
  animation: wiggle 5s linear infinite;
  border-radius: 30px;
  font-weight: 700;
  padding: 0px 20px;
  background-repeat: no-repeat;
  border:1px solid #e27646;
  cursor: pointer;
  overflow: hidden;
  outline: medium none;
  // background-color: rgb(255, 153, 0);
  background-color:#e27646;
  letter-spacing: 0.1em;
}
.wpStyle,
.wpStyle:hover{
  width:270px !important;
}
.nwpStyle,
.nwpStyle:hover{
  width:160px !important;
}
@keyframes wiggle {
2% {
transform: translateX(1px) rotate(1deg);
}
4% {
transform: translateX(-1px) rotate(-1deg);
}
6% {
transform: translateX(1px) rotate(0deg);
}
8% {
transform: translateX(-1px) rotate(0deg);
}
10% {
transform: translateX(2px) rotate(1deg);
}
12% {
transform: translateX(-2px) rotate(-1deg);
}
14% {
transform: translateX(2px) rotate(0deg);
}
16% {
transform: translateX(-2px) rotate(0deg);
}
18% {
transform: translateX(1px) rotate(0deg);
}
20% {
transform: translateX(-1px) rotate(0deg);
}
}

.shdemobtn{
  color: #fff;
  background-color: #e27646;
  border-color: #ff9800;
  border-radius: 30px;
  border-width: 0;
  padding: 1px 4px;
  box-shadow: inset 0 0 0 1px rgba(0,0,0,.08);
  height: 37px;
  margin-bottom: 4px;
  text-transform: capitalize;
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration:none;
}
.shdemobtn:hover {
  color: #fff;
  background-color: #e27646;
  border-color: #ff9800;
  border-radius: 30px;
  border-width: 0;
  padding: 1px 4px;
  box-shadow: inset 0 0 0 1px rgba(0,0,0,.08);
  height: 37px;
  margin-bottom: 4px;
  text-transform: capitalize;
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration:none;
}
// Parvati Css End
.topbararrow{
  display:none;
}
.collapsibletopbarmenu{
  position: absolute !important;
  left: 237px;
  top: 0px !important;
}
.closemenu {
    position: absolute !important;
    top: 0px;
    left: 140px;
}
.isomorphicTopbar.collapsed .closemenu{
  left:80px !important;
}
.isomorphicTopbar.collapsed .bpsLeft10{
  margin-left:2px !important;
}
.isomorphicTopbar.collapsed .ant-select-search .topbar__Search_Bar {
  width: 320px !important;
}
.bpsLeft10 {
  margin-left:70px;
}
li.ant-menu-item.headernav{
  padding: 12px 15px;
}
.ant-dropdown-trigger {
  background-image: url(${app_icon});
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  background-size: contain;  
  display:block;
}
.topbar__Search_Bar {
    margin-right: 15px !important;
    width: 260px !important;
    border-radius: 20px !important;    
}
.certain-category-search-dropdown.topbar__Search_Bar div ul li ul li a {
  width:100%;
  hight:100%;
}
.search_icon {
  position: absolute;
  right: 445px;
  top: 33px;
  cursor: pointer;
}
.search__Icon_Box {
  box-shadow: 0 4px 2px 0px rgb(0 0 0 / 2%);
  border: 1px solid #DDDDDD;
  background-color: #FFFFFF;
  padding: 7px;
  border-radius: 50%;
  position: absolute;
  right: 435px;
  top: 25px;
  cursor: pointer;
}

.listviewsearch {
  right: 33px;
  top:27px;
}
.searchinticon .ant-select-selection:active {
  border:unset !important;
}
.searchinticon {
  margin-top: 5px;
}
.topBookDemoBtn,
.topBookDemoBtn:hover,
.topBookDemoBtn:focus {
  color: #e27646 !important;
  height: 36px !important;
  width: 108px !important;
  margin-top: 1px !important;
  border-radius: 5px !important;
  border: 1px solid #E27646 !important;
  background: #FFF !important;
}
.newTopBookDemoBtn, .newTopBookDemoBtn:hover, .newTopBookDemoBtn:focus {
  background: #e27646 !important;
  color: #fff !important;
  height: 37px !important;
  width: unset !important;
  border-radius: 30px !important;
  font-size: 14px;
  font-weight: 500;
  line-height:normal;
  padding: 7px 24px;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .isomorphicTopbar .isoLeft .triggerBtn{
    display:block;
    margin-right: 20px;
    margin-right: 5px;
    left:230px !important
  }
  .isomorphicTopbar.collapsed .isoLeft .triggerBtn{
    margin-left:-10px;
  }
  .isomorphicTopbar.collapsed{
    padding: 0 0px 0 80px;
  }
  .scheduledemotab, .ctrlh_link{
    display: none !important;
  }
  .topbararrow{
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    position: fixed;
    top: 37px;  
  }
  .leftpricingarrow {
    transform: rotate(135deg);
    left: 124px;
  }
   .rightpricingarrow {
    transform: rotate(-45deg);
    left:325px;
  }
  
  .headernavtabwrapper li.ant-menu-item-selected{
    color: #333
    border-bottom: 2px solid rgb(226, 118, 70);
  } 
  .headernavtab{
    border-bottom: 2px solid rgb(226, 118, 70);
  } 
  .headernavtabwrapper li a {
    transform: translate(0px, 7px);
    color: #333;
  } 
  .headernavtabwrapper li a:hover{
    color: rgb(226, 118, 70);   
  }
  .topbarmenu {      
    display:flex;
    width:500px;
    margin-left: 0;
  }
  .headernavtabwrapper li.ant-menu-submenu{
    display:none !important;
  }
  .headernavtabwrapper li{   
    padding: 12px 22px; 
    display:block !important;   
  }
  .headernavtabwrapper{
    position:relative;
    overflow: scroll;
    width:200px;
    scroll-behavior: smooth;
  }
  .headernavtabwrapper .ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected{
    color: #333;
    border-bottom: 2px solid #e27646 ;
  }
  .hidetopmenu{
    display:none;
  }
  .closemenutab {
    transform: translate(0px, 6px);
    position: inherit !important;  
  }
}

@media (min-width: 1200px) and (max-width: 1279px) {
  li.ant-menu-item.headernav {
    padding: 12px 10px;
  }
  .navchtobmarg {
    margin-right: 10px !important;
  }
}

@media (min-width: 1280px) and (max-width: 1359px) {
li.ant-menu-item.headernav {
  padding: 12px 8px;
}
.bpsLeft10 {
  margin-left:65px;
}
.navchtobmarg {
  margin-right: 10px !important;
}
}
@media (min-width: 1280px) and (max-width: 1349px){
  .topbar__Search_Bar {
    width: 300px !important;
  }
  .search_icon {
    right: 430px;
  }
  .listviewsearch {
    top: 24px;
  }
}
@media (min-width: 1350px){
  .topbar__Search_Bar {
    width: 320px !important;
  }
}
@media (min-width: 1280px) and (max-width: 1355px) {
  .search_icon {
    right: 430px;
  }
}
@media (min-width: 1355px) and (max-width: 1500px) {
  .search_icon {
    right: 445px;
  }
}
@media only screen and (max-width:1199px) {
  .topbar__Search_Bar {
    width: 210px !important;
  }
  .newTopBookDemoBtn {
    font-size: 13px;
    padding: 7px 18px !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) { 
  .searchinticon {
    display:none;
  }
}
.productfruits_bell{
    cursor: pointer !important;
    width:30px;
    position: relative;
    .HW_badge_cont {
      visibility: hidden;
      pointer-events: none;
      display: block;
      cursor: pointer;
      position: relative;
      width: 32px;
      height: 32px;
    }
    .HW_badge_cont {
      position: absolute;
      top: -2px;
      right: -2px;
    }
    .HW_badge_cont.HW_visible {
      visibility: visible;
      pointer-events: auto;
    }
    .HW_badge {
      display: block;
      border-radius: 20px;
      height: 18px;
      width: 18px;
      color: #fff;
      text-align: center;
      line-height: 18px;
      font-size: 12px;
      cursor: pointer;
      position: absolute;
      top: 8px;
      left: 8px;
      will-change: scale;
      transition: all 0.3s;
      opacity: 0;
      transform: scale(0);
      left: 20px;
      top: 0px;
      font-weight: 500;
    }
    .HW_badge.HW_softHidden {
      background: #e27646;
      opacity: 1;
      transform: scale(0.6);
    }
  }
`;

export default WithDirection(TopbarWrapper);
