import styled from 'styled-components';

const CHTour = ComponentName => styled(ComponentName)`
max-width:550px;

.productTourTitle{
  padding:8px 14px;
  text-align: center;
  font-size:22px;
  color:#333;
  background-color:#fff;
  font-weight:500;
}
.producttournextbtn{
  background-color: #E27849;
  border: none;
  color: #fff;
  cursor:pointer;
  outline:none;
  text-align:center;
  border-radius: 4px;
  display: inline-block;
  height: 37px;
  line-height: 36px;
  padding: 0 2rem;
  text-transform: uppercase;
  font-size:12px;
  margin-left: 2px;
}
.endtourbtn{
  border-color: #fafafa;
  color: #000;
  border-radius: 4px;
  color: #707980;
  cursor:pointer;
  height: 37px;
  line-height: 36px;
  padding: 0 2rem;
  text-transform: uppercase;
  font-size:12px;
  box-shadow: inset 0 0 0 1px rgba(0,0,0,.08) !important;
  // border: 1px solid #dbdbdb !important;
  border: unset !important;
  margin-left:2px;
  outline:0;
}
.endtourbtn:hover{
  color: #262d33 !important;
  background-color: #e1e1e1 !important;
  border-color: #dbdbdb !important;
}
.ptButtonWrapper{
  margin-top:16px;
  text-align:center;
}
.productTourContent{
  padding: 10px 12px;
  font-size:15px;
  color:#333;
}
.ptc1{
  margin-bottom:1rem;
}
.productTourArrow:after{
  content:"";
  left: -40px;
  top: 50%;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #fff;
  position:absolute;
}
.userArrow:after{
  top:68px !important;
}
.callLogsArrow:after{
  top:50px !important;
}
.prevarrow{
  font-size: 18px;
    line-height: 1px;
    /* vertical-align: middle; */
    display: inline-block;
    transform: translate(-4px,0px);
}
.nextarrow{
  font-size: 18px;
    line-height: 1px;
    /* vertical-align: middle; */
    display: inline-block;
    transform: translate(4px,0px);
}
.startTourMain{
  position:relative;
}
.ptdialerarrow:after{
  content: "";
  top: -30px;
  width: 0;
  height: 0;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  left: 34%;
}
.numbersettingarrow:after{
  transform: rotate(180deg);
  right: -38px !important;
  left: auto;
  top: 60px !important;
}
.numberarrow:after{
  top:38px !important;
}
.productTourWrapper.reactour__helper--is-open{
  transform: translate(99px,35px);
}
@media (min-width: 768px) and (max-width: 1024px) {
   .productTourWrapper.reactour__helper--is-open{
    transform: translate(99px,35px);
  }
}
`;

export default CHTour;