//Integraion Icon
const imageUrl = process.env.REACT_APP_IMAGE_URL;
const bitrix24Logo = imageUrl+ "icon/ch/integration/bitrix24.png";
const oneloginLogo = imageUrl+ "icon/ch/integration/onelogin.png";
const ssologinLogo = imageUrl+ "icon/ch/integration/sso.svg";
const pipedriveIcon = imageUrl+ "icon/ch/integration/pipedrive.png";
const freshdeskIcon = imageUrl+ "icon/ch/integration/freshdesk.png";
const grooveIcon = imageUrl+ "icon/ch/integration/groove.png";
const hubspotIcon = imageUrl+ "icon/ch/integration/hubspot.png";
const intercomIcon = imageUrl+ "icon/ch/integration/intercom.png";
const microsoftDynamicsIcon = imageUrl+ "icon/ch/integration/microsoft-dynamics.png";
const salesforceIcon = imageUrl+ "icon/ch/integration/salesforce.png";
const shopifyIcon = imageUrl+ "icon/ch/integration/shopify.png";
const slackIcon = imageUrl+ "icon/ch/integration/slack.svg";
const zapierIcon = imageUrl+ "icon/ch/integration/zapier.png";
const zendeskIcon = imageUrl+ "icon/ch/integration/zendesk.jpg";
const zohoIcon = imageUrl+ "icon/ch/integration/zoho.svg";
const zohorecruitIcon = imageUrl+ "icon/ch/integration/zohorecruit.svg";
const customIntegrationIcon = imageUrl+ "icon/ch/integration/customIntegration.png";
const typeFormIcon = imageUrl+ "icon/ch/integration/typeform.png";
const integromatIcon = imageUrl+ "icon/ch/integration/integromat.png";
const odooIcon = imageUrl+ "icon/ch/integration/odoo.png";
const phoneBridgeIcon = imageUrl+ "icon/ch/integration/phoneBridge.jpg";
const lessAnnoyingIcon = imageUrl+ "icon/ch/integration/lacrm.png";
const keapIcon = imageUrl+ "icon/ch/integration/keap.jpeg";
const kylasLogo = imageUrl+ "icon/ch/integration/kylas.png"
//popup Logos 

const zendeskpopup = imageUrl+ "zendeskLogo.png";
const freshdeskpopup = imageUrl+ "freshdeskLogo.png";
const groovepopup = imageUrl+ "grooveLogo.png";
const hubspotpopup = imageUrl+ "hubspotLogo.png";
const zapierpopup = imageUrl+ "zapierLogo.png";
const intercompopup = imageUrl+ "intercomlogo.png";
const talentLyftpopup = imageUrl+ "talentLyftLogo.png";
const ontraPortLogo = imageUrl+ "ontraportLogo.png";


//End Integraion Icon

//Integraion Logo
const pipedriveLogo = imageUrl+ "icon/ch/integration/pipedrive_dif.png";
const freshdeskLogo = imageUrl+ "icon/ch/integration/freshdesk.png";
const grooveLogo = imageUrl+ "icon/ch/integration/groove.png";
const hubspotLogo = imageUrl+ "icon/ch/integration/hubspot.png";
const slackLogo = imageUrl+ "icon/ch/integration/slackLogo.svg";
const zapierLogo = imageUrl+ "icon/ch/integration/zapier.png";
const zendeskLogo = imageUrl+ "icon/ch/integration/zendesk.png";
const helpscoutLogo = imageUrl+ "icon/ch/integration/helpscout.png";
const bigcommerceLogo = imageUrl+ "icon/ch/integration/bigcommerce.png";
const magentoLogo = imageUrl+ "icon/ch/integration/magento.png";
const nocrmLogo = imageUrl+ "icon/ch/integration/nocrm.png";
const freshsalesLogo = imageUrl+ "icon/ch/integration/freshsales.png";
const copperLogo = imageUrl+ "icon/ch/integration/copperCRM.png";
const hunterLogo = imageUrl+ "icon/ch/integration/hunterCRM.png";
const zendeskSellLogo = imageUrl+ "icon/ch/integration/zendeskSell.png";
const nutshellLogo = imageUrl+ "icon/ch/integration/nutshell.png";
const woocommerceLogo = imageUrl+ "icon/ch/integration/woocommerce.png";
const datanyzeLogo = imageUrl+ "icon/ch/integration/datanyze.png";
const confluenceLogo = imageUrl+ "icon/ch/integration/confluence.png";
const customerioLogo = imageUrl+ "icon/ch/integration/customerio.png";
const pipelinedealsLogo = imageUrl+ "icon/ch/integration/pipelinedeals.png";
const activecampaignLogo = imageUrl+ "icon/ch/integration/active_campaign.png";
const leadfeederLogo = imageUrl+ "icon/ch/integration/leadfeeder.png";
const sentryLogo = imageUrl+ "icon/ch/integration/sentry.png";
const sendinblueLogo = imageUrl+ "icon/ch/integration/sendinblue.png";
const surveymonkeyLogo = imageUrl+ "icon/ch/integration/SurveyMonkey.png";
const streakLogo = imageUrl+ "icon/ch/integration/streak.png";
const actLogo = imageUrl+ "icon/ch/integration/Act.png";
const insightlyLogo = imageUrl+ "icon/ch/integration/insightly.png";
const calendlyLogo = imageUrl+ "icon/ch/integration/calendly.png";
const zohomailLogo = imageUrl+ "icon/ch/integration/zohomail.svg";
const sendgridLogo = imageUrl+ "icon/ch/integration/sendgrid.png";
const shopboxLogo = imageUrl+ "icon/ch/integration/shopbox.png";
const sugarcrmLogo = imageUrl+ "icon/ch/integration/sugarcrm.png";
const podioLogo = imageUrl+ "icon/ch/integration/podio.png";
const odooLogo = imageUrl+ "icon/ch/integration/odoo.png";
const clearbitLogo = imageUrl+ "icon/ch/integration/clearbit.png";
const simplicateLogo = imageUrl+ "icon/ch/integration/simplicate.png";
const zohoanalyticsLogo = imageUrl+ "icon/ch/integration/zohoanalytics.png";
const freshserviceLogo = imageUrl+ "icon/ch/integration/freshservice.png";
const nimbleLogo = imageUrl+ "icon/ch/integration/nimble.png";
const salesflareLogo = imageUrl+ "icon/ch/integration/salesflare.png";
const apifyLogo = imageUrl+ "icon/ch/integration/apify.png";
const powerbiLogo = imageUrl+ "icon/ch/integration/powerbi.png";
const proteriaLogo = imageUrl+ "icon/ch/integration/proteria.png";
const nationbuilderLogo = imageUrl+ "icon/ch/integration/nationbuilder.png";
const servicenowLogo = imageUrl+ "icon/ch/integration/servicenow.png";
const sellsyLogo = imageUrl+ "icon/ch/integration/sellsy.png";
const ccvshopLogo = imageUrl+ "icon/ch/integration/ccvshop.png";
const asanaLogo = imageUrl+ "icon/ch/integration/asana.png";
const aztrixLogo = imageUrl+ "icon/ch/integration/aztrix.png";
const klaviyoLogo = imageUrl+ "icon/ch/integration/klaviyo.png";
const zuoraLogo = imageUrl+ "icon/ch/integration/zuora.png";
const rackbeatLogo = imageUrl+ "icon/ch/integration/rackbeat.png";
const zohoLogo = imageUrl+ "icon/ch/integration/zoho_dif.png";
const airtableLogo = imageUrl+ "icon/ch/integration/airtable.png";
const opencartLogo = imageUrl+ "icon/ch/integration/opencart.png";
const mailshakeLogo = imageUrl+ "icon/ch/integration/mailshake.png";
const outreachLogo = imageUrl+ "icon/ch/integration/outreach.png";
const frontIcon = imageUrl+ "icon/ch/integration/front.png";
const ontraIcon = imageUrl+ "icon/ch/integration/ontraport.jpg";
const synchroIcon = imageUrl+ "icon/ch/integration/synchroteam.png";
const kustomerIcon = imageUrl+ "icon/ch/integration/kustomer.png";
const onepageIcon = imageUrl+ "icon/ch/integration/onepagecrm.png";
const helpwiseIcon = imageUrl+ "icon/ch/integration/helpwise.png";
const gongIcon = imageUrl+ "icon/ch/integration/gong.jpg";
const enthuIcon = imageUrl+ "icon/ch/integration/enthu-ai.png";
const leadsbridgeIcon = imageUrl+ "icon/ch/integration/leadsBridge.png";
const refractIcon = imageUrl+ "icon/ch/integration/refract.png";
const talentLyftIcon = imageUrl+ "icon/ch/integration/talentlyft.png";
const greenhouseLogo = imageUrl+ "icon/ch/integration/greenhouse.png";
const constantcontactLogo = imageUrl+ "icon/ch/integration/constantcontact.png";
const leadsquaredLogo = imageUrl+ "icon/ch/integration/leadsquared.png";
const reamazeLogo = imageUrl+ "icon/ch/integration/reamaze.png";
const amoCRMicon = imageUrl+ "icon/ch/integration/amocrm.png";
const amoCRMLogo = imageUrl+ "amocrm.png";
const happyfoxLogo = imageUrl+ "icon/ch/integration/happyfox.jpg";
const gorgiasLogo = imageUrl+ "icon/ch/integration/logo-gorgias.svg";
const lemlistLogo = imageUrl+ "icon/ch/integration/lem_list.png";
const salesloftLogo = imageUrl+ "icon/ch/sales_loft.jpg";
const zohodeskLogo = imageUrl+ "icon/ch/integration/zohodesk.svg";
const wingmanLogo = imageUrl+ "icon/ch/integration/wingman.png";
const wingmanPopup = imageUrl+ "wingmanlogo.png";

const agilecrmLogo = imageUrl+ "icon/ch/integration/agileCRM.jpg";
const vtigerLogo = imageUrl+ "icon/ch/integration/vtiger.jpg";
const jobadderLogo = imageUrl+ "icon/ch/integration/jobadder.jpg";
const closeLogo = imageUrl+ "icon/ch/integration/close.jpg";
const piesyncLogo = imageUrl+ "icon/ch/integration/piesync.jpg";
const infusionsoftLogo = imageUrl+ "icon/ch/integration/infusionsoft.jpg";
const avomaLogo = imageUrl+ "icon/ch/integration/avoma.jpg";
const callinglyLogo = imageUrl+ "icon/ch/integration/callingly.jpg";
const diduenjoyLogo = imageUrl+ "icon/ch/integration/diduenjoy.jpg";
const flgLogo = imageUrl+ "icon/ch/integration/flg.jpg";
const planhatLogo = imageUrl+ "icon/ch/integration/planhat.jpg";
const postcallLogo = imageUrl+ "icon/ch/integration/postcall.jpg";

const kilterlyLogo = imageUrl+ "icon/ch/integration/kilterly.jpg";
const lessAnnoyingLogo = imageUrl+ "icon/ch/integration/lessAnnoying.jpg";
const engagebayLogo = imageUrl+ "icon/ch/integration/engagebay.jpg";
const mondayLogo = imageUrl+ "icon/ch/integration/monday.png";
const recruiteeLogo = imageUrl+ "icon/ch/integration/recruitee.jpg";
const salesmateLogo = imageUrl+ "icon/ch/integration/salesmate.jpg";
const leadsimpleLogo = imageUrl+ "icon/ch/integration/leadsimple.jpg";
const teamwaveLogo = imageUrl+ "icon/ch/integration/teamwave.jpg";
const segmentLogo = imageUrl+ "icon/ch/integration/segment.jpg";
const clozeLogo = imageUrl+ "icon/ch/integration/cloze.jpg";
const redtailLogo = imageUrl+ "icon/ch/integration/redtail.jpg";
const salesnexusLogo = imageUrl+ "icon/ch/integration/salesnexus.jpg";

const capsuleLogo = imageUrl+ "icon/ch/integration/capsule.jpg";
const liveagentLogo = imageUrl+ "icon/ch/integration/liveagent.jpg";
const klausLogo = imageUrl+ "icon/ch/integration/klaus.jpg";
const nicereplyLogo = imageUrl+ "icon/ch/integration/nicereply.jpg";
const punchalertLogo = imageUrl+ "icon/ch/integration/punchalert.jpg";
const whatsappbusinessIcon = imageUrl+ "icon/ch/integration/whatsappbusiness.jpg";
const skypeIcon = imageUrl+ "icon/ch/integration/skype.jpg";
const call2teamsIcon = imageUrl+ "icon/ch/integration/call2teams.jpg";
const solcallIcon = imageUrl+ "icon/ch/integration/solcall.jpg";
const microsoftteamsIcon = imageUrl+ "icon/ch/integration/microsoftteams.jpg";
const glipIcon = imageUrl+ "icon/ch/integration/glip.jpg";

const trelloLogo = imageUrl+ "icon/ch/integration/trello.jpg";
const office365Logo = imageUrl+ "icon/ch/integration/office365.jpg";
const netsuiteLogo = imageUrl+ "icon/ch/integration/netsuite.jpg";
const oracleLogo = imageUrl+ "icon/ch/integration/oracle.jpg";
const dialogflowLogo = imageUrl+ "icon/ch/integration/dialogflow.jpg";
const jiraLogo = imageUrl+ "icon/ch/jiraLogo.png";
const boxLogo = imageUrl+ "icon/ch/integration/box.jpg";
const dropboxLogo = imageUrl+ "icon/ch/integration/dropbox.jpg";
const googlecalendarLogo = imageUrl+ "icon/ch/integration/googlecalendar.jpg";
const yapiLogo = imageUrl+ "icon/ch/integration/yapi.jpeg"; //yapiii
const workatoLogo = imageUrl+ "icon/ch/integration/workato.jpg";

const cleanshelfLogo = imageUrl+ "icon/ch/integration/leanshelf.jpg";
const recruitbpmLogo = imageUrl+ "icon/ch/integration/recruitbpm.png"; //bpm
const xsellLogo = imageUrl+ "icon/ch/integration/xsell.jpg";
const githubLogo = imageUrl+ "icon/ch/integration/github.jpg";
const newrelicLogo = imageUrl+ "icon/ch/integration/newrelic.jpg";
const oncelogixLogo = imageUrl+ "icon/ch/integration/oncelogix.png"; //oncelogix
const bullhornLogo = imageUrl+ "icon/ch/integration/bullhorn.jpg";
const ripplingLogo = imageUrl+ "icon/ch/integration/rippling.jpg";
const canvasLogo = imageUrl+ "icon/ch/integration/canvas.jpg";
const openloyaltyLogo = imageUrl+ "icon/ch/integration/openloyalty.jpg";
const firefoxLogo = imageUrl+ "icon/ch/integration/firefox.jpg";

const pcrecruiterLogo = imageUrl+ "icon/ch/integration/pcrecruiter.jpg";
const mailchimpLogo = imageUrl+ "icon/ch/integration/mailchimp.jpg";
const marketoIcon = imageUrl+ "icon/ch/integration/marketo.jpg";
const outlookIcon = imageUrl+ "icon/ch/integration/outlook.jpg";
const scottynewsbotIcon = imageUrl+ "icon/ch/integration/scottynewsbot.jpg";
const pardotIcon = imageUrl+ "icon/ch/integration/Pardot.jpg";
const trayIcon = imageUrl+ "icon/ch/integration/tray.jpg";
const voxjarLogo = imageUrl+ "icon/ch/integration/voxjar.jpg";
const snaprecordingsLogo = imageUrl+ "icon/ch/integration/snaprecordings.jpg";
const alexaforBusinessLogo = imageUrl+ "icon/ch/integration/alexaforbusiness.png";
const ahaLogo = imageUrl+ "icon/ch/integration/aha.jpg";
const kaptureLogo = imageUrl+ "icon/ch/integration/kapture.png"; //alexa
const targetcrmLogo = imageUrl+ "icon/ch/integration/targetcrm.png";
const merittoLogo  = imageUrl+ "icon/ch/integration/meritto.png";
const zipteamsLogo  = imageUrl+ "icon/ch/integration/zipteams.png";
const snapLogo  = imageUrl+ "icon/ch/integration/snap_pe.png";
const edgecrmLogo = imageUrl+ "icon/ch/integration/edgecrm.png";
const maplecrmLogo = imageUrl+ "icon/ch/integration/maplecrm.png";
const we360Logo = imageUrl+ "icon/ch/integration/we360.png";
const jobitusLogo = imageUrl+ "icon/ch/integration/jobitus.png";


const webhooksLogo = imageUrl+ "icon/ch/integration/webhooks.png"; //webhook
const freshworkcrmLogo = imageUrl+ "icon/ch/integration/freshwork.jpg";
const goolgeanalyticsLogo = imageUrl+'googleanalytics.png';
const goolgeadsLogo = imageUrl+'googleads.png';
const chorusLogo = imageUrl+'chorus.jpeg';
const chorusPoppupLogo = imageUrl+'chorusPoppupLogo.jpeg';
const googleDriveIcon = imageUrl+'icon/ch/integration/googledrive.png';
const salesLoftLogo = imageUrl+ "icon/ch/salesloft.jpg";
const salesLoftIcon = imageUrl+ "icon/ch/salesloftIcon.jpg";
const outplayLogo = imageUrl+ "icon/ch/outplay.png";
const awsLogo= imageUrl+ "icon/ch/integration/awss3.png";
const awsS3Icon=imageUrl+ "icon/ch/integration/awsLogo.png"
//End Integraion Logo


var integrationList = {
    "gohighlevel": {
        "displayName": "GoHighLevel",
        "category" : "CRM",
        "name": "gohighlevel",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": `https://s3.amazonaws.com/cdn.hotglue.xyz/images/logos/gohighlevel.png`,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000082917",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": `https://s3.amazonaws.com/cdn.hotglue.xyz/images/logos/gohighlevel.png`,
            "name": "gohighlevel",
        },
        "btnText" : "INTEGRATED",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "microsoftTeams": {
        "displayName": "MicrosoftTeams",
        "name": "microsoftTeams",
        "category" : "Communication",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": microsoftteamsIcon,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000063368-microsoftteams-integration-setup-guide",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": microsoftteamsIcon,
            "name": "microsoftteams"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "monday": {
        "displayName": "monday.com",
        "category" : "CRM",
        "name": "monday",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": mondayLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000062717",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": mondayLogo,
            "name": "monday",
        },
        "btnText" : "INTEGRATED",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },"engagebay": {
        "displayName": "EngageBay",
        "category" : "CRM",
        "name": "engagebay",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": engagebayLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000062433",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": engagebayLogo,
            "name": "engagebay",
        },
        "btnText" : "INTEGRATED",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "zohoanalytics": {
        "displayName": "Zoho Analytics",
        "name": "zohoanalytics",
        "assignNumber": [],
        "category" : "Analytics",
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": zohoanalyticsLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000052244",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": zohoanalyticsLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "wingman": {
        "displayName": "Wingman",
        "category" : "Analytics",
        "name": "wingman",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": wingmanLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000061394",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": wingmanPopup,
            "name": "wingman",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "powerbi": {
        "displayName": "Microsoft Power Bi",
        "name": "powerbi",
        "assignNumber": [],
        "status": "integrate",
        "category" : "Analytics",
        "type": "native",
        "setting": true,
        "assignSetting": false,
        "iconSrc": powerbiLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000052250",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": powerbiLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "googleads": {
        "displayName": "Google Ads",
        "category" : "Marketing Automation",
        "name": "googleads",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": goolgeadsLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000061053-how-to-integrate-google-ads-with-callhippo",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": goolgeadsLogo,
            "name": "googleads",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "googledrive": {
        "displayName": "Google Drive",
        "category" : "Cloud Storage",
        "name": "googledrive",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": googleDriveIcon,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000072629",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": googleDriveIcon,
            "name": "googledrive",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "pipedrive": {
        "displayName": "Pipedrive",
        "category" : "CRM",
        "name": "pipedrive",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": pipedriveIcon,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000051055",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": pipedriveLogo,
            "name": "pipedrive",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "showNewSettingModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    "hubspot": {
        "displayName": "HubSpot",
        "name": "hubspot",
        "category" : "Marketing Automation",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": hubspotLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000026509-how-to-integrate-callhippo-with-hubspot-",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": hubspotpopup
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "showNewSettingModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    "zoho": {
        "displayName": "Zoho",
        "name": "zoho",
        "category" : "CRM",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": zohoIcon,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000002972-how-to-configure-my-zoho-crm-plus-integration-",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": zohoIcon,
            "name":"zoho"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "showNewSettingModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    "freshdesk": {
        "displayName": "Freshdesk",
        "category" : "HelpDesk",
        "name": "freshdesk",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": freshdeskLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000002975-how-to-integrate-callhippo-with-freshdesk-",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": freshdeskpopup
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    "talentlyft": {
        "displayName": "TalentLyft",
        "category" : "HelpDesk",
        "name": "talentlyft",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": talentLyftIcon,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000056144",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": talentLyftpopup
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "iconValue" : "check_circle"
    },
    "zendesk": {
        "displayName": "Zendesk",
        "category" : "HelpDesk",
        "name": "zendesk",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": zendeskLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000002973-how-to-integrate-callhippo-with-zendesk-",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": zendeskpopup
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true  
    }, 
    "phoneBridge": {
        "displayName": "Zoho PhoneBridge",
        "name": "phoneBridge",
        "category" : "Communication",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": phoneBridgeIcon,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000064751",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": phoneBridgeIcon,
            "name":"phoneBridge"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    "groove": {
        "displayName": "Groove",
        "name": "groove",
        "category" : "HelpDesk",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": grooveLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000002971-how-to-configure-my-groove-integration-with-callhippo-",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": groovepopup
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "salesforce": {
        "displayName": "Salesforce",
        "name": "salesforce",
        "category" : "CRM",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": salesforceIcon,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000080488",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": salesforceIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    "intercom": {
        "displayName": "Intercom",
        "name": "intercom",
        "category" : "Marketing Automation",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": intercomIcon,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000004244-how-to-integrate-callhippo-with-intercom-",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": intercompopup
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    "leadsquared": {
        "displayName": "Leadsquared",
        "name": "leadsquared",
        "category" : "Marketing Automation",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": leadsquaredLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000057982",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": leadsquaredLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "showNewSettingModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    "ontraport": {
        "displayName": "Ontraport",
        "name": "ontraport",
        "category" : "Marketing Automation",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": ontraPortLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000062008",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": ontraPortLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "zapier": {
        "displayName": "Zapier",
        "name": "zapier",
        "category" : "other",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": zapierLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000017065-how-to-configure-my-zapier-integration-",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": zapierpopup
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    "microsoftdynamics": {
        "displayName": "Microsoft Dynamics",
        "name": "microsoftdynamics",
        "category" : "CRM",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": microsoftDynamicsIcon,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000051053",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": microsoftDynamicsIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    "shopify": {
        "displayName": "Shopify",
        "name": "shopify",
        "category" : "E-commerce",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": shopifyIcon,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000051054",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": shopifyIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    "helpscout": {
        "displayName": "Help Scout",
        "name": "helpscout",
        "assignNumber": [],
        "status": "integrate",
        "category" : "HelpDesk",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": helpscoutLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000051530",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": helpscoutLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    "reamaze": {
        "displayName": "Reamaze",
        "category" : "HelpDesk",
        "name": "reamaze",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": reamazeLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000058879",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": reamazeLogo,
            "name": "reamaze",
        },
        "btnText" : "INTEGRATED",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "klaus": {
        "displayName": "Klaus",
        "category" : "HelpDesk",
        "name": "klaus",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": klausLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000062006",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": klausLogo,
            "name": "klaus",
        },
        "btnText" : "INTEGRATED",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "gorgias": {
        "displayName": "Gorgias",
        "category" : "HelpDesk",
        "name": "gorgias",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": gorgiasLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000062437",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": gorgiasLogo,
            "name": "gorgias",
        },
        "btnText" : "INTEGRATED",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "gorgiaslogowidth"
    },
    "front":{
        "displayName": "Front",
        "category" : "HelpDesk",
        "name": "front",
        "iconSrc": frontIcon,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000061812",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "popupData": {
            "logo": frontIcon,
            "name": "front",
        },
        "btnText" : "INTEGRATED",
    "showSettingModal": false,
    "showDetailModal" : false,
    "iconValue" : "check_circle"
},
"zohodesk": {
    "displayName": "Zoho Desk",
    "category" : "HelpDesk",
    "name": "zohodesk",
    "assignNumber": [],
    "status": "integrate",
    "type": "native",
    "setting": true,
    "assignSetting": true,
    "iconSrc": zohodeskLogo,
    "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000075662",
    "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
    "popupData": {
        "logo": zohodeskLogo,
        "name": "zohodesk",
    },
    "btnText" : "Integrated",
    "showSettingModal": false,
    "showDetailModal" : false,
    "iconValue" : "check_circle"
},
"integromat":{
    "displayName": "Make",
    "category" : "other",
    "name": "integromat",
    "assignNumber": [],
    "status": "integrate",
    "type": "native",
    "setting": true,
    "assignSetting": true,
    "iconSrc": integromatIcon,
    "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000060249",
    "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
    "popupData": {
        "logo": integromatIcon,
        "name": "integromat",
    },
    "btnText" : "Integrated",
    "showSettingModal": false,
    "showDetailModal" : false,
    "iconValue" : "check_circle"
},
    "bigcommerce": {
        "displayName": "Bigcommerce",
        "name": "bigcommerce",
        "category" : "E-commerce",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": true,
        "iconSrc": bigcommerceLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": bigcommerceLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "magento": {
        "displayName": "Magento 2",
        "name": "magento",
        "assignNumber": [],
        "category" : "E-commerce",
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": true,
        "iconSrc": magentoLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": magentoLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "nocrm": {
        "displayName": "noCRM",
        "name": "nocrm",
        "assignNumber": [],
        "category" : "CRM",
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": nocrmLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000081446",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": nocrmLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    // "freshsales": {
    //     "displayName": "FreshSales",
    //     "name": "freshsales",
    //     "assignNumber": [],
    //     "status": "integrate",
    //     "category" : "CRM",
    //     "type": "blendr",
    //     "setting": true,
    //     "assignSetting": true,
    //     "iconSrc": freshsalesLogo,
    //     "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000051531",
    //     "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
    //     "popupData": {
    //         "logo": freshsalesLogo
    //     },
    //     "btnText" : "Integrated",
    //     "showSettingModal": false,
    //     "showDetailModal" : false,
    //     "iconValue" : "check_circle",
    //     "isPopular" : true
    // },
    "copper": {
        "displayName": "Copper",
        "name": "copper",
        "category" : "CRM",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": copperLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000051532",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": copperLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "hunter": {
        "displayName": "Hunter",
        "name": "hunter",
        "category" : "CRM",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": true,
        "iconSrc": hunterLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": hunterLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "amoCRM": {
        "displayName": "Kommo",
        "category" : "CRM",
        "name": "amoCRM",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": amoCRMicon,
        "setUpGuide":"https://support.callhippo.com/support/solutions/articles/10000061012",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": amoCRMLogo,
            "name": "amoCRM",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    "bitrix24": {
        "displayName": "Bitrix 24",
        "category" : "CRM",
        "hidden" : false,
        "name": "bitrix24",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": bitrix24Logo,
        "setUpGuide":"https://support.callhippo.com/support/solutions/articles/10000071664-bitrix24-callhippo-integration",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": bitrix24Logo,
            "name": "bitrix24",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "salesloft": {
        "displayName": "SalesLoft",
        "category" : "CRM",
        "name": "salesloft",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": salesLoftIcon,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000080721",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": salesLoftLogo,
            "name": "salesloft",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "showNewSettingModal" : false,
        "iconValue" : "check_circle",
        
    },
    "kylas": {
        "displayName": "Kylas",
        "category" : "CRM",
        "name": "kylas",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": kylasLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000078907",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": kylasLogo,
            "name": "kylas",
        },
        "btnText" : "INTEGRATED",
        "showSettingModal": false,
       "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120",
       
    },


    "sugarcrm": {
        "displayName": "SugarCRM",
        "name": "sugarcrm",
        "assignNumber": [],
        "status": "integrate",
        "category" : "CRM",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": sugarcrmLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000051809",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": sugarcrmLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "podio": {
        "displayName": "Podio",
        "name": "podio",
        "assignNumber": [],
        "status": "integrate",
        "category" : "CRM",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": podioLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000051800",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": podioLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "activecampaign": {
        "displayName": "ActiveCampaign",
        "name": "activecampaign",
        "assignNumber": [],
        "status": "integrate",
        "category" : "CRM",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": activecampaignLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000051861",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": activecampaignLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    "outreach": {
        "displayName": "Outreach",
        "name": "outreach",
        "assignNumber": [],
        "category" : "CRM",
        "status": "request",
        "setting": true,
        "assignSetting": true,
        "iconSrc": outreachLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": outreachLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    
    "nutshell": {
        "displayName": "Nutshell",
        "name": "nutshell",
        "category" : "CRM",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": nutshellLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000051856",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": nutshellLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "nimble": {
        "displayName": "Nimble",
        "name": "nimble",
        "assignNumber": [],
        "category" : "CRM",
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": true,
        "iconSrc": nimbleLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": nimbleLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "nationbuilder": {
        "displayName": "NationBuilder",
        "name": "nationbuilder",
        "assignNumber": [],
        "category" : "CRM",
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": true,
        "iconSrc": nationbuilderLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": nationbuilderLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "insightly": {
        "displayName": "Insightly",
        "name": "insightly",
        "category" : "CRM",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": true,
        "iconSrc": insightlyLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": insightlyLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    }, 
    "greenhouse": {
        "displayName": "Greenhouse",
        "name": "greenhouse",
        "category" : "CRM",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": true,
        "iconSrc": greenhouseLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": greenhouseLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "freshworkcrm": {
        "displayName": "FreshWork CRM",
        "name": "freshworkcrm",
        "assignNumber": [],
        "status": "integrate",
        "category" : "CRM",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": freshworkcrmLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000074443-how-to-integrate-freshwork-with-callhippo-",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": freshworkcrmLogo,
            "name": "freshworkcrm",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "lessAnnoying": {
        "displayName": "Less Annoying CRM",
        "category" : "CRM",
        "name": "lessAnnoying",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": lessAnnoyingIcon,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000069986",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": lessAnnoyingIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "keap": {
        "displayName": "Keap",
        "category" : "CRM",
        "name": "keap",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": infusionsoftLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000072722-how-to-integrate-keap-with-callhippo",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": keapIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "zendesksell": {
        "displayName": "Zendesk Sell",
        "name": "zendesksell",
        "category" : "CRM",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": zendeskSellLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": zendeskSellLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "woocommerce": {
        "displayName": "Woo Commerce",
        "name": "woocommerce",
        "category" : "E-commerce",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": woocommerceLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000051830",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": woocommerceLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    "datanyze": {
        "displayName": "Datanyze",
        "name": "datanyze",
        "assignNumber": [],
        "category" : "Marketing Automation",
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": datanyzeLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": datanyzeLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "salesforcemc": {
        "displayName": "Salesforce Marketing Cloud",
        "name": "salesforcemc",
        "category" : "Marketing Automation",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": salesforceIcon,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": salesforceIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "calendly": {
        "displayName": "Calendly",
        "name": "calendly",
        "category" : "Productivity",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": calendlyLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": calendlyLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    "asana": {
        "displayName": "Asana",
        "name": "asana",
        "category" : "Productivity",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": asanaLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000052656",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": asanaLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    "airtable": {
        "displayName": "Airtable",
        "name": "airtable",
        "assignNumber": [],
        "status": "integrate",
        "category" : "Productivity",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": airtableLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000052657",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": airtableLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "jira": {
        "displayName": "Jira",
        "name": "jira",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": jiraLogo,
        // "setUpGuide": "#",
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000079772",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": jiraLogo,
            "name": "jira"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "confluence": {
        "displayName": "Confluence",
        "name": "confluence",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": confluenceLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": confluenceLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "klaviyo": {
        "displayName": "Klaviyo",
        "name": "klaviyo",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "category" : "Mailings",
        "setting": true,
        "assignSetting": false,
        "iconSrc": klaviyoLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000052659",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": klaviyoLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    "constantcontact": {
        "displayName": "Constant Contact",
        "name": "constantcontact",
        "category" : "Mailings",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": constantcontactLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": constantcontactLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "customerio": {
        "displayName": "Customerio",
        "name": "customerio",
        "assignNumber": [],
        "status": "request",
        "category" : "Mailings",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": customerioLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": customerioLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "pipelinedeals": {
        "displayName": "Pipeline Deals",
        "name": "pipelinedeals",
        "assignNumber": [],
        "category" : "CRM",
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": pipelinedealsLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": pipelinedealsLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    
    "leadfeeder": {
        "displayName": "Leadfeeder",
        "name": "leadfeeder",
        "assignNumber": [],
        "status": "request",
        "category" : "Analytics",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": leadfeederLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": leadfeederLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "sentry": {
        "displayName": "Sentry",
        "name": "sentry",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "category" : "HelpDesk",
        "setting": true,
        "assignSetting": false,
        "iconSrc": sentryLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": sentryLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "sendinblue": {
        "displayName": "Sendinblue",
        "name": "sendinblue",
        "assignNumber": [],
        "category" : "Marketing Automation",
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": sendinblueLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": sendinblueLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "surveymonkey": {
        "displayName": "SurveyMonkey",
        "name": "surveymonkey",
        "category" : "Productivity",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": surveymonkeyLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": surveymonkeyLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "streak": {
        "displayName": "Streak",
        "name": "streak",
        "assignNumber": [],
        "status": "request",
        "category" : "CRM",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": streakLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": streakLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "act": {
        "displayName": "Act! 365",
        "name": "act",
        "assignNumber": [],
        "category" : "CRM",
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": actLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": actLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    
    "sendgrid": {
        "displayName": "SendGrid",
        "name": "sendgrid",
        "assignNumber": [],
        "status": "request",
        "category" : "Mailings",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": sendgridLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": sendgridLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "shopbox": {
        "displayName": "Shopbox",
        "name": "shopbox",
        "category" : "E-commerce",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": shopboxLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": shopboxLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "zohorecruit": {
        "displayName": "Zoho Recruit",
        "name": "zohorecruit",
        "category" : "Recruitment",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": zohorecruitIcon,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000056145",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": zohorecruitIcon,
            "name":"zoho"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "iconValue" : "check_circle"
    },
    // commented as we are not using blender anymore
    // "odoo": {
    //     "displayName": "Odoo",
    //     "name": "odoo",
    //     "assignNumber": [],
    //     "status": "integrate",
    //     "category" : "ERP",
    //     "type": "blendr",
    //     "setting": true,
    //     "assignSetting": false,
    //     "iconSrc": odooLogo,
    //     "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000052254",
    //     "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
    //     "popupData": {
    //         "logo": odooLogo
    //     },
    //     "btnText" : "Integrated",
    //     "showSettingModal": false,
    //     "showDetailModal" : false,
    //     "iconValue" : "check_circle",
    //     "isPopular" : true
    // },
    "clearbit": {
        "displayName": "Clearbit",
        "name": "clearbit",
        "assignNumber": [],
        "status": "request",
        "category" : "Data Enrichment",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": clearbitLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": clearbitLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "salesforcesoap": {
        "displayName": "Salesforce Soap",
        "name": "salesforcesoap",
        "category" : "CRM",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": salesforceIcon,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": salesforceIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "simplicate": {
        "displayName": "Simplicate",
        "name": "simplicate",
        "assignNumber": [],
        "category" : "CRM",
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": simplicateLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": simplicateLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "freshservice": {
        "displayName": "Freshservice",
        "name": "freshservice",
        "category" : "HelpDesk",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": freshserviceLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": freshserviceLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    
    "salesflare": {
        "displayName": "Salesflare",
        "name": "salesflare",
        "assignNumber": [],
        "status": "request",
        "category" : "CRM",
        "type": "blendr",
        "setting": true,
        "assignSetting": true,
        "iconSrc": salesflareLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": salesflareLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "apify": {
        "displayName": "Apify",
        "name": "apify",
        "assignNumber": [],
        "status": "request",
        "category" : "Data Enrichment",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": apifyLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": apifyLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "proteria": {
        "displayName": "Proteria",
        "name": "proteria",
        "assignNumber": [],
        "status": "request",
        "category" : "ERP",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": proteriaLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": proteriaLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "servicenow": {
        "displayName": "Service Now",
        "name": "servicenow",
        "assignNumber": [],
        "category" : "HelpDesk",
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": servicenowLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": servicenowLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "sellsy": {
        "displayName": "Sellsy",
        "name": "sellsy",
        "category" : "CRM",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": sellsyLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": sellsyLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "ccvshop": {
        "displayName": "Ccvshop",
        "name": "ccvshop",
        "assignNumber": [],
        "category" : "E-commerce",
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": ccvshopLogo,
        // "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000052654",
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": ccvshopLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "aztrix": {
        "displayName": "Aztrix*",
        "name": "aztrix",
        "assignNumber": [],
        "status": "request",
        "category" : "Productivity",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": aztrixLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": aztrixLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "zuora": {
        "displayName": "Zuora",
        "name": "zuora",
        "category" : "Marketing Automation",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": zuoraLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": zuoraLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "rackbeat": {
        "displayName": "Rackbeat",
        "name": "rackbeat",
        "assignNumber": [],
        "category" : "ERP",
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": rackbeatLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": rackbeatLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "opencart": {
        "displayName": "OpenCart",
        "name": "opencart",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "category" : "E-commerce",
        "setting": true,
        "assignSetting": false,
        "iconSrc": opencartLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": opencartLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "mailshake": {
        "displayName": "Mailshake",
        "name": "mailshake",
        "category" : "Mailings",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": mailshakeLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": mailshakeLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    
    "custom": {
        "displayName": "Webhook",
        "name": "custom",
        "category" : "Webhook",
        "assignNumber": [],
        "status": "integrate",
        "type": "custom",
        "setting": true,
        "assignSetting": true,
        "iconSrc": customIntegrationIcon,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000002969-how-to-integrate-callhippo-with-custom-application-custom-integration-",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": customIntegrationIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "slack": {
        "displayName": "Slack",
        "name": "slack",
        "category" : "Communication",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": slackIcon,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000058489",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": slackIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    "whatsappbusiness": {
        "displayName": "Whatsapp Business",
        "name": "whatsappbusiness",
        "category" : "Communication",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": whatsappbusinessIcon,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": whatsappbusinessIcon,
            "name": "whatsappbusiness"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "zohomail": {
        "displayName": "Zoho Mail",
        "name": "zohomail",
        "assignNumber": [],
        "category" : "Mailings",
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": zohomailLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": zohomailLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "sso": {
        "displayName": "SSO Login",
        "name": "sso",
        "category" : "SSO",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": ssologinLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000073962-one-login-sso-integration",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": ssologinLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "showNewSettingModal" : false,
        "iconValue" : "check_circle"
    },
    "synchroteam": {
        "displayName": "Synchroteam",
        "name": "synchroteam",
        "category" : "Productivity",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": false,
        "iconSrc": synchroIcon,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": synchroIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "kustomer": {
        "displayName": "Kustomer",
        "name": "kustomer",
        "category" : "CRM",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": false,
        "iconSrc": kustomerIcon,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": kustomerIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "onepage": {
        "displayName": "OnePageCRM",
        "name": "onepage",
        "category" : "CRM",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": false,
        "iconSrc": onepageIcon,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": onepageIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "helpwise": {
        "displayName": "Helpwise",
        "name": "helpwise",
        "category" : "Communication",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": false,
        "iconSrc": helpwiseIcon,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": helpwiseIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "gong": {
        "displayName": "Gong",
        "name": "gong",
        "category" : "CRM",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": false,
        "iconSrc": gongIcon,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": gongIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "leadsbridge": {
        "displayName": "LeadsBridge",
        "name": "leadsbridge",
        "category" : "CRM",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": false,
        "iconSrc": leadsbridgeIcon,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": leadsbridgeIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "typeform": {
        "displayName": "Typeform",
        "name": "typeform",
        "category" : "other",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": typeFormIcon,
        // "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000058484",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": typeFormIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "refract": {
        "displayName": "Refract",
        "name": "refract",
        "category" : "other",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": false,
        "iconSrc": refractIcon,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": refractIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    
    "odoo": {
        "displayName": "Odoo",
        "category" : "ERP",
        "name": "odoo",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": odooIcon,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000052254-how-to-integrate-odoo-with-callhippo-",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": odooIcon,
            "name": "odoo",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    "happyFox": {
        "displayName": "HappyFox",
        "category" : "HelpDesk",
        "name": "happyFox",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": happyfoxLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": happyfoxLogo,
            "name": "happyFox",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "lemlist": {
        "displayName": "Lemlist",
        "category" : "Sales Automation",
        "name": "lemlist",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": lemlistLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": lemlistLogo,
            "name": "lemlist",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "zohologowidth"
    },
    
    "agilecrm": {
        "displayName": "Agile CRM",
        "category" : "CRM",
        "name": "agilecrm",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": agilecrmLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": agilecrmLogo,
            "name": "agilecrm",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "vtiger": {
        "displayName": "VTiger",
        "category" : "CRM",
        "name": "vtiger",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": vtigerLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": vtigerLogo,
            "name": "vtiger",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "jobadder": {
        "displayName": "Jobadder",
        "category" : "CRM",
        "name": "jobadder",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": jobadderLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": jobadderLogo,
            "name": "jobadder",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "close": {
        "displayName": "Close",
        "category" : "CRM",
        "name": "close",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": closeLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": closeLogo,
            "name": "close",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "piesync": {
        "displayName": "Pie Sync",
        "category" : "CRM",
        "name": "piesync",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": piesyncLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": piesyncLogo,
            "name": "piesync",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "avoma": {
        "displayName": "Avoma",
        "category" : "CRM",
        "name": "avoma",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": avomaLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": avomaLogo,
            "name": "avoma",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "callingly": {
        "displayName": "Callingly",
        "category" : "CRM",
        "name": "callingly",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": callinglyLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": callinglyLogo,
            "name": "callingly",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "diduenjoy": {
        "displayName": "Diduenjoy",
        "category" : "CRM",
        "name": "diduenjoy",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": diduenjoyLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": diduenjoyLogo,
            "name": "diduenjoy",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "flg": {
        "displayName": "FLG",
        "category" : "CRM",
        "name": "flg",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": true,
        "iconSrc": flgLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": flgLogo,
            "name": "flg",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "planhat": {
        "displayName": "Planhat",
        "category" : "CRM",
        "name": "planhat",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": planhatLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": planhatLogo,
            "name": "planhat",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "postcall": {
        "displayName": "Postcall",
        "category" : "CRM",
        "name": "postcall",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": true,
        "iconSrc": postcallLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": postcallLogo,
            "name": "postcall",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "kilterly": {
        "displayName": "Kilterly",
        "category" : "CRM",
        "name": "kilterly",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": kilterlyLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": kilterlyLogo,
            "name": "kilterly",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "recruitee": {
        "displayName": "Recruitee",
        "category" : "CRM",
        "name": "recruitee",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": recruiteeLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": recruiteeLogo,
            "name": "recruitee",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "salesmate": {
        "displayName": "Salesmate",
        "category" : "CRM",
        "name": "salesmate",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": salesmateLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": salesmateLogo,
            "name": "salesmate",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "leadsimple": {
        "displayName": "LeadSimple",
        "category" : "CRM",
        "name": "leadsimple",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": leadsimpleLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": leadsimpleLogo,
            "name": "leadsimple",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "teamwave": {
        "displayName": "TeamWave",
        "category" : "CRM",
        "name": "teamwave",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": teamwaveLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": teamwaveLogo,
            "name": "teamwave",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "segment": {
        "displayName": "Segment",
        "category" : "CRM",
        "name": "segment",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": segmentLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": segmentLogo,
            "name": "segment",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "cloze": {
        "displayName": "Cloze",
        "category" : "CRM",
        "name": "cloze",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": clozeLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": clozeLogo,
            "name": "cloze",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "redtail": {
        "displayName": "Redtail",
        "category" : "CRM",
        "name": "redtail",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": redtailLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": redtailLogo,
            "name": "redtail",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "salesnexus": {
        "displayName": "SalesNexus",
        "category" : "CRM",
        "name": "salesnexus",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": salesnexusLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": salesnexusLogo,
            "name": "salesnexus",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "capsule": {
        "displayName": "Capsule",
        "category" : "CRM",
        "name": "capsule",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": capsuleLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": capsuleLogo,
            "name": "capsule",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "liveagent": {
        "displayName": "LiveAgent",
        "category" : "HelpDesk",
        "name": "liveagent",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": liveagentLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": liveagentLogo,
            "name": "liveagent",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "nicereply": {
        "displayName": "Nicereply",
        "category" : "HelpDesk",
        "name": "nicereply",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": nicereplyLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": nicereplyLogo,
            "name": "nicereply",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        // "className" : "zohologowidth"
    },
    "punchalert": {
        "displayName": "PunchAlert",
        "category" : "HelpDesk",
        "name": "punchalert",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": punchalertLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": punchalertLogo,
            "name": "punchalert",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        // "className" : "zohologowidth"
    },
    "skype": {
        "displayName": "Skype",
        "name": "skype",
        "category" : "Communication",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": skypeIcon,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": skypeIcon,
            "name": "skype"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "call2teams": {
        "displayName": "Call2Teams",
        "name": "call2teams",
        "category" : "Communication",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": call2teamsIcon,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": call2teamsIcon,
            "name": "call2teams"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "solcall": {
        "displayName": "Solcall",
        "name": "solcall",
        "category" : "other",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": solcallIcon,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": solcallIcon,
            "name": "solcall"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "glip": {
        "displayName": "Glip",
        "name": "glip",
        "category" : "Communication",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": glipIcon,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": glipIcon,
            "name": "glip"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "trello": {
        "displayName": "Trello",
        "name": "trello",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": trelloLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": trelloLogo,
            "name": "trello"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "office365": {
        "displayName": "Office 365",
        "name": "office365",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": office365Logo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": office365Logo,
            "name": "office365"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "oracle": {
        "displayName": "Oracle",
        "name": "oracle",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": oracleLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": oracleLogo,
            "name": "oracle"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "dialogflow": {
        "displayName": "Dialogflow",
        "name": "dialogflow",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": dialogflowLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": dialogflowLogo,
            "name": "dialogflow"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
   
    "box": {
        "displayName": "Box",
        "name": "box",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": boxLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": boxLogo,
            "name": "box"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "dropbox": {
        "displayName": "Dropbox",
        "name": "dropbox",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": dropboxLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": dropboxLogo,
            "name": "dropbox"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "googlecalendar": {
        "displayName": "Google Calendar",
        "name": "googlecalendar",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": googlecalendarLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": googlecalendarLogo,
            "name": "googlecalendar"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "netsuite": {
        "displayName": "NetSuite",
        "name": "netsuite",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": netsuiteLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": netsuiteLogo,
            "name": "netsuite"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "yapi": {
        "displayName": "Yapi",
        "name": "yapi",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "request",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": yapiLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": yapiLogo,
            "name": "yapi"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "workato": {
        "displayName": "Workato",
        "name": "workato",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": workatoLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": workatoLogo,
            "name": "workato"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "cleanshelf": {
        "displayName": "Cleanshelf",
        "name": "cleanshelf",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": cleanshelfLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": cleanshelfLogo,
            "name": "cleanshelf"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "recruitbpm": {
        "displayName": "RecruitBPM",
        "name": "recruitbpm",
        "assignNumber": [],
        "category" : "Recruitment",
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": recruitbpmLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": recruitbpmLogo,
            "name": "recruitbpm"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "xsell": {
        "displayName": "XSELL",
        "name": "xsell",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": xsellLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": xsellLogo,
            "name": "xsell"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "github": {
        "displayName": "GitHub",
        "name": "github",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": githubLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": githubLogo,
            "name": "github"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "newrelic": {
        "displayName": "New Relic",
        "name": "newrelic",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": newrelicLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": newrelicLogo,
            "name": "newrelic"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "oncelogix": {
        "displayName": "OnceLogix",
        "name": "oncelogix",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": oncelogixLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": oncelogixLogo,
            "name": "oncelogix"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "bullhorn": {
        "displayName": "Bullhorn",
        "name": "bullhorn",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": false,
        "iconSrc": bullhornLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000088174",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": bullhornLogo,
            "name": "bullhorn"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "rippling": {
        "displayName": "Rippling",
        "name": "rippling",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": ripplingLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": ripplingLogo,
            "name": "rippling"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "canvas": {
        "displayName": "Canvas LMS",
        "name": "canvas",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": canvasLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": canvasLogo,
            "name": "canvas"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "openloyalty": {
        "displayName": "Open Loyalty",
        "name": "openloyalty",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": openloyaltyLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": openloyaltyLogo,
            "name": "openloyalty"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "firefox": {
        "displayName": "Firefox",
        "name": "firefox",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": firefoxLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": firefoxLogo,
            "name": "firefox"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "pcrecruiter": {
        "displayName": "PCRecruiter",
        "name": "pcrecruiter",
        "assignNumber": [],
        "category" : "Productivity",
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": pcrecruiterLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": pcrecruiterLogo,
            "name": "pcrecruiter"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "mailchimp": {
        "displayName": "Mailchimp",
        "name": "mailchimp",
        "assignNumber": [],
        "status": "request",
        "category" : "Mailings",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": mailchimpLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": mailchimpLogo,
            "name": "mailchimp"
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "marketo": {
        "displayName": "Marketo",
        "name": "marketo",
        "category" : "Marketing Automation",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": false,
        "iconSrc": marketoIcon,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": marketoIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "outlook": {
        "displayName": "Outlook",
        "name": "outlook",
        "category" : "Marketing Automation",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": false,
        "iconSrc": outlookIcon,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": outlookIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "scottynewsbot": {
        "displayName": "ScottyNewsBot",
        "name": "scottynewsbot",
        "category" : "Marketing Automation",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": false,
        "iconSrc": scottynewsbotIcon,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": scottynewsbotIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "pardot": {
        "displayName": "Pardot",
        "name": "pardot",
        "category" : "Marketing Automation",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": false,
        "iconSrc": pardotIcon,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": pardotIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "tray": {
        "displayName": "Tray",
        "name": "tray",
        "category" : "Marketing Automation",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": false,
        "iconSrc": trayIcon,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": trayIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "outplay": {
        "displayName": "Outplay",
        "name": "outplay",
        "assignNumber": [],
        "status": "integrate",
        "category" : "Marketing Automation",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": outplayLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000080391",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": outplayLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "isPopular" : true
    },
    "enthuai": {
        "displayName": "Enthu.ai",
        "name": "enthuai",
        "category" : "Analytics",
        "assignNumber": [],
        "status": "request",
        "type": "blendr",
        "setting": false,
        "iconSrc": enthuIcon,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": enthuIcon
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "voxjar": {
        "displayName": "Voxjar",
        "name": "voxjar",
        "assignNumber": [],
        "status": "request",
        "category" : "Analytics",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": voxjarLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": voxjarLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "snaprecordings": {
        "displayName": "Snap Recordings",
        "name": "snaprecordings",
        "assignNumber": [],
        "status": "request",
        "category" : "Analytics",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": snaprecordingsLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": snaprecordingsLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
        "googleanalytics": {
        "displayName": "Google Analytics",
        "category" : "Analytics",
        "name": "googleanalytics",
        "assignNumber": [],
        "status": "request",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": goolgeanalyticsLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": goolgeanalyticsLogo,
            "name": "googleanalytics",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "aha": {
        "displayName": "Aha",
        "name": "aha",
        "assignNumber": [],
        "status": "request",
        "category" : "Entertainment",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": ahaLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": ahaLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "alexaforBusiness": {
        "displayName": "Alexa for Business",
        "name": "alexaforBusiness",
        "assignNumber": [],
        "status": "request",
        "category" : "Data Enrichment",
        "type": "beta",
        "setting": true,
        "assignSetting": false,
        "iconSrc": alexaforBusinessLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": alexaforBusinessLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "webhooks": {
        "displayName": "Webhooks",
        "name": "webhooks",
        "assignNumber": [],
        "status": "request",
        "category" : "Data Enrichment",
        "type": "blendr",
        "setting": true,
        "assignSetting": false,
        "iconSrc": webhooksLogo,
        "setUpGuide": "#",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": webhooksLogo
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "chorus": {
        "displayName": "Chorus",
        "category" : "Analytics",
        "name": "chorus",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": chorusLogo,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000065883",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": chorusPoppupLogo,
            "name": "chorus",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle"
    },
    "aws_s3": {
        "displayName": "Amazon S3",
        "category" : "Cloud Storage",
        "name": "aws_s3",
        "assignNumber": [],
        "status": "integrate",
        "type": "native",
        "setting": true,
        "assignSetting": true,
        "iconSrc": awsS3Icon,
        "setUpGuide": "https://support.callhippo.com/support/solutions/articles/10000081110",
        "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
        "popupData": {
            "logo": awsLogo,
            "name": "aws_s3",
        },
        "btnText" : "INTEGRATED",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "kapture": {
        "displayName": "Kapture",
        "category" : "Communication",
        "name": "kapture",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": kaptureLogo,
        "setUpGuide": "#",
        "getHelp": "#",
        "popupData": {
            "logo": kaptureLogo,
            "name": "capsule",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "targetCRM": {
        "displayName": "TargetCRM",
        "category" : "CRM",
        "name": "targetCRM",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": targetcrmLogo,
        "setUpGuide": "#",
        "getHelp": "#",
        "popupData": {
            "logo": targetcrmLogo,
            "name": "capsule",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    
    "meritto": {
        "displayName": "Meritto",
        "category" : "Productivity",
        "name": "meritto",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": merittoLogo,
        "setUpGuide": "#",
        "getHelp": "#",
        "popupData": {
            "logo": merittoLogo,
            "name": "capsule",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "zipteams": {
        "displayName": "Zipteams",
        "category" : "Productivity",
        "name": "zipteams",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": zipteamsLogo,
        "setUpGuide": "#",
        "getHelp": "#",
        "popupData": {
            "logo": zipteamsLogo,
            "name": "capsule",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "snap.pe": {
        "displayName": "Snap.pe",
        "category" : "CRM",
        "name": "snap.pe",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": snapLogo,
        "setUpGuide": "#",
        "getHelp": "#",
        "popupData": {
            "logo": snapLogo,
            "name": "capsule",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "edgecrm": {
        "displayName": "Edge CRM",
        "category" : "CRM",
        "name": "edgecrm",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": edgecrmLogo,
        "setUpGuide": "#",
        "getHelp": "#",
        "popupData": {
            "logo": edgecrmLogo,
            "name": "capsule",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "maplecrm": {
        "displayName": "Maple CRM",
        "category" : "CRM",
        "name": "maplecrm",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": maplecrmLogo,
        "setUpGuide": "#",
        "getHelp": "#",
        "popupData": {
            "logo": maplecrmLogo,
            "name": "maplecrm",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "jobitus": {
        "displayName": "Jobitus",
        "category" : "Productivity",
        "name": "jobitus",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": jobitusLogo,
        "setUpGuide": "#",
        "getHelp": "#",
        "popupData": {
            "logo": jobitusLogo,
            "name": "capsule",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    "we360": {
        "displayName": "We360",
        "category" : "ERP",
        "name": "we360",
        "assignNumber": [],
        "status": "request",
        "type": "beta",
        "setting": true,
        "assignSetting": true,
        "iconSrc": we360Logo,
        "setUpGuide": "#",
        "getHelp": "#",
        "popupData": {
            "logo": we360Logo,
            "name": "capsule",
        },
        "btnText" : "Integrated",
        "showSettingModal": false,
        "showDetailModal" : false,
        "iconValue" : "check_circle",
        "className" : "width120"
    },
    
         
        

} 


export default integrationList;