import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const isoModal = ComponentName => WithDirection(styled(ComponentName)`
&.ant-modal{
  top: 24px;
}

.modalDisabled{
  pointer-events: none;
}

.pricingpopup .ant-modal-title {
  text-align: center;
  font-size: 28px;
}

.planpopupwrapper .ant-row:after {
  display: block;
}

.plancard .ant-card-head {
  border-bottom: 0px;
}

.plancard .ant-card-head-title {
  font-weight: 700;
  font-size: 22px;
  padding: 16px 0px 0;
  padding-bottom: 5px;
  text-align: center;
  text-transform: capitalize;
}

.plancard .ant-card-body {
  padding-top: 0px;
}

.plantext {
  color: rgb(0, 0, 0);
  font-size: 30px;
  text-align: center;
  font-weight: 700;
}

.planmonth {
  font-size: 12px;
  margin-bottom: 10px;
  text-align: center;
}

.upgradeNowBtn {
  background-color: #e27849;
  border-color: #e27849;
  color: #fff;
  font-size: 14px;
}

.planbtncenter {
  text-align: center;
  margin-bottom: 10px;
}

.plancard .ant-btn-primary:hover, .ant-btn-primary:focus {
  background-color: #e27849;
  border-color: #e27849;
  color: #fff;
}

.planlist li {
  font-weight: 600;
  font-size: 14px;
  margin: 8px 0px;
  list-style-type: none;
  padding-left: 50px;
  position: relative;
  border-bottom: 1px solid #ddd;
  padding: 5px 0 5px 50px;
}

.planlist li:before {
  content: "";
  height: 10px;
  width: 10px;
  background: rgb(226, 120, 73);
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: 11px;
}

.planinfoicon {
  margin-left: 5px;
}

.planribbon {
  position: relative;
  overflow: hidden;
}

.everythingbox {
  background: rgb(255, 250, 234);
  padding: 12px 0px 12px 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  width: 180px;
  margin-left: 30px;
  text-align: center;
}

.planribbon:after {
  font-weight: 800;
  width: 250px;
  padding: 10px;
  color: rgb(255, 255, 255);
  content: "Popular";
  position: absolute;
  text-align: center;
  background: rgb(226, 120, 73);
  transform: rotate(45deg);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 20px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  top: 22px;
  left: 225px;
  font-size: 18px;
}
.planribbon_four_card:after {
  top: 18px;
  left: 132px;
}
.planDisabled {
  pointer-events: none;
}
.planDisabled .upgradeNowBtn, .planDisabled .upgradeNowBtn:hover, .planDisabled .upgradeNowBtn:focus{
  background-color: #e5e5e5;
  border-color: #e5e5e5;
  color: #000;
}

.planDisabled .everythingbox{
  background-color: #f9f9f9;
}
.planDisabled .planlist li {
  color: #b3b3b3;
}
  
.planDisabled .planlist li:before {
  background-color: #e5e5e5;
}
.hippaImg {
  width: 70px;
  height: 22px;
  margin-right: 10px;
  align-self: center;
}

.pciImg {
  width: 70px;
  height: 28px;
}
.hippaPciCol {
  display: flex;
  align-items: center;
  justify-content: center;
}


`);

export default isoModal;