import axiosApi from "../api";
import Axios from "axios";
const base_url = process.env.REACT_APP_BASE_URL;
const integration = {

    ACTIVE_INTEGRATIONS: 'ACTIVE_INTEGRATIONS',
    INTEGRATION_TOKEN : 'INTEGRATION_TOKEN',
    ADD_INTEGRATION : 'ADD_INTEGRATION',
    RESTAPI_INTEGRATION : "RESTAPI_INTEGRATION",
    RESTAPI_SERVICE : "RESTAPI_SERVICE",
    SLACK_INTEGRATION : "SLACK_INTEGRATION",
    REMOVE_INTEGRATION : 'REMOVE_INTEGRATION',
    SLACK_CHANNEL : 'SLACK_CHANNEL',
    ADD_NATIVE_INTEGRATION : 'ADD_NATIVE_INTEGRATION',
    INTERCOM_SAVE : "INTERCOM_SAVE",
    ASSIGN_INTEGRATION_NUMBER : "ASSIGN_INTEGRATION_NUMBER",
    ASSIGN_COMMON_INTEGRATION_NUMBER : "ASSIGN_COMMON_INTEGRATION_NUMBER",
    REMOVE_BLENDR_INTEGRATION : "REMOVE_BLENDR_INTEGRATION",
    ADD_DETAILS : "ADD_DETAILS",
    REMOVE_GHL_SUB_ACCOUNT : "REMOVE_GHL_SUB_ACCOUNT",
    GET_DETAILS : "GET_DETAILS",
    DELETE_DETAILS : "DELETE_DETAILS",
    UPDATE_SMS_WEBHOOK: "UPDATE_SMS_WEBHOOK",
    INTEGRATION_USERS: "INTEGRATION_USERS",
    SET_INTEGRATION_USER: "SET_INTEGRATION_USER",
    REQUEST_NEW_INTEGRATION: "REQUEST_NEW_INTEGRATION",
    MS_CHANNELS_USERS: "MS_CHANNELS_USERS",
    SAVE_MS_SELECTIONS: "SAVE_MS_SELECTIONS",
    RESCHEDULE_ACTIVITY: "RESCHEDULE_ACTIVITY",
    LEADSQUARED_ACTIVITY: "LEADSQUARED_ACTIVITY",
    ACTIVE_CAMPAIGN_ACTIVITY: "ACTIVE_CAMPAIGN_ACTIVITY",
    DEPARTMENTS_ZOHO_DESK: "DEPARTMENTS_ZOHO_DESK",
    ZOHO_ANALYTICS_WORKSPACE: "zoho_analytics_workspace",
    GET_COUPON_CODES: "GET_COUPON_CODES",


    activeIntegrations: (isCallTracking = 0) => dispatch => axiosApi(`${base_url}integration/${isCallTracking}`, "get", {}, dispatch, `${integration.ACTIVE_INTEGRATIONS}`),
    getCouponCodes: postData => dispatch => axiosApi(`${base_url}integration/getCouponCodes`,"post",postData,dispatch,`${integration.GET_COUPON_CODES}`),
    getIntegrationToken: (payload) => dispatch => axiosApi(`${base_url}integration/integrationtoken/${payload.name}`+(payload.domain ? `?domain=${payload.domain}&&appId=${payload.appId}` : ''),"get",{},dispatch,`${integration.INTEGRATION_TOKEN}`),
    addIntegration : postData => dispatch => axiosApi(`${base_url}integration/addIntegration`,"post",postData,dispatch,`${integration.ADD_INTEGRATION}`),
    restApiIntegrate : postData => dispatch => axiosApi(`${base_url}user/restapi/integrate`,"put",postData,dispatch,`${integration.RESTAPI_INTEGRATION}`),
    restApiService : () => dispatch => axiosApi(`${base_url}integrationRestApi/restapiService`,"delete",{},dispatch,`${integration.RESTAPI_SERVICE}`),
    slackIntegrate : postData => dispatch => axiosApi(`${base_url}slack/integrate`,"put",postData,dispatch,`${integration.SLACK_INTEGRATION}`),
    removeIntegration : (name,feedback) => dispatch => axiosApi(`${base_url}integration/${name}`,"delete",feedback,dispatch,`${integration.REMOVE_INTEGRATION}`),
    removeSlackChannel : postData => dispatch => axiosApi(`${base_url}slack/deletechannel`,"put",postData,dispatch,`${integration.SLACK_CHANNEL}`),
    addNativeIntegration : (name,data) => dispatch => axiosApi(`${base_url}integration/${name}`,"put",data,dispatch,`${integration.ADD_NATIVE_INTEGRATION}`),
    intercomSave : data => dispatch => axiosApi(`${base_url}${data.type}/save/`,"put",data,dispatch,`${integration.INTERCOM_SAVE}`),
    assignIntegrationNumber : (data,integration) => dispatch => axiosApi(`${base_url}pipedriveintegration/${integration}`,"put",data,dispatch,`ASSIGN_INTEGRATION_NUMBER`),
    assignCommonIntegrationNumber : (data,integration) => dispatch => axiosApi(`${base_url}updateCommonIntegation/${integration}`,"put",data,dispatch,`ASSIGN_COMMON_INTEGRATION_NUMBER`),
    removeBlendrIntegration : postData => dispatch => axiosApi(`${base_url}integration/removeIntegration`,"post",postData,dispatch,`${integration.REMOVE_BLENDR_INTEGRATION}`),
    addDetails : (name,data) => dispatch => axiosApi(`${base_url}details/${name}`,"put",data,dispatch,`${integration.ADD_DETAILS}`),
    removeGHLSubAccount : postData => dispatch => axiosApi(`${base_url}removeGHLSubAccount`,"post",postData,dispatch,`${integration.REMOVE_GHL_SUB_ACCOUNT}`),
    getDetails: (name) => dispatch => axiosApi(`${base_url}getDataDetails/${name}`, "get", {}, dispatch, `${integration.GET_DETAILS}`),
    deleteDetails: (id,name) => dispatch => axiosApi(`${base_url}deleteDetails/${id}/${name}`, "delete", {}, dispatch, `${integration.DELETE_DETAILS}`),
    updateSMSWebhook: (id,postData) => dispatch => axiosApi(`${base_url}updateSMSWebhook/${id}`,"post",postData,dispatch,`${integration.UPDATE_SMS_WEBHOOK}`),
    getIntegrationUsers: (id,name) => dispatch => axiosApi(`${base_url}integration/integrationUsers/${id}/${name}`, "get", {}, dispatch, `${integration.INTEGRATION_USERS}`),
    setIntegrationUser: (id, name, postData) => dispatch => axiosApi(`${base_url}integration/integrationUser/${id}/${name}`, "post", postData, dispatch, `${integration.INTEGRATION_USERS}`),
    requestNewIntegration : postData => dispatch => axiosApi(`${base_url}integration/requestNewIntegration`,"post", postData, dispatch, `${integration.REQUEST_NEW_INTEGRATION}`),
    rescheduleActivity : postData => dispatch => axiosApi(`${base_url}integration/rescheduleActivity`,"post", postData, dispatch, `${integration.RESCHEDULE_ACTIVITY}`),
    leadsquaredActivity : (name) => dispatch => axiosApi(`${base_url}integration/${name}/activitytype`,"GET", {}, dispatch, name == 'leadsquared' ? `${integration.LEADSQUARED_ACTIVITY}` :  name == 'zohodesk' ? `${integration.DEPARTMENTS_ZOHO_DESK}` : name === 'zohoanalytics' ? `${integration.ZOHO_ANALYTICS_WORKSPACE}` :  `${integration.ACTIVE_CAMPAIGN_ACTIVITY}`),
    
    //action for ms teams integration
    getMsChannelsUsers: (uid) => dispatch => axiosApi(`${base_url}getMsChannelsUsers/${uid}`, "get", {}, dispatch, `${integration.MS_CHANNELS_USERS}`),
    saveMsSelections: postData => dispatch => axiosApi(`${base_url}saveMsSelections`, "post", postData, dispatch, `${integration.SAVE_MS_SELECTIONS}`),

    customerSupport: (postData) => (dispatch,state) => {
        const userData = JSON.parse(localStorage.getItem('userData')),
        authToken = userData == null ? '' : userData.authToken,
        userId = userData == null ? '' : userData._id,
        bilingtoken = userData == null ? '' : userData.billingToken;
        let url = base_url + `api/v1/customersupport`;
        dispatch({ type: "CLEAR_CUSTOM_MESSAGE" });
        return Axios.post(url,postData,{
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'id': userId,
                'authtoken': authToken,
                'billingtoken': bilingtoken
              }
            }
        )
      }
}
export default integration;