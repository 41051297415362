import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Layout,Tooltip } from 'antd';
import message from '@iso/components/Feedback/Message';
import Scrollbars from '@iso/components/utility/customScrollBar';
import Menu from '@iso/components/uielements/menu';
import appActions from '@iso/redux/app/actions';
import Logo from '@iso/components/utility/logo';
import SidebarWrapper from './Sidebar.styles';
import { Link, withRouter } from 'react-router-dom';
import CHTour from './CHTour';
import { APP_PRIVATE_ROUTE,PRIVATE_ROUTE } from '@iso/components/root/routeConstants';
import { Helmet } from "react-helmet";
import AddNumberModal from './AddNumberModal.js';
import PlanUpgradePopup from "@iso/components/Popups/PlanUpgrade/PlanUpgradePopup";
import {Action, checkAccessAllowedForModule, ModuleName} from "./../../library/helpers/moduleAccess";
import dashboardAction from "../../redux/dashboard/actions";
import {InfoCircleFilled} from '@ant-design/icons';

const { DASHBOARD, BROADCAST } = APP_PRIVATE_ROUTE;
const {getCredits} = dashboardAction ;
const { BROADCAST_ACTIVITY_FEED,BROADCAST_CALL,ADD_CAMPAIGNS_BROADCAST,EDIT_CAMPAIGNS_BROADCAST, PLAN_BILLING, USER_PROFILE, USER_PROFILE_DOCUMENT, CHANGE_PASSWORD } = PRIVATE_ROUTE;
const { Sider } = Layout;
const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
} = appActions;

function Sidebar(props) {
  const dispatch = useDispatch();
  const {
    view,
    openKeys,
    collapsed,
    openDrawer,
    current,
    height,
  } = useSelector(state => state.App);

  const userData = useSelector(state => state.authentication.userData.data);
  const numberData = useSelector(state => state.number.get_number);
  const user_billing = useSelector(state => state.number.user_billing);
  const get_plan = useSelector(state=>state.planReducer.get_plan);
  const isTourOpen = useSelector(state => state.authentication.isTourOpen);
  const pusherData = useSelector(state => state.dashboard.pusher_data);
  const userCredits = useSelector(state => state.dashboard.userCredits);
  const accessAllowed = checkAccessAllowedForModule(props.auth.userData.data.roleData, ModuleName.BROADCAST_ACTIVITY_FEED,props.auth.userData.data);
  var currentCredit = userCredits && userCredits.credits ? userCredits.credits : 0;
  // Pusher connecttion
  const [showCallingCreditSection, setShowCallingCreditSection] = useState(false);
  const [sidebarCredit, setSidebarCredit] = useState(0);
  const [sidebarCreditRedirect, setSidebarCreditRedirect] = useState(false);

  //This useEffect will call's every time when this component loaded
  useEffect(() => { 
    if (!userData.isTourVisited && !userData.isProfileRemaining) props.history.push(DASHBOARD) ;
    sidebarCreditChange();
  }, []);
  
  //Change credit when push event call
  useEffect(() => { if(pusherData && pusherData.type === "credit") sidebarCreditChange();}, [pusherData]);
  useEffect(() =>{
    if(userData.plan.isAccessEditable && userData.isDisableCredit && !(userData.roleData.priority == 1 || userData.roleData.priority == 1.5)){
      setShowCallingCreditSection(!userData.isDisableCredit)
    }else{
      setShowCallingCreditSection(true)
    }
  }, [userData])
  const customizedTheme = useSelector(
    state => state.ThemeSwitcher.sidebarTheme
  );

  const SIDEBAR_ROUTE = {
    DASHBOARD: {
      URL: BROADCAST,
      KEY: 'dashboard',
      URL_GROUP: [BROADCAST]
    },
    ACTIVITYFEED: {
      URL: BROADCAST_ACTIVITY_FEED,
      KEY: 'activityfeed',
      URL_GROUP: [BROADCAST_ACTIVITY_FEED]
    },
    BROADCAST_CALL: {
      URL: BROADCAST_CALL,
      KEY: 'voiceBroadcast',
      URL_GROUP: [
        BROADCAST_CALL,
        ADD_CAMPAIGNS_BROADCAST,
        EDIT_CAMPAIGNS_BROADCAST
      ]
    },
    PLAN_BILLING: {
      URL: PLAN_BILLING,
      KEY: 'planBilling',
      URL_GROUP: [PLAN_BILLING]
    },
    USER_PROFILE_DOCUMENT: {
      URL: USER_PROFILE_DOCUMENT,
      KEY: 'uploadDocument',
      URL_GROUP: [USER_PROFILE_DOCUMENT]
    }
  }

  useEffect(() => {
    dispatch(changeCurrent([]));
    Object.values(SIDEBAR_ROUTE).filter(obj => {
      obj.URL_GROUP.map((obj2) => {
        if (props.history.location.pathname.toLowerCase() === obj2.toLowerCase()) {
          dispatch(changeCurrent([obj.KEY]));
        }
      })
    })
    if(![USER_PROFILE,USER_PROFILE_DOCUMENT,CHANGE_PASSWORD].includes(props.history.location.pathname) && userData.isDocVerificationRequired != "approved"){
      props.history.push(USER_PROFILE_DOCUMENT);
    }
  }, [props.history.location.pathname])

  function handleClick(e) {
    //dispatch(changeCurrent([e.key]));
    if (view === 'MobileView') {
      setTimeout(() => {
        dispatch(toggleCollapsed());
        // dispatch(toggleOpenDrawer());
      }, 100);

      // clearTimeout(timer);
    }
  }

  function onOpenChange(newOpenKeys) {
    const latestOpenKey = newOpenKeys.find(
      key => !(openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = getAncestorKeys(latestCloseKey);
    }
    dispatch(changeOpenKeys(nextOpenKeys));
  }
  const getAncestorKeys = key => {
    const map = {
      sub3: ['sub2'],
    };
    return map[key] || [];
  };

  const checkCredits = () =>{
    props.getCredits().then(({payload})=>{
      if(payload.success){
      let formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 });
      let finalCredit= payload.data ? payload.data.totalCredits ? formatter.format( payload.data.totalCredits.toFixed(2).toString()):sidebarCredit : sidebarCredit;
      let finalCr= payload.data ? payload.data.totalCredits ? payload.data.totalCredits:sidebarCredit : sidebarCredit;
      let userData = JSON.parse(localStorage.getItem('userData'));
      userData.totalCredits = finalCr;
      localStorage.setItem("userData", JSON.stringify(userData));
      setSidebarCredit(finalCredit);  
      }
    });
  }

  const isCollapsed = collapsed && !openDrawer;
  const mode = isCollapsed === true ? 'vertical' : 'inline';
  const onMouseEnter = event => {
    if(window.outerWidth > 1024){  
      if (collapsed && openDrawer === false) {
        dispatch(toggleOpenDrawer());
      }
    }
    return;
  };
  const onMouseLeave = () => {
    if (collapsed && openDrawer === true) {
      dispatch(toggleOpenDrawer());
    }
    return;
  };
  const styling = {
    backgroundColor: customizedTheme.backgroundColor,
  };
  
  const sidebarCreditChange = () => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    let finalCredit = 0;
    if (pusherData && pusherData.type === "credit") {
      finalCredit = parseFloat(pusherData.data.totalCredits)
    } else {
      if (!(!numberData || !numberData.data || !numberData.data.length) && user_billing && user_billing.data && user_billing.data.data) {
        finalCredit = parseFloat(user_billing.data.data.availableCredits - user_billing.data.data.usedCredits)
      } else {
        if (userCredits && userCredits.credits) {
          finalCredit = userCredits.credits
        } else {
          if (userData && userData.stripeId && userData.plan && userData.plan.planDisplayName && userData.plan.planDisplayName !== 'free') {
            if (!userData.parentId || (userData.parentId && userData.totalCredits && userData.userModules.indexOf('billing') >= 0)) {
              finalCredit = userData && userData.totalCredits ? userData.totalCredits : 0;
              setSidebarCreditRedirect(true);
            } else if (userData && userData.parentId && userData.userModules && userData.userModules.indexOf('billing') == -1) {
              finalCredit = userData && userData.totalCredits ? userData.totalCredits : 0;
            }
          }
        }
      }
    }
    userData.totalCredits = finalCredit;
    localStorage.setItem("userData", JSON.stringify(userData));
    let userDataLocal = JSON.parse(localStorage.getItem('userData'));
    userDataLocal.totalCredits = finalCredit;
    localStorage.setItem("userData", JSON.stringify(userDataLocal));
    let formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 });
    finalCredit = formatter.format(finalCredit.toFixed(2).toString());
    setSidebarCredit(finalCredit);
  }
  
  const warningMsg = msg => { message.destroy(); message.warning(msg) };
  const userIsBlocked = () => {
    userData.isBlocked && !userData.amountDue && warningMsg(userData.blockReason);
    //userData.isBlocked && userData.amountDue && warningMsg(userData.blockReason + " Please update your payment method.");

  };
  const tabTitleChange = () => {
    if (current != []) {
      current.splice(0, current.length)
      current.push("credit")
    }
  }
  function changeTabTitle(currentKey) {
    const keyName = currentKey[0];
    if (currentKey[0] == "dashboard") {
      return "voicebroadcast"
    } else if (currentKey[0] == "activityfeed") {
      return "Activity Feed"
    }else if (currentKey[0] == "voiceBroadcast") {
      return "Voice Broadcast"
    }else if (currentKey[0] == undefined) {
      return "Dashboard"
    }else {
      return keyName[0].toUpperCase() + keyName.slice(1);
    }
  }

  return (
    <div>
      <SidebarWrapper>
        {/* <PusherConnection userData={userData} /> */}
        <Helmet>
          <title>{changeTabTitle(current)} | Callhippo.com</title>
        </Helmet>
        <CHTour isOpen={isTourOpen} />
        {/* <AddNumberModal /> */}
        <PlanUpgradePopup />
        <Sider
          trigger={null}
          collapsible={false}
          collapsed={isCollapsed}
          width={240}
          className={`isomorphicSidebar sidebaUnqDivForTopNotificatiobar  ${userData && userData.isProfileRemaining ? "sidebarDisabled" : ''}`}          
          // onMouseLeave={onMouseLeave}
          // onMouseEnter={onMouseEnter}
          style={styling}
        >
          <Logo collapsed={isCollapsed} isBlocked={userData.isBlocked} />
          <Scrollbars className={`sidebarScrollbars scrolltabheight ${userData.isProfileRemaining ? "sidebarDisabledOpacity" : ''}`} 
            style={ isCollapsed ? {height: height - 75} : {height: height - 94}}
          >
            <Menu
              onClick={handleClick}
              theme="dark"
              className="isoDashboardMenu Broadcast_Sider_outer_div"
              mode={mode}
              openKeys={isCollapsed ? [] : openKeys}
              selectedKeys={current}
              onOpenChange={onOpenChange}
            >
              <Menu.Item key={"dashboard"}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && "/broadcast"}>
                  <span className="isoMenuHolder svgremove">
                    <svg className="nav-icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"/></svg>
                    <span className="nav-text">Dashboard</span>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key={SIDEBAR_ROUTE.BROADCAST_CALL.KEY}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.BROADCAST_CALL.URL}>
                  <span className="isoMenuHolder ">
                    <i className="material-icons nav-icon voiceBroadcastIcon campaignremove">record_voice_over</i>
                    <span className="nav-text">Campaigns</span>
                    </span>
                    </Link>
                    </Menu.Item>
              {accessAllowed.read &&
              <Menu.Item key="activityfeed" data-tour="tour-activity">
                <Link onClick={userIsBlocked} to={!userData.isBlocked?(userData.numbers || userData.deletedNumbers || userData.parentId ? "/broadcast/activityFeed":"/broadcast/dummyactivityfeed"):null} >
                  <span className="isoMenuHolder svgremove">
                    <svg className="nav-icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 18h2V6H7v12zm4 4h2V2h-2v20zm-8-8h2v-4H3v4zm12 4h2V6h-2v12zm4-8v4h2v-4h-2z"/></svg>
                    <span className="nav-text">Activity Feed</span>
                  </span>
                </Link>
              </Menu.Item>}
              
              
              
              
              
              
              
            </Menu>
          </Scrollbars>
          {showCallingCreditSection && 
          <div className="sidebarCreditDiv credibtntab chflex chflexcenter" style={{marginTop:"-10px"}}>
            {sidebarCreditRedirect || !userData.isFreeTrialUser ?
              (
                <>
                  {isCollapsed ? "" :
                    <>
                    <Link to={SIDEBAR_ROUTE.PLAN_BILLING.URL}>
                    <span className="sidebarCreditSpan chflex chflexcenter" onClick={() => tabTitleChange()}>Credits
                      <Tooltip title={<div className='sidebarCreditsTooltip'>Credits are common for calling and SMS</div>} open>
                        <InfoCircleFilled className='creditsInfoIcon' /> 
                      </Tooltip>
                      <span className="currentCreditValue"> {(currentCredit > 0) ? sidebarCredit : '$0.00'}</span>
                    </span>
                    </Link>
                     <Tooltip title="Refresh">
                     <div className="refreshwrapper chflex chflexcenter justcenter" onClick={()=> checkCredits()}>
                      <i className="refreshbtn"></i>
                      </div>
                     </Tooltip>
                     </>
                  }
                </>
              )
              
            :
              <>
              <span className="sidebarCreditSpan chflex chflexcenter" onClick={() => tabTitleChange()}>Credits
                <Tooltip title={<div className='sidebarCreditsTooltip'>Credits are common for calling and SMS</div>} open>
                  <InfoCircleFilled className='creditsInfoIcon' /> 
                </Tooltip>
                <span className="currentCreditValue"> {(currentCredit > 0) ? sidebarCredit : '$0.00'}</span>
              </span>
              <Tooltip title="Refresh">
              <div className="refreshwrapper chflex chflexcenter justcenter" onClick={()=> checkCredits()}>
                <i className="refreshbtn"></i>
              </div>
              </Tooltip>
              </>
            
            }
            </div>}
        </Sider>
      </SidebarWrapper>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    numbers:state.number.get_number,
    user_billing: state.number.user_billing,
    //get_plan: state.planReducer.get_plan,
    pusherData: state.dashboard.userData,
  }
}
export default connect(mapStateToProps,{getCredits})(withRouter(Sidebar));