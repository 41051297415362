import axiosApi, {ClearUserData, errorResponseStatus} from "../api"
import axios from 'axios';
import authenticationAction from "../authentication/action";
import cookie from 'react-cookies';
const userData = JSON.parse(localStorage.getItem('userData')),
  base_url = process.env.REACT_APP_BASE_URL,
  authToken = userData == null ? '' : userData.authToken,
  userId = userData == null ? '' : userData._id,
  bilingtoken = userData == null ? '' : userData.billingToken;

const users = {

  NUMBER_LIST: 'NUMBER_LIST',
  COMMON_NUMBER_LIST: 'COMMON_NUMBER_LIST',
  COMMON_USER_LIST: 'COMMON_USER_LIST',
  GET_USERS: 'GET_USERS',
  COMMON_DATA: 'COMMON_DATA',
  ADD_USER: 'ADD_USER',
  ADD_USER_ESTIMATE: 'ADD_USER_ESTIMATE',
  AUTO_ANSWER: 'AUTO_ANSWER',
  USER_DETAILS: 'USER_DETAILS',
  UPDATE_COMMON_SETTINGS: 'UPDATE_COMMON_SETTINGS',
  UPDATE_ACW: 'UPDATE_ACW',
  ASSIGN_NUMBER: 'ASSIGN_NUMBER',
  DEFAULT_NUMBER: 'DEFAULT_NUMBER',
  DELETE_USER: "DELETE_USER",
  GET_SUBUSERS_DETAILS: "GET_SUBUSERS_DETAILS",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  GET_CUSTOMER_PORTAL: "GET_CUSTOMER_PORTAL",
  UPDATE_MODULES_RIGHTS: "UPDATE_MODULES_RIGHTS",
  UPDATE_MOBILE_PHONE_NUMBERS: "UPDATE_MOBILE_PHONE_NUMBERS",
  UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
  AVAILABILITY_TIME: "AVAILABILITY_TIME",
  TIME_OPERATION: "TIME_OPERATION",
  GET_LASTLOGIN_CSV: "GET_LASTLOGIN_CSV",
  GET_ROLES: "GET_ROLES",
  SEND_OTP: "SEND_OTP",
  VERIFY_OTP: "VERIFY_OTP",
  MAKE_ADMIN_USER:"MAKE_ADMIN_USER",
  ASSIGN_ALL:"ASSIGN_ALL",
  CLONE_USER_SETTING:"CLONE_USER_SETTING",
  GET_SHAKEN_STIR:"GET_SHAKEN_STIR",
  SHAKEN_STIR:"SHAKEN_STIR",
  GET_COUNTRY_LIST:"GET_COUNTRY_LIST",

  getShakenStirAction: () => dispatch => axiosApi(`${base_url}shakenstirdata`, "get", {}, dispatch, `${users.GET_SHAKEN_STIR}`),
  shakenStirAction: (postData) => dispatch => axiosApi(`${base_url}shakenstirdata`, "post", postData, dispatch, `${users.SHAKEN_STIR}`),
  getAvailabilityTime: id => dispatch => axiosApi(`${base_url}user/${id}/setting/time`, "get", {}, dispatch, `${users.AVAILABILITY_TIME}`),
  timeOperation: (id, postData) => dispatch => axiosApi(`${base_url}user/${id}/setting/customTime`, "post", postData, dispatch, `${users.TIME_OPERATION}`),
  numberList: postData => dispatch => {
    dispatch({ type: "CLEAR_CUSTOM_MESSAGE" });
    return axiosApi(`${base_url}number/list?isAddUser=0`, "get", {}, dispatch, `${users.NUMBER_LIST}`);
  },
  commonNumberList: postData => dispatch => {
    dispatch({ type: "CLEAR_CUSTOM_MESSAGE" });
    return axiosApi(`${base_url}number/common/list`, "get", {}, dispatch, `${users.COMMON_NUMBER_LIST}`);
  },
  commonUserList: postData => dispatch => {
    dispatch({ type: "CLEAR_CUSTOM_MESSAGE" });
    let isRole = postData.isRole?`&isRole=${postData.isRole}`:'';
    return axiosApi(`${base_url}user/common/list?${isRole}`, "get", postData, dispatch, `${users.COMMON_USER_LIST}`);
  },
  getUsers: (postData={}) => dispatch => {
    let pagination = postData.pagination?`&pagination=${postData.pagination}`:''
    let search = postData.search?`&search=${encodeURIComponent(postData.search)}`:'';
    let usersActive = postData.userActive?`&status=true`:'';
    let isDownload = postData.isDownload?`&isDownload=${postData.isDownload}`:'';
    let teamCreate = postData.teamCreate?`&teamCreate=${postData.teamCreate}`:'';
    let roleName = postData.roleName?`&roleName=${postData.roleName}`:'';
    let allForClone = postData.allForClone?`&allForClone=${postData.allForClone}`:'';
    dispatch({ type: "CLEAR_CUSTOM_MESSAGE" });
    return axiosApi(`${base_url}user/list?current=${postData.current?postData.current:1}${pagination}${search}${usersActive}${isDownload}${teamCreate}${roleName}${allForClone}`, "get", {}, dispatch, `${users.GET_USERS}`).then((result) => {
      return result;
    })
  },
  getLastLoginCsv: postData => dispatch => {
    dispatch({ type: "CLEAR_CUSTOM_MESSAGE" });
    return axiosApi(`${base_url}user/getlastloginCsv`, "get", {}, dispatch, `${users.GET_LASTLOGIN_CSV}`).then((result) => {     
      return result;
    })
  },
  getCommonData: (postData={}) => dispatch => {
    dispatch({ type: "CLEAR_CUSTOM_MESSAGE" });
    const userData = JSON.parse(localStorage.getItem('userData'));
    let parentId = userData.parentId?userData.parentId:userData._id;

    return axiosApi(`${base_url}commondata?parentId=${parentId}`, "get", {}, dispatch, `${users.COMMON_DATA}`).then((result) => {
      return result;
    })
  },

  //This function is used to delete user
  deleteUser: (id, index) => dispatch => {
    const userData = JSON.parse(localStorage.getItem('userData')),
  base_url = process.env.REACT_APP_BASE_URL,
  authToken = userData == null ? '' : userData.authToken,
  userId = userData == null ? '' : userData._id,
  bilingtoken = userData == null ? '' : userData.billingToken;
    dispatch({ type: "CLEAR_CUSTOM_MESSAGE" });
    return axios.delete(`${base_url}user/${id}`,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken
        }
      }
    ).then(postData => {
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: postData.data
      })
      dispatch({
        type: users.DELETE_USER,
        payload: index,
      });
      return postData
    }).catch((err) =>{
      if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
        return ClearUserData(err.response);
      }
       console.log(err, "Error in deleteUser api in users.js",err)
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: err.response.data.error, type:"error" }
      })
      return err
    })
  },
  // This function is used to get users details
  getUserDetails: (postData, pageDetail) => dispatch => {
    let currentPage, isUserDoc
    if(pageDetail){
      currentPage = pageDetail
      isUserDoc = true
    }else{
      currentPage = 1
      isUserDoc = false
    }
    
    return axiosApi(`${base_url}user/details/${postData}?isAddNumber=0&userDocs=`+isUserDoc+`&current=`+currentPage, "get", {}, dispatch, `${users.USER_DETAILS}`);
  },
  updateProfileData: (id, postData) => dispatch => axiosApi(`${base_url}user/${id}/setting/edit`, "put", postData, dispatch, `${authenticationAction.UPDATE_COMMON_USER_DATA}`),
  cloneUserSetting: (postData) => dispatch => axiosApi(`${base_url}user/clone/settings`, "post", postData, dispatch, `${users.CLONE_USER_SETTING}`),
  updateSettingData: (id, data,status) => dispatch => {
    const userData = JSON.parse(localStorage.getItem('userData')),
    base_url = process.env.REACT_APP_BASE_URL,
    authToken = userData == null ? '' : userData.authToken,
    userId = userData == null ? '' : userData._id,
    bilingtoken = userData == null ? '' : userData.billingToken;
    dispatch({ type: "CLEAR_CUSTOM_MESSAGE" });
    console.log("heree",userId,authToken,bilingtoken);
    return axios.put(`${base_url}user/${id}/setting/edit`, data,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken
        }
      }
    ).then(postData => {
      let message = `User updated successfully.`
      if(data.isEmailChanged){
        if(status){
          message = `We have sent you verification mail. Please verify it to process further.`;
        }else{
          message = `${message} A verification has been sent to your new email.`;
        }
      }
      if(status && status == "hippaCompliance" && data.hippaComplianceEnabled){
        message = `Your account is now HIPAA compliant.`
      }
      if(status && status == "emailDownload"){
        message = 'Download Reports in Email status updated successfully.'
        dispatch({
          type: authenticationAction.UPDATE_COMMON_USER_DATA,
          payload:{"data":{emailDownload : data.emailDownload},type:"emailDownload"},
        })
      }
      //postData.data = { ...postData.data , ...data}
      postData.data.fullName = data.fullName;
      postData.data.currentUser = data.currentUser;
      let returnData = {
        type: users.UPDATE_COMMON_SETTINGS,
        payload: postData
      }
      dispatch(returnData)
      if(postData.data.data && postData.data.data.allUsers){
        dispatch({
          type: authenticationAction.UPDATE_COMMON_USER_DATA,
          payload:{"data":postData.data.data,type:"updateRoleUsers"},
        })
      }
      if(status != "hippaCompliance"){
        if(!data.fromUserCalendar){
          dispatch({
            type: "SET_CUSTOM_MESSAGE",
            payload: { message,type: "success" }
          })
        }
      }
      else{
        if(data.hippaComplianceEnabled){
          if(!data.fromUserCalendar){
            dispatch({
              type: "SET_CUSTOM_MESSAGE",
              payload: { message,type: "success" }
            })
          }
        } 
      }
      return returnData
    }).catch(err => {
      err = JSON.parse(err.response.request.response);
      // if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
      //   return ClearUserData(err.response);
      // }
      // dispatch({
      //   type: "SET_CUSTOM_MESSAGE",
      //   payload: { message: err.response.error.error, type:"error" }
      // })
      let returnErr = {
        type: users.UPDATE_COMMON_SETTINGS,
        payload: err
      }
      dispatch(returnErr)
      return returnErr
    })
  },
  changeUserEmailPassword: (postData,id) => dispatch => axiosApi(`${base_url}user/${id}/setting`, "put", postData, dispatch, `${users.CHANGE_PASSWORD_EMAIL}`),
  sendOTPForVerify: (postData) => dispatch => axiosApi(`${base_url}user/sendOTPForVerify`, "post", postData, dispatch, `${users.SEND_OTP}`),
  verifyOTP: (postData) => dispatch => axiosApi(`${base_url}user/verifyOTP`, "post", postData, dispatch, `${users.VERIFY_OTP}`),

  updateAcw: (postData, id) => dispatch => {
    const userData = JSON.parse(localStorage.getItem('userData')),
  base_url = process.env.REACT_APP_BASE_URL,
  authToken = userData == null ? '' : userData.authToken,
  userId = userData == null ? '' : userData._id,
  bilingtoken = userData == null ? '' : userData.billingToken;
    dispatch({ type: "CLEAR_CUSTOM_MESSAGE" })
    axios.put(`${base_url}user/${id}/acwupdate`, postData,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken
        }
      }
    ).then(postData => {
      dispatch({
        type: users.UPDATE_ACW,
        payload: postData
      })
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: "User updated successfully.", type: "success"}
      })
    }).catch(err => {
      if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
        return ClearUserData(err.response);
      }
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: err.response.data.error.error ? err.response.data.error.error : err.response.data.error, type:"error" }
      })

      console.log(err, "Error in updateAcw api in users.js") })
  },


  //This function is used to allocate/deallocate number to user
  allocateNumber: (postData, id) => dispatch => {
    const userData = JSON.parse(localStorage.getItem('userData')),
  base_url = process.env.REACT_APP_BASE_URL,
  authToken = userData == null ? '' : userData.authToken,
  userId = userData == null ? '' : userData._id,
  bilingtoken = userData == null ? '' : userData.billingToken;
    dispatch({ type: "CLEAR_CUSTOM_MESSAGE" })
    axios.put(`${base_url}user/${id}/assignNumber`, postData,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken
        }
      }
    ).then(result => {
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: `Number ${postData.isAdd ? "allocated" : "deallocated"} successfully.`, type: "success" }
      })
      dispatch({
        type: users.ASSIGN_NUMBER,
        payload: postData
      })
    }).catch(err => {
      if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
        return ClearUserData(err.response);
      }
      console.log(err.response.data, "Error in allocateNumber api in users.js") })
  },

  assignMultipleNumbers: (postData, userId) => dispatch => axiosApi(`${base_url}user/assignMultipleNumbers/${userId}`, "post", postData, dispatch, `${users.ASSIGN_ALL}`),

  resendInvite: (user, id) => dispatch => {
    dispatch({ type: "CLEAR_CUSTOM_MESSAGE" })
    axiosApi(`${base_url}resendinvitation`, "post", user, dispatch, "SET_CUSTOM_MESSAGE");
  },
    
  allocateModule: (postData, id, p_userId, p_authToken, p_billingToken) => dispatch => {
    const userData = JSON.parse(localStorage.getItem('userData')),
    base_url = process.env.REACT_APP_BASE_URL,
    authToken = userData == null ? '' : userData.authToken,
    userId = userData == null ? '' : userData._id,
    bilingtoken = userData == null ? '' : userData.billingToken;
    dispatch({ type: "CLEAR_CUSTOM_MESSAGE" })
    axios.put(`${base_url}user/${id}/assignModule`, postData,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken
        }
      }
    ).then((result) => {
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: `Module ${postData.isAdd ? "allocated" : "deallocated"} successfully.`, type: "success" }
      })
      dispatch({
        type: users.UPDATE_MODULES_RIGHTS,
        payload: postData
      })
    }).catch(err => {
      if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
        return ClearUserData(err.response);
      }
     })
  },
  updateMobileNo: (postData, id) => dispatch => {
    const userData = JSON.parse(localStorage.getItem('userData')),
  base_url = process.env.REACT_APP_BASE_URL,
  authToken = userData == null ? '' : userData.authToken,
  userId = userData == null ? '' : userData._id,
  bilingtoken = userData == null ? '' : userData.billingToken;
    dispatch({ type: "CLEAR_CUSTOM_MESSAGE" })
    axios.put(`${base_url}user/${id}/setting/edit`, postData,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken
        }
      }
    ).then(result => {
      dispatch({ type: "SET_CUSTOM_MESSAGE", payload: { message: "User updated successfully.", type: "success"} });
      dispatch({
        type: users.UPDATE_MOBILE_PHONE_NUMBERS,
        payload: postData
      })

    }).catch(err => {
      if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
        return ClearUserData(err.response);
      }
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: err.response.data.error.error ? err.response.data.error.error : err.response.data.error, type:"error" }
      })

      console.log(err, "Error in updateSettingData api in users.js") })
  },

  changePassword: postData => dispatch => axiosApi(`${base_url}changePassword`, "post", postData, dispatch, `${users.CHANGE_PASSWORD}`),

  getCustomerPortal: postData => dispatch => axiosApi(`${base_url + postData.id}/customerPortal?cancelCheck=${postData.cancelCheck}`, "get", {}, dispatch, `${users.GET_CUSTOMER_PORTAL}`),

  uploadvoicemailVoice: (postData, id) => (dispatch, getState) => {
    const userData = JSON.parse(localStorage.getItem('userData')),
  base_url = process.env.REACT_APP_BASE_URL,
  authToken = userData == null ? '' : userData.authToken,
  userId = userData == null ? '' : userData._id,
  bilingtoken = userData == null ? '' : userData.billingToken;
    dispatch({ type: "CLEAR_CUSTOM_MESSAGE" })
    return axios.post(`${base_url}user/${id}/uploadfile`, postData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken
        }
      }
    ).then((result) => {
      // console.log("in uploadvoicemailvoice function",getState().usersReducer.user_details.voiceMail);
      // let userDetail = { ...getState().usersReducer.user_details };
      // userDetail.voiceMail = result.data.data.userRes.voiceMail;
      // console.log("in uploadvoicemailvoice function",userDetail.voiceMail);
      // dispatch({
      //   type: users.USER_DETAILS,
      //   payload: { data: userDetail }
      // });
      // dispatch({
      //   type: "SET_CUSTOM_MESSAGE",
      //   payload: { message: "uploaded successfully." }
      // });
      return result;
    }).catch((err) => {
      if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
        return ClearUserData(err.response);
      }
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: err.response && err.response.data?err.response.data.error.error:"Something went wrong",type:"error"}
      });
    })
  },
  updateExtensionNumber: (id, postData) => (dispatch, getState) => {
    const userData = JSON.parse(localStorage.getItem('userData')),
  base_url = process.env.REACT_APP_BASE_URL,
  authToken = userData == null ? '' : userData.authToken,
  userId = userData == null ? '' : userData._id,
  bilingtoken = userData == null ? '' : userData.billingToken;
    let userDetail = { ...getState().usersReducer.user_details };
    dispatch({ type: "CLEAR_CUSTOM_MESSAGE" })
    axios.put(`${base_url}user/${id}/setting/edit`, postData,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken
        }
      }
    ).then(result => {
      userDetail.extensionNumber = postData.extensionNumber;
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: "User updated successfully.", type: "success"}
      });
      dispatch({
        type: users.USER_DETAILS,
        payload: { data: userDetail }
      })
    }).catch(err => {
      if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
        return ClearUserData(err.response);
      }
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: err.response.data.error.error ? err.response.data.error.error : err.response.data.error, type:"error" }
      });
      dispatch({
        type: users.USER_DETAILS,
        payload: { data: userDetail }
      })
    })
  },
  allocateDefaultNumber: (postData, index) => (dispatch, getState) => {
    const userData = JSON.parse(localStorage.getItem('userData')),
  base_url = process.env.REACT_APP_BASE_URL,
  authToken = userData == null ? '' : userData.authToken,
  userId = userData == null ? '' : userData._id,
  bilingtoken = userData == null ? '' : userData.billingToken;
    dispatch({ type: "CLEAR_CUSTOM_MESSAGE" })
    axios.post(`${base_url}user/defaultNo`, postData,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken
        }
      }
    ).then(result => {
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: "Default number changed successfully.", type: "success" }
      })
      dispatch({
        type: "UPDATE_DEFAULT_NUMBER",
        payload: postData
      });
    }).catch(err => {
      if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
        return ClearUserData(err.response);
      }
      console.log(err);
    })
  },
  addUser: postData => dispatch => {
  const userData = JSON.parse(localStorage.getItem('userData')),
  base_url = process.env.REACT_APP_BASE_URL,
  userId = userData == null ? '' : userData._id
  
    axiosApi(`${base_url}user/${userId}/add`, "post", postData, dispatch, `${users.ADD_USER}`).then(function(res){
      if(res.payload.success){
        return dispatch({
          type:"SET_CUSTOM_MESSAGE",
          payload:{message:"Your invitation sent successfully.", type: "success"}
        });
      }else{
        let error = res.payload.message;
        return dispatch({
          type:"SET_CUSTOM_MESSAGE",
          payload:{message:error, type: res.payload.type || "error"}
        });
      }
    }).catch(err => {
      if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
        return ClearUserData(err.response);
      }
    })
  },

  addUserEstimate: postData => dispatch =>{
    const userData = JSON.parse(localStorage.getItem('userData')),
        base_url = process.env.REACT_APP_BASE_URL,
        userId = userData == null ? '' : userData._id
    console.log("userId new", userId)
    return axiosApi(`${base_url}user/create/${userId}/estimate`, "post", postData, dispatch, `${users.ADD_USER_ESTIMATE}`)},

    addNumberEstimate: postData => dispatch =>{
      const userData = JSON.parse(localStorage.getItem('userData')),
          base_url = process.env.REACT_APP_BASE_URL,
          userId = userData == null ? '' : userData._id
      console.log("userId new", userId)
      return axiosApi(`${base_url}numbers/number/create/${userId}/estimate`, "post", postData, dispatch, `${users.ADD_USER_ESTIMATE}`)},

  changeEmail: (hash,i,c) => {
    return axios.get(base_url + 'api/v1/changeemail/' + hash + '/' + i + '/' + c ,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );
  },

  uploadDocument: (data) => {
  const  userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
  return (dispatch, getstate) => {
    axios.post(base_url + 'user/'+userId+'/uploadDocument', data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }
    ).then(postdata => {
      dispatch({
        type: users.UPLOAD_DOCUMENT,
        postdata,
      })
    }).catch((error) => {
      if(error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
        return ClearUserData(error.response);
      }
      const postdata = error.response;
      dispatch({
        type: users.UPLOAD_DOCUMENT,
        postdata,
      })
    });
  }
},
getRoles: () => dispatch => {
  dispatch({ type: "CLEAR_CUSTOM_MESSAGE" });
  return axiosApi(`${base_url}roles`, "get", {}, dispatch, `${users.GET_ROLES}`).then((result) => {
    return result;
  })
},

autoAnswer: postData => dispatch =>{
  return axiosApi(`${base_url}autoAnswerUpdate`, "post", postData, dispatch, `${users.AUTO_ANSWER}`).then((result) => {
    return result;
  })
},

makeAdminUser: postData => (dispatch) => {
  axiosApi(`${base_url}makeAdminUser`, "post", postData, dispatch, `${users.MAKE_ADMIN_USER}`).then(function(res){
    console.log("res==============",res)
    if(res.payload.success){
      return dispatch({
        type: users.MAKE_ADMIN_USER,
        payload:res.payload
      })
    }else{
      return dispatch({
        type: users.MAKE_ADMIN_USER,
        payload:res.payload.error
      })
    }
  }).catch((err) => {
    return dispatch({
      type: users.MAKE_ADMIN_USER,
      payload:err
    })
  });
},

getCountryList: () => dispatch => {
  return axiosApi(`${base_url}getCountryList`, "get", {}, dispatch, `${users.GET_COUNTRY_LIST}`).then((result) => {
    return result
  })
},

}
export default users;
