import React, { Component } from 'react';

class KommunicateChat extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    try {
      (function (d, m) {
        var kommunicateSettings = {
          appId: process.env.REACT_APP_KOMMUNICATE_APPID,
          popupWidget: true,
          automaticChatOpenOnNavigation: false,
          hidePostCTA: true,
          labels: {
            "closed.conversation.message":
              "This conversation has been marked as resolved. If you have other queries, kindly drop a mail to sales@callhippo.com.",
          },
          onInit: function (resp) {
            var css2 = ".mck-dropdown{ display: none !important;} #mck-restart-conversation{display:none !important;} #start_new_conversation_button{border:none; background-color: transparent; color:blueviolet;} .mck-tab-link.mck-back-btn-container{ display: none !important; } .mck-name-status-container{ margin-left: 50px; }";
            window.Kommunicate.customizeWidgetCss(css2);
            var widgetOption = window.KommunicateGlobal.document.getElementById('km-faq');
    
            let startnewConversationLink = window.KommunicateGlobal.document.querySelector(".mck-csat-text-1");
            var startbutton = document.createElement("button");
            startbutton.innerHTML = 'Start New Conversation'
            startbutton.setAttribute("id", "start_new_conversation_button");
            startnewConversationLink.appendChild(startbutton);
            startbutton.addEventListener('click', () => {
              window.Kommunicate.startConversation();
            })
    
            var events = {
               onChatWidgetOpen: function (resp) {
                setTimeout(() => {
                  addClickEventToElements();
                  addClickEventToresolvedElements()
                  let groupId = `li-group-${window.KommunicateGlobal.CURRENT_GROUP_DATA.tabId}`
                  showOrHide(groupId);
                }, 2000);
    
              },
              onStartNewConversation: function (resp) {
                hideTextArea();
                hideAttachment(false);
                DisableResolvedConversations(0);
                var csat=window.KommunicateGlobal.document.querySelector("#mck-sidebox-ft");
                csat.classList.remove("n-vis");
                csat.classList.add("vis")
              },
    
              onMessageReceived: function (resp) {
                var action = resp?.message?.metadata?.action;
                if (action === "NewCustomer" || action === "ExistingCustomer") {
                  showTextArea(`li-group-${resp.message.to}`);
                } else if (
                  action === "users" ||
                  action === "welcome" ||
                  action === "hidetextarea" ||
                  action === "hideforinvalidemail"
                ) {
                  hideInputField(`li-group-${resp.message.to}`);
                }
                if (action === "NewCustomer") {
                  localStorage.setItem(`li-group-${resp.message.to}-existingCustomer`, false);
                  hideAttachment(true);
                }
              },
    
              onMessageSent: function (resp) {
                if (resp && resp.message && resp.message.metadata && resp.message.metadata.KM_TRIGGER_EVENT == "validate email") {
                  var userdetail = {
                    "email": resp.message.message,
                  };
                  window.Kommunicate.updateSettings({ "KM_CHAT_CONTEXT": userdetail });
    
                }
              },
    
              onChatWidgetClose: function (resp){
                DisableResolvedConversations(0)
                var csat=window.KommunicateGlobal.document.querySelector("#mck-sidebox-ft");
                csat.classList.remove("n-vis");
                csat.classList.add("vis")
              }
    
            };
            window.Kommunicate.subscribeToEvents(events);
          },
        };
    
        function showTextArea(groupId) {
          var chatWidget = window.KommunicateGlobal.document.querySelector(
            ".mck-box-form.mck-row"
          );
          if (chatWidget) {
            chatWidget.style.display = "block";
            localStorage.setItem(`${groupId}`, false);
          }
        }
    
        function hideTextArea() {
          var chatWidget = window.KommunicateGlobal.document.querySelector(
            ".mck-box-form.mck-row"
          );
          if (chatWidget) {
            chatWidget.style.display = "none";
          }
        }
    
        function hideInputField(groupId) {
          hideTextArea()
          localStorage.setItem(`${groupId}`, true);
        }
    
    
        function addClickEventToElements() {
          var elements = window.KommunicateGlobal.document.querySelectorAll(".mck-conversation-open");
          if (elements?.length == 0) {
            let btn = window.KommunicateGlobal.document.getElementById("mck-contacts-content");
            btn.classList.remove("force-n-vis");
          } else if (elements?.length == 1) {
            let id = elements[0].id.split("-");
            window.Kommunicate.openConversation(id[id.length - 1]);
            showOrHide(elements[0].id);
          }
          for (var i = 0; i < elements.length; i++) {
            elements[i].onclick = function () {
              var Id = this.id;
              showOrHide(Id)
              localStorage.getItem(`${Id}-existingCustomer`) === "false" ? hideAttachment(true) : hideAttachment(false);
    
            }
          }
        }
    
    
        function addClickEventToresolvedElements() {
          var elements = window.KommunicateGlobal.document.querySelectorAll(".mck-conversation-resolved");
          for (var i = 0; i < elements.length; i++) {
            elements[i].onclick = function () {
              hideInputField();
              DisableResolvedConversations(1);
              let resolvdByuser=JSON.parse(localStorage.getItem("resolvedByuser"));
             
              if(resolvdByuser){
                let isendbyuser=resolvdByuser.find(id=> id===window.KommunicateGlobal.CURRENT_GROUP_DATA.tabId)
               
                if(isendbyuser){
                  
                  var csat = window.KommunicateGlobal.document.querySelector("#mck-sidebox-ft");
                 
                  csat.style.display="none"
                }
              }
              
            }
          }
        }
    
    
        function DisableResolvedConversations(flag){
         setTimeout(()=>{
          var buttons = window.KommunicateGlobal.document.querySelectorAll(".km-quick-replies");

          if (buttons.length>0  && flag) {
            buttons.forEach(element => {
              element.disabled = true;  
            });
          }
         if(flag){
            let newlist=JSON.parse(localStorage.getItem("resolvdByuser"));
            if(newlist>0){
              newlist.push( window.KommunicateGlobal.CURRENT_GROUP_DATA.tabId);
            }else{
              newlist=[];
              newlist.push( window.KommunicateGlobal.CURRENT_GROUP_DATA.tabId);
            }
            localStorage.setItem("resolvedByuser",JSON.stringify(newlist));
         }
          let background = window.KommunicateGlobal.document.querySelector(".mck-group-inner");
           if(background){
            background.style.filter = `grayscale(${flag})`
           }
         },900)
        }
    
    
        function showOrHide(Id) {
          localStorage.getItem(Id) === "false" ? showTextArea(Id) : hideInputField(Id);
        }
    
        function hideAttachment(flag) {
          const uploadIcon = window.KommunicateGlobal.document.querySelector("#mck-file-up2");
          if (uploadIcon && flag) {
            uploadIcon.style.visibility = "hidden";
          } else {
            uploadIcon.style.visibility = "visible";
          }
        }
    
    
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.kommunicate.io/v2/kommunicate.app";
        var h = document.getElementsByTagName("head")[0];
        h.appendChild(s);
        window.kommunicate = m;
        m._globals = kommunicateSettings;
      })(document, window.kommunicate || {});
    } catch (error) {
      console.error("An error occurred while initializing the chat widget:", error);
    }   
  }

  render() {
    return (
      <div></div>
    );
  }
}

export default KommunicateChat;
