import axios from 'axios';
import {ClearUserData, errorResponseStatus} from "../api";



const callPlanner = {

    CALL_REMINDERS: 'CALL_REMINDERS',
    DELETE_REMINDER : 'DELETE_REMINDER',
    
    //User Plan Data Api 
    getCallReminder: (postData,activeKey) => dispatch => {
const userdata = JSON.parse(localStorage.getItem('userData'));

const base_url = process.env.REACT_APP_BASE_URL;
const authToken = userdata == null ? '' : userdata.authToken;
const userId = userdata == null ? '' : userdata._id;
const bilingtoken = userdata == null ? '' : userdata.billingToken;
            return axios.get(`${base_url}getCallLog/${userId}/getcallreminders/${postData.skip}/${postData.limit}`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userId,
                        'authtoken': authToken,
                        'billingtoken': bilingtoken
                    }
                }
            ).then(data => {
                dispatch({
                    type: callPlanner.CALL_REMINDERS,
                    payload:data.data,
                    activeKey
                });
                dispatch({
                    type:"CHANGE_CALL_REMINDER_LENGTH",
                    payload:data.data.CallReminders.countPendingReminder
                })
                return data.data
            }).catch((err) => {
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                dispatch({
                    type:"SET_CUSTOM_MESSAGE",
                    payload:{message: err.response && err.response.data ? err.response.data.error.error:"something happened wrong",type:"error"}
                });
            })
    },
    
//User Plan Data Api 
deleteCallReminder: (Data,index) => dispatch => {
    const userdata = JSON.parse(localStorage.getItem('userData'));

const base_url = process.env.REACT_APP_BASE_URL;
const authToken = userdata == null ? '' : userdata.authToken;
const userId = userdata == null ? '' : userdata._id;
const bilingtoken = userdata == null ? '' : userdata.billingToken;
    return axios.delete(base_url + 'user/deleteCallReminder/'+Data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'id': userId,
                    'authtoken': authToken,
                    'billingtoken': bilingtoken
                }
            }
        ).then((postdata) => {
            dispatch({
                type:callPlanner.DELETE_REMINDER,
                payload:index,
                postdata
            })
            // return result;
        }).catch((err) => {
            if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
               return ClearUserData(err.response);
            }
            dispatch({
                type: "SET_CUSTOM_MESSAGE",
                payload: { message: err.response && err.response.data?err.response.data.error.error:"Something went wrong",type:"error"}
              });
          });
},

}
export default callPlanner;