
import axiosApi from "../api";
const base_url = process.env.REACT_APP_BASE_URL;

const importActions = {
  IMPORT_CONTACT : 'IMPORT_CONTACT',
  IMPORT_DNC_NUMBER : 'IMPORT_DNC_NUMBER',
  
  importContactAdd: postData => dispatch => axiosApi(`${base_url}importContact`, "post", postData, dispatch, `${importActions.IMPORT_CONTACT}`),
  importDNCNumbers: postData => dispatch => axiosApi(`${base_url}import/dncNumberList`, "post", postData, dispatch, `${importActions.IMPORT_DNC_NUMBER}`),
}
export default importActions;