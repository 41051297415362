import axios from 'axios';
import axiosApi from '../api'
import { APP_PRIVATE_ROUTE } from '@iso/components/root/routeConstants';
const {ClearUserData, errorResponseStatus} = axiosApi
const { DASHBOARD } = APP_PRIVATE_ROUTE;
const userData = JSON.parse(localStorage.getItem('userData')),
    base_url = process.env.REACT_APP_BASE_URL,
    base_url_v2 = process.env.REACT_APP_BASE_URL,
    authToken = userData == null ? '' : userData.authToken,
    userId = userData == null ? '' : userData._id,
    bilingtoken = userData == null ? '' : userData.billingToken;


const callReport = {

    CALL_REPORT: 'CALL_REPORT',
    CALL_REPORT_CSV: 'CALL_REPORT_CSV',
    ABANDONMENT_REPORT: 'ABANDONMENT_REPORT',
    ABANDONMENT_REPORT_CSV: 'ABANDONMENT_REPORT_CSV',
    CALLSTATUS_REPORT: 'CALLSTATUS_REPORT',
    CALLSTATUS_REPORT_CSV: 'CALLSTATUS_REPORT_CSV',
    AVAILABILITY_REPORT: 'AVAILABILITY_REPORT',
    AVAILABILITY_REPORT_CSV: 'AVAILABILITY_REPORT_CSV',
    USER_STATUS_REPORT: 'USER_STATUS_REPORT',
    USER_STATUS_REPORT_CSV: 'USER_STATUS_REPORT_CSV',
    DID_REPORT: 'DID_REPORT',
    DID_REPORT_CSV: 'DID_REPORT_CSV',
    AVAILABILITY_SLOT: 'AVAILABILITY_SLOT',
    CALL_COUNTRIES:'CALL_COUNTRIES',
    USER_LIST:'USER_LIST',
    COUNTRY_WISE:'COUNTRY_WISE',
    TARGET_LIST: 'TARGET_LIST',
    ADD_TARGET: 'ADD_TARGET',
    EDIT_TARGET:'EDIT_TARGET',
    REMOVE_TARGET: 'REMOVE_TARGET',
    CALL_DISPOSITION_REPORT: 'CALL_DISPOSITION_REPORT',

    getCallReport: (userData,postData,reportData) => dispatch => {
            return axios.post(base_url_v2 + 'api/v1/callReport',{reportData,postData:postData},
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userData.userId,
                        'authtoken': userData.authToken,
                        'billingtoken': userData.billingToken
                    }
                }
            ).then(postData => {

                if (postData.data.data.message) {

                    dispatch({
                        type: "SET_CUSTOM_MESSAGE",
                        payload: { message: postData.data.data.message, type: "infoLong" }
                    })
                }

                if(postData.data.data.isDownload){
                    if(!postData.data.data.url){
                        dispatch({
                            type:"SET_CUSTOM_MESSAGE",
                            payload:{message:'Your request is submitted successfully. We will send you the report on your registered email ID.',type:"infoLong"}
                        });
                    }else{
                        dispatch({
                            type:"SET_CUSTOM_MESSAGE",
                            payload:{message:'Your report is downloaded successfully!',type:"success"}
                        });
                        return dispatch({
                            type: callReport.CALL_REPORT_CSV,
                            postData,
                        })
                    }
                }else{
                    return dispatch({
                        type: callReport.CALL_REPORT,
                        postData,
                    })
                }

            }).catch(err => {
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                if(err.response && err.response.data){
                dispatch({
                    type:"SET_CUSTOM_MESSAGE",
                    payload:{message:err.response.data.error.error,type:"error"}
                });
                // history.push(DASHBOARD)
                }else{
                    dispatch({
                        type:"SET_CUSTOM_MESSAGE",
                        payload:{message:"something went wrong",type:"error"}
                    });
                }
            })
        
    },

    getAbandonmentReport: (userData,postData,reportData) => {
        return (dispatch, getstate) => {
            axios.post(base_url_v2 + 'api/v1/callReport/abandonmentReport',{reportData,postData},
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userData.userId,
                        'authtoken': userData.authToken,
                        'billingtoken': userData.billingToken
                    }
                }
            ).then(postData => {

                if (postData.data.data.message) {

                    dispatch({
                        type: "SET_CUSTOM_MESSAGE",
                        payload: { message: postData.data.data.message, type: "infoLong" }
                    })
                }

                if(postData.data.data.isDownload){
                    if(!postData.data.data.url){
                        dispatch({
                            type:"SET_CUSTOM_MESSAGE",
                            payload:{message:'Your request is submitted successfully. We will send you the report on your registered email ID. ',type:"infoLong"}
                        });
                    }else{
                        dispatch({
                            type:"SET_CUSTOM_MESSAGE",
                            payload:{message:'Your report is downloaded successfully!',type:"success"}
                        });
                        dispatch({
                            type: callReport.ABANDONMENT_REPORT_CSV,
                            postData,
                        })
                    }
                }else{
                    dispatch({
                    type: callReport.ABANDONMENT_REPORT,
                    postData,
                })
                }
            }).catch(err => {
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                if(err.response && err.response.data) {
                    dispatch({
                        type: "SET_CUSTOM_MESSAGE",
                        payload: {message: err.response.data.error.error, type: "error"}
                    });
                }
                console.log(err, "Error in get call report api in users.js") 
            })
        }
    },

    getCallStatusReport: (userData,postData) => {
        return (dispatch, getstate) => {
            axios.post(base_url_v2 + 'api/v1/callReport/callStatusReport',postData,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userData.userId,
                        'authtoken': userData.authToken,
                        'billingtoken': userData.billingToken
                    }
                }
            ).then(postData => {
                if (postData.data.data.message) {
                    dispatch({
                        type: 'SET_CUSTOM_MESSAGE',
                        payload: { message: postData.data.data.message, type: 'infoLong' }
                    })
                }
                if(postData.data.data.isDownload){
                    if(!postData.data.data.url){
                       dispatch({
                           type:"SET_CUSTOM_MESSAGE",
                           payload:{message:'Your request is submitted successfully. We will send you the report on your registered email ID.',type:"infoLong"}
                       });
                    }else{
                        dispatch({
                            type:"SET_CUSTOM_MESSAGE",
                            payload:{message:'Your report is downloaded successfully!',type:"success"}
                        });
                        dispatch({
                            type: callReport.CALLSTATUS_REPORT_CSV,
                            postData,
                        })
                    }
                }else{
                    dispatch({
                        type: callReport.CALLSTATUS_REPORT,
                        postData,
                    })
                }
            }).catch(err => {
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                if(err.response && err.response.data) {
                    dispatch({
                        type: "SET_CUSTOM_MESSAGE",
                        payload: {message: err.response.data.error.error, type: "error"}
                    });
                }
                console.log(err, "Error in get call report api in users.js")
            })
        }
    },

    getAvailabilityReport: (userData,postData,reportData) => {
        return (dispatch, getstate) => {
            axios.post(base_url_v2 + 'api/v1/callReport/availabilityReport',{reportData,postData:postData},
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userData.userId,
                        'authtoken': userData.authToken,
                        'billingtoken': userData.billingToken
                    }
                }
            ).then(postData => {
                if(postData.data.data.isDownload){
                    if(!postData.data.data.url){
                        dispatch({
                            type:"SET_CUSTOM_MESSAGE",
                            payload:{message:'Your request is submitted successfully. We will send you the report on your registered email ID. ',type:"infoLong"}
                        });
                    }else{
                        dispatch({
                            type:"SET_CUSTOM_MESSAGE",
                            payload:{message:'Your report is downloaded successfully!',type:"success"}
                        });
                        dispatch({
                            type: callReport.AVAILABILITY_REPORT_CSV,
                            postData,
                        })
                    }
                }else{
                    dispatch({
                        type: callReport.AVAILABILITY_REPORT,
                        postData,
                    })
                }
            }).catch(err => {
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                if(err.response && err.response.data) {
                    dispatch({
                        type: "SET_CUSTOM_MESSAGE",
                        payload: {message: err.response.data.error.error, type: "error"}
                    });
                }
                console.log(err, "Error in get call report api in users.js") 
            })
        }
    },

    getUserStatusReport: (userData,postData,reportData) => {
        return (dispatch, getstate) => {
            axios.post(base_url_v2 + 'api/v1/callReport/userStatusReport',{reportData,postData:postData},
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userData.userId,
                        'authtoken': userData.authToken,
                        'billingtoken': userData.billingToken
                    }
                }
            ).then(postData => {

                if (postData.data.data.message) {

                    dispatch({
                        type: "SET_CUSTOM_MESSAGE",
                        payload: { message: postData.data.data.message, type: "infoLong" }
                    })
                }

                if(postData.data.data.isDownload){
                    if(!postData.data.data.url){
                        dispatch({
                            type:"SET_CUSTOM_MESSAGE",
                            payload:{message:'Your request is submitted successfully. We will send you the report on your registered email ID. ',type:"infoLong"}
                        });
                    }else{
                        dispatch({
                            type:"SET_CUSTOM_MESSAGE",
                            payload:{message:'Your report is downloaded successfully!',type:"success"}
                        });
                        dispatch({
                            type: callReport.USER_STATUS_REPORT_CSV,
                            postData,
                        })
                    }
                }else{
                    dispatch({
                        type: callReport.USER_STATUS_REPORT,
                        postData,
                    })
                }
            }).catch(err => {
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                if(err.response && err.response.data) {
                    dispatch({
                        type: "SET_CUSTOM_MESSAGE",
                        payload: {message: err.response.data.error.error, type: "error"}
                    });
                }
                console.log(err, "Error in get call report api in users.js") 
            })
        }
    },

    getDIDReport: (userData,postData,reportData) => {
        return (dispatch, getstate) => {
            axios.post(base_url_v2 + 'api/v1/callReport/didReport',{reportData,postData:postData},
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userData.userId,
                        'authtoken': userData.authToken,
                        'billingtoken': userData.billingToken
                    }
                }
            ).then(postData => {
                if(postData.data.data.isDownload){
                    if(!postData.data.data.url){
                        dispatch({
                            type:"SET_CUSTOM_MESSAGE",
                            payload:{message:'Your request is submitted successfully. We will send you the report on your registered email ID. ',type:"infoLong"}
                        });
                    }else{
                        dispatch({
                            type:"SET_CUSTOM_MESSAGE",
                            payload:{message:'Your DID report is downloaded successfully!',type:"success"}
                        });
                        dispatch({
                            type: callReport.DID_REPORT_CSV,
                            postData,
                        })
                    }
                }else{
                    dispatch({
                        type: callReport.DID_REPORT,
                        postData,
                    })
                }
            }).catch(err => {
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                if(err.response && err.response.data) {
                    dispatch({
                        type: "SET_CUSTOM_MESSAGE",
                        payload: {message: err.response.data.error.error, type: "error"}
                    });
                }
                console.log(err, "Error in get call report api in users.js") 
            })
        }
    },

    getAvailabilitySlots: (userData, postData, reportData) => {
        return (dispatch, getstate) => {
            axios.post(base_url_v2 + 'api/v1/callReport/availabilitySlots?' + postData, reportData,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userData.userId,
                        'authtoken': userData.authToken,
                        'billingtoken': userData.billingToken
                    }
                }
            ).then(postData => {
                if (postData) {
                    dispatch({
                        type: callReport.AVAILABILITY_SLOT,
                        postData,
                    })
                }
            }).catch(err => {
                if (err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                if (err.response && err.response.data) {
                    dispatch({
                        type: "SET_CUSTOM_MESSAGE",
                        payload: { message: err.response.data.error.error, type: "error" }
                    });
                }
                console.log(err, "Error in get availability slots api")
            })
        }
    },

    getCountryWiseReport: (userData,postData) => {
        return (dispatch, getstate) => {
            axios.get(base_url + 'countryWiseUserCallReport/?'+postData,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userData.userId,
                        'authtoken': userData.authToken,
                        'billingtoken': userData.billingToken
                    }
                }
            ).then(postData => {
                dispatch({
                    type: callReport.COUNTRY_WISE,
                    postData,
                })
            }).catch(err => {
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                console.log(err, "Error in get country wis report api in users.js") 
            })
        }
    },

    getCallCountries: postData => {
        return (dispatch, getstate) => {
            axios.get(base_url + 'callCountries?id=' + postData.userId,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': postData.userId,
                        'authtoken': postData.authToken,
                        'billingtoken': postData.billingToken
                    }
                }
            ).then(postData => {
                dispatch({
                    type: callReport.CALL_COUNTRIES,
                    postData,
                })
            }).catch(err => {
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                console.log(err, "Error in get call countries api in users.js") 
            })
        }
    },

    getUserList: postData => dispatch => {
        return axios.get(base_url + 'user/list?status=true',
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': postData.userId,
                        'authtoken': postData.authToken,
                        'billingtoken': postData.billingToken
                    }
                }
            ).then(postData => {
                return dispatch({
                    type: callReport.USER_LIST,
                    postData,
                })
            }).catch(err => {
            if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                return ClearUserData(err.response);
            }
            })
        
    },

    getTargetList: data => dispatch => {
        const userData = JSON.parse(localStorage.getItem('userData')),
        authToken = userData == null ? '' : userData.authToken,
        userId = userData == null ? '' : userData._id,
        bilingtoken = userData == null ? '' : userData.billingToken;
        var current = 'current='+data.current
        var query;
        if(data.id){
            query = data.id ?"id="+data.id:""
        }else{
            query = data && data.query ? current+'&'+data.query : current
        }
        return axios.get(base_url + 'target/list?'+query,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userId,
                        'authtoken': authToken,
                        'billingtoken': bilingtoken
                    }
                }
            ).then(postData => {
                return dispatch({
                    type: callReport.TARGET_LIST,
                    postData,
                })
            }).catch(err => {
                if (err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                const postData = err.response;
                dispatch({
                    type: callReport.TARGET_LIST,
                    postData
                })
            })
        
    },
    addTarget: data => dispatch =>  {
        const userData = JSON.parse(localStorage.getItem('userData')),
            authToken = userData == null ? '' : userData.authToken,
            userId = userData == null ? '' : userData._id,
            bilingtoken = userData == null ? '' : userData.billingToken;
        axios.post(base_url + 'target', data,
              {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'id': userId,
                  'authtoken': authToken,
                  'billingtoken': bilingtoken,
                }
              }
            ).then(postdata => {
              dispatch({
                type: callReport.ADD_TARGET,
                postdata,
              })
            }).catch((err) => {
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
              const postdata = err.response;
              dispatch({
                type: callReport.ADD_TARGET,
                postdata,
              })
            });
    },
    editTarget: (data, id) => dispatch => {
        const userData = JSON.parse(localStorage.getItem('userData')),
            authToken = userData == null ? '' : userData.authToken,
            userId = userData == null ? '' : userData._id,
            bilingtoken = userData == null ? '' : userData.billingToken;
        axios.put(base_url + 'target/' + id, data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'id': userId,
                    'authtoken': authToken,
                    'billingtoken': bilingtoken,
                }
            }
        ).then(postdata => {
            dispatch({
                type: callReport.EDIT_TARGET,
                postdata,
            })
        }).catch((err) => {
            if (err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                return ClearUserData(err.response);
            }
            const postdata = err.response;
            dispatch({
                type: callReport.EDIT_TARGET,
                postdata,
            })
        });
    },
    removeTarget: _id => {
        const userData = JSON.parse(localStorage.getItem('userData')),
            authToken = userData == null ? '' : userData.authToken,
            userId = userData == null ? '' : userData._id,
            bilingtoken = userData == null ? '' : userData.billingToken;
        return (dispatch, getstate) => {
            axios.delete(base_url + 'target/'+_id,
              {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'id': userId,
                  'authtoken': authToken,
                  'billingtoken': bilingtoken,
                }
              }
            ).then(postdata => {
                return dispatch({
                type: callReport.REMOVE_TARGET,
                postdata,
              })
            }).catch((err) => {
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
            });
        }
    },
    callDispositionReport: postData => dispatch => axiosApi(`${base_url + 'api/v1/callReport/callDispositionReport'}`, "post", postData, dispatch, `${callReport.CALL_DISPOSITION_REPORT}`),
}

export default callReport;
